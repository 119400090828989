/*
  obfuscate/unobfuscate a string
  create a string <block>-<block>-<block>... with number of blocks equals length of string
  than replace the n-th position in each block with the n-th character of the string
*/

// create a random number of length
function makeRandom(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 // replace a char position
 function replaceAt(instring, index, replacement) {
    return instring.substr(0, index) + replacement + instring.substr(index + replacement.length);
}
 

// client module for retreiving data from lime proxy
export default class ObfuscationService {

    // obfuscate
    obfusc(value) {
      var chars = value.toString().split('');
      var result = '';
      chars.forEach((char, i) => {
        var rand = makeRandom(chars.length)
        rand = replaceAt(rand, i, char)
        result += (i > 0 ? '-' : '') + rand;
      })
      return result
    }

    // unobfusc
    unobfusc(value) {
        var blocks = value.toString().split('-');
        var result = '';
        blocks.forEach((block, i) => {
            result += block.charAt(i);
        })
        return result
    }
}