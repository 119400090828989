<template>

  <div id="selftest">

    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <!-- navigation-->    
          <div class="col-12">
            <Navi dark-mode></Navi>
    
            <!-- how happy makes you your job -->
            <div class="row justify-content-center">
              <div class="col-11 col-md-10 col-lg-10">
                <div class="row d-flex align-items-center"> 
                  <div class="col-12 col-md-5">                     
                    <div class="flex-column align-items-center justify-content-center">
                      <h1 class="text-left c-txt-dark c-h1">
                        <span v-html="this.diagrams[currentDiagramIdx].teaser"></span>   
                      </h1>
                      <p class="text-left c-txt-dark">
                        <span v-html="this.diagrams[currentDiagramIdx].text"></span>
                     </p>
                    </div>
                  </div>

                  <div class="col-12 col-md-7 d-flex flex-row align-items-end justify-content-center py-2 py-md-0" @click="selectDiagram(currentDiagramIdx)" v-touch:swipe.prevent="swipeDiagram">
                    <div class="row">
                      <div class="col-12">
                        <!-- dummy needed for smooth fading -->
                        <svg id="dummy" width="0px" viewBox="0 0 1 200" class="c-diagram"></svg>   
                        <!-- happines at all -->
                        <transition name="fade" mode="out-in">
                          <svg id="gauges-all" class="c-diagram" viewBox="0 0 200 200"  v-show="diagrams[0].display">
                            <!--<text text-anchor="middle" font-size="0.5rem" transform="translate(100,10)" style="fill: #ffffff;">DURCHSCHNITTLICHES GLÜCKSLEVEL</text>-->
                            <Gauge id="ha" class="ha" v-bind:cx="100" v-bind:cy="80" v-bind:r="60" v-bind:value="this.diagrams[0].data.data[0].avgHa" v-bind:label="['Glück','']" v-bind:icon="require('@/assets/icon_happiness_white.svg')" v-bind:setup='{"circleColor": "#00986d","textColor":"#00986d","waveTextColor":"#00986d","waveColor":"#99deca","circleOuterGap":0.025}'/>
                            <Gauge id="si" class="si" v-bind:cx="40" v-bind:cy="142" v-bind:r="30" v-bind:value="this.diagrams[0].data.data[0].avgSi" v-bind:label="['Sinn-','empfinden']" v-bind:icon="require('@/assets/icon_sense_white.svg')" v-bind:setup='{"circleColor": "#81cbdc","textColor":"#81cbdc","waveTextColor":"#81cbdc","waveColor":"#b7e2ec"}'/>
                            <Gauge id="sv" class="sv" v-bind:cx="100" v-bind:cy="164" v-bind:r="30" v-bind:value="this.diagrams[0].data.data[0].avgSv" v-bind:label="['Selbstverwirk-','lichung']" v-bind:icon="require('@/assets/icon_selfactualization_white.svg')" v-bind:setup='{"circleColor": "#ec6d3f","textColor":"#ec6d3f","waveTextColor":"#ec6d3f","waveColor":"#f3c5b4"}'/>
                            <Gauge id="ge" class="ge" v-bind:cx="160" v-bind:cy="142" v-bind:r="30" v-bind:value="this.diagrams[0].data.data[0].avgGe" v-bind:label="['Gemeinschaft','']" v-bind:icon="require('@/assets/icon_comunity_white.svg')" v-bind:setup='{"circleColor": "#f6a82e","textColor":"#f6a82e","waveTextColor":"#f6a82e","waveColor":"#fbe0b7"}'/>
                          </svg>
                        </transition>
                        <!-- diagram 2 -->
                        <transition name="fade">
                          <svg id="svg_2" class="c-diagram" viewBox="0 0 560 600"  v-show="diagrams[1].display" >
                            <Barchart darkMode id="chart_2" v-bind:width="500" v-bind:height="500" v-bind:data="this.diagrams[1].data"/>
                          </svg>
                        </transition>
                        <!--diagram 3 -->
                        <transition name="fade">
                          <svg id="svg_3" class="c-diagram" viewBox="0 0 560 600"  v-show="diagrams[2].display" >
                            <Linechart darkMode id="chart_3"  v-bind:width="500" v-bind:height="500"  v-bind:data="this.diagrams[2].data"/>
                          </svg>
                        </transition>
                        <!--diagram 4 -->
                        <transition name="fade">
                          <svg id="svg_4" class="c-diagram" viewBox="0 0 560 600"  v-show="diagrams[3].display" >
                            <Linechart darkMode id="chart_4" v-bind:width="500" v-bind:height="500"  v-bind:data="this.diagrams[3].data"/>
                          </svg>
                        </transition>
                        <!--diagram 5 -->
                        <transition name="fade">
                          <svg id="svg_5" class="c-diagram" viewBox="0 0 560 600"  v-show="diagrams[4].display" >
                            <Linechart darkMode id="chart_5" v-bind:width="500" v-bind:height="500"  curveMode="Step" rotateXLabels v-bind:data="this.diagrams[4].data"/>
                          </svg>
                        </transition>
                        <!-- diagram 6 -->
                        <transition name="fade">
                          <svg id="svg_6" class="c-diagram" viewBox="0 0 560 600"  v-show="diagrams[5].display" >
                            <Linechart darkMode id="chart_6" v-bind:width="500" v-bind:height="500"  curveMode="Step" rotateXLabels v-bind:data="this.diagrams[5].data"/>
                          </svg>
                        </transition>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-12">
                    <div class="row py-2">
                      <!-- diagram navigation -->                                  
                      <div class="col-12  col-sm-6 py-2 d-flex align-self-center justify-content-center justify-content-sm-end" >
                        <span class="c-txt-dark" style="padding: 10px; font-size: 1.2rem;">{{diagrams[currentDiagramIdx].label}}</span>
                      </div>
                      <div class="col-12 col-sm-6 py-2 d-flex align-self-center justify-content-center justify-content-sm-start" >
                        <template v-for="(dia, idx) in diagrams">
                            <img v-if="dia.display" class="c-link" v-bind:key="idx"  @click="selectDiagram(idx)" src="@/assets/icon_dot_orange.svg" alt="punkt">
                            <img v-else v-bind:key="idx" class="c-link"  @click="selectDiagram(idx)" src="@/assets/icon_dot_grey.svg" alt="punkt">
                        </template>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-12 py-2 ">
                    <div class="d-flex justify-content-center justify-content-md-start">
                      <!-- button --> 
                      <div class="c-btn c-btn-orange"  @click="gotoLocation(surveyUrl, '_self')">
                        <span style="padding: 10px;">Jetzt testen</span>
                        <div class="c-btn-img c-btn-img-right-white"/> <!-- icon -->
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- test methodology -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <div class="row align-items-end">
            <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">
              <h2 id="scale" class="text-center text-md-center c-h2" >Wie testen wir?</h2>
            </div>
            <div class="col-12 order-2 col-md-6 order-md-2">
              <img id="bubble_scale" src="@/assets/academy_bubble_scale.png" alt="HappinessAndWork_Skala">
            </div>
            <div class="col-12 order-3 col-md-6 order-md-3 py-3 align-self-start"> 
              <p class="text-center text-md-left">
                Der Test basiert auf der <b class="c-txt-orange">HappinessandWork-Scale</b> (Rehwaldt & Kortsch). Diese Skala misst Glück am Arbeitsplatz und erfasst über drei Subskalen 
                die Facetten Sinnempfinden, Selbstverwirklichung und Gemeinschaft.  
              </p>           
              <div class="row justify-content-center">
                <div class="col-12">
                  <div class="d-flex justify-content-center">  
                    <!-- button -->
                    <router-link to="/academy/happiness_and_work_scale" style="text-decoration: none">
                       <div class="c-btn c-btn-orange">
                        <span style="padding: 10px">HappinessandWork-Scale</span>
                        <div class="c-btn-img c-btn-img-right-white"/>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
import Gauge from '@/components/Gauge.vue'
import Barchart from '@/components/Barchart.vue'
import Linechart from '@/components/Linechart.vue'

import ProxyClientService from '@/services/ProxyClientService.js'

export default {
  name: 'Selftest',
  components: {
    Gauge,
    Barchart,
    Linechart,
    Navi,
    Footer
  },
    metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` },
        { vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // survey url
      surveyUrl: "https://my.happinessandwork.de/limesurvey/index.php/772744",
      // metaData
      metaTitle: "- Wie Glücklich bist Du bei der Arbeit?",
      metaDescription: "Hier kannst Du dein Glücksempfinden bei der Arbeit testen und dich mit anderen vergleichen.",
      metaUrl: "/haw/selftest",
      metaKeywords: "Selbsttest",
      metaImage: "https://www.happinessandwork.de/share/selftest.png",
      // data
      diagrams: [{id: "all", label: "Durchschnittliches Glücksempfinden",   display: true, criteria: null, data: {},
                  defaultData: {"criteria": {"name": "all","timestamp": "2022/04/05 12:12:00","values": ["all"],"lang": "de"},"data": [{"avgSi": 66.0,"avgSv": 59.0,"avgGe": 60.0,"avgHa": 62.0}]},
                  text: "Mehr als 3000 Personen haben ihr 'Glück bei der Arbeit' bereits getestet. Hier siehst du die Ergebnisse. Teste dich selbst und sieh wie es um <b class=\"c-txt-orange\">dein persönliches Glück</b> bestellt ist und wie du im Vergleich mit anderen stehst.",
                  title: "Gesamt",
                  teaser: "Wie glücklich sind Menschen bei ihrer Arbeit?",
                  filter: [{key: "all", label: "Gesamt"}
                          ]
                  },
                  {id: "gender", label: "Glück & Geschlecht", display: false, criteria: "gender", data: {},
                  defaultData: {"criteria": {"name": "gender","timestamp": "2022/04/05 12:12:00","values": ["M","F"],"lang": "de"},"data": [{"avgSi": 65.0,"avgSv": 63.0,"avgGe": 60.0,"avgHa": 63.0},{"avgSi": 67.0,"avgSv": 57.0,"avgGe": 59.0,"avgHa": 61.0}]},
                  text: " Männer sind ein ganz kleines bisschen glücklicher bei der Arbeit. Erklärt wird das offensichtlich durch ein höheres Maß an Selbst&shy;verwirklichung und Gemeinschafts&shy;empfinden bei den Männern. Bei Frauen hingegen ist das Sinn&shy;empfinden im Kontext Arbeit höher ausgeprägt.",
                  title: "Geschlecht",         
                  teaser: "Sind eigentlich Frauen glücklicher als Männer?",             
                  filter: [{key: "M", label: "Männer"},
                            {key: "F", label: "Frauen"}
                          ]
                  },
                  {id: "salaryRange", label: "Glück & Verdienst", display: false, criteria: "salaryRange", data: {},
                  defaultData: {"criteria": {"name": "salaryRange","timestamp": "2022/04/05 12:12:01","values": ["G01","G02","G03","G04","G05","G06"],"lang": "de"},"data": [{"avgSi": 69.0,"avgSv": 55.0,"avgGe": 62.0,"avgHa": 62.0},{"avgSi": 69.0,"avgSv": 55.0,"avgGe": 58.0,"avgHa": 61.0},{"avgSi": 69.0,"avgSv": 61.0,"avgGe": 59.0,"avgHa": 63.0},{"avgSi": 70.0,"avgSv": 66.0,"avgGe": 59.0,"avgHa": 65.0},{"avgSi": 69.0,"avgSv": 65.0,"avgGe": 58.0,"avgHa": 64.0},{"avgSi": 76.0,"avgSv": 81.0,"avgGe": 58.0,"avgHa": 72.0}]},
                  text: "Geld scheint wohl doch glücklich zu machen? Allerdings sind diejenigen, die viel Geld verdienen auch oft erfolgreiche Selbständige, die viel Sinn&shy;stiftendes in ihrer Arbeit sehen.",
                  title: "Jahresgehalt",
                  teaser: "Macht Geld wirklich glücklich?",             
                  filter: [{key: "G01", label: "-10T€"},
                            {key: "G02", label: "20T€"},
                            {key: "G03", label: "40T€"},
                            {key: "G04", label: "60T€"}, 
                            {key: "G05", label: "80T€"}//, 
                            //{key: "G06", label: "+100T€"}
                          ]
                  },
                  {id: "companySize", label: "Glück & Unternehmensgröße", display: false, criteria: "companySize", data: {},
                  defaultData: {"criteria": {"name": "companySize","timestamp": "2022/04/05 12:12:00","values": ["-5","-10","-50","-100","-1000","-10000","10000+"],"lang": "de"},"data": [{"avgSi": 76.0,"avgSv": 76.0,"avgGe": 65.0,"avgHa": 73.0},{"avgSi": 74.0,"avgSv": 65.0,"avgGe": 63.0,"avgHa": 67.0},{"avgSi": 72.0,"avgSv": 64.0,"avgGe": 60.0,"avgHa": 66.0},{"avgSi": 69.0,"avgSv": 60.0,"avgGe": 59.0,"avgHa": 63.0},{"avgSi": 67.0,"avgSv": 57.0,"avgGe": 59.0,"avgHa": 61.0},{"avgSi": 62.0,"avgSv": 56.0,"avgGe": 61.0,"avgHa": 60.0},{"avgSi": 60.0,"avgSv": 54.0,"avgGe": 59.0,"avgHa": 58.0}]},
                  text: "Glück bei der Arbeit sinkt kontinuierlich mit der Unternehmens&shy;größe. Während das Sinn&shy;empfinden noch bis 50 Mit&shy;arbeitende stabil bleibt, sinkt der Faktor Selbst&shy;verwirklichung bereits ab 6 Mit&shy;arbeitenden rapide ab.",
                  title: "Anzahl Mitarbeiter",                      
                  teaser: "Hängt Glück auch von der Unternehmensgröße ab?",             
                  filter: [{key: "-5", label: "-5"},
                            {key: "-10", label: "10"},
                            {key: "-50", label: "50"},
                            {key: "-100", label: "100"},
                            {key: "-1000", label: "1000"},
                            {key: "-10000", label: "10000"},
                            {key: "10000+", label: "10000+"}
                          ]
                  },
                  {id: "eductaion", label: "Glück & Bildung", display: false, criteria: "education", data: {},
                  defaultData: {"criteria": {"name": "education","timestamp": "2022/04/05 12:12:00","values": ["A01","A02","A03","A04","A05","A06","A07","A08","other"],"lang": "de"},"data": [{"avgSi": 71.0,"avgSv": 53.0,"avgGe": 56.0,"avgHa": 60.0},{"avgSi": 66.0,"avgSv": 54.0,"avgGe": 57.0,"avgHa": 59.0},{"avgSi": 66.0,"avgSv": 53.0,"avgGe": 60.0,"avgHa": 60.0},{"avgSi": 65.0,"avgSv": 63.0,"avgGe": 63.0,"avgHa": 63.0},{"avgSi": 64.0,"avgSv": 65.0,"avgGe": 60.0,"avgHa": 63.0},{"avgSi": 66.0,"avgSv": 63.0,"avgGe": 60.0,"avgHa": 63.0},{"avgSi": 61.0,"avgSv": 62.0,"avgGe": 57.0,"avgHa": 60.0},{"avgSi": 69.0,"avgSv": 65.0,"avgGe": 60.0,"avgHa": 65.0},{"avgSi": 74.0,"avgSv": 56.0,"avgGe": 58.0,"avgHa": 63.0}]},
                  text: "Bildung macht glücklich. Besonders trägt dazu der Studien&shy;abschluss Bachelor bei. Hier schnellt das Glück im Verhältnis zur Berufs&shy;ausbildung deutlich in die Höhe. Am glücklichsten sind bei Betrachtung des Bildungs&shy;niveaus Promovierte und Habilitierte. Erklärt wird dieser Anstieg über den Zuwachs an Selbst&shy;verwirklichungs&shy;möglichkeiten.",
                  title: "Bildungsabschluss",
                  teaser: "Sind gebildetere Menschen eigentlich glücklicher?",             
                  filter: [{key: "A01", label: "mittl. Reife"},
                            {key: "A02", label: "Abitur "},
                            {key: "A03", label: "Ausbildung"},
                            {key: "A04", label: "Bachelor"},
                            {key: "A05", label: "Master"},
                            {key: "A06", label: "Diplom"},
                            {key: "A08", label: "Promotion"}
                          ]
                  },
                  {id: "industrialSector", label: "Glück & Branche", display: false, criteria: "industrialSector", data: {},
                  defaultData: {"criteria": {"name": "industrialSector","timestamp": "2022/04/05 12:12:00","values": ["D01","D02","D03","D04","D05","D06","D07","D08","D09","D10","D11","D12","D13","D14","D15","D16","D17","D18","D19","D20","D21","D22","D23","D24","other"],"lang": "de"},"data": [{"avgSi": 50.0,"avgSv": 52.0,"avgGe": 54.0,"avgHa": 52.0},{"avgSi": 59.0,"avgSv": 63.0,"avgGe": 65.0,"avgHa": 62.0},{"avgSi": 71.0,"avgSv": 71.0,"avgGe": 65.0,"avgHa": 69.0},{"avgSi": 79.0,"avgSv": 66.0,"avgGe": 61.0,"avgHa": 69.0},{"avgSi": 58.0,"avgSv": 55.0,"avgGe": 57.0,"avgHa": 56.0},{"avgSi": 54.0,"avgSv": 64.0,"avgGe": 62.0,"avgHa": 60.0},{"avgSi": 66.0,"avgSv": 62.0,"avgGe": 62.0,"avgHa": 63.0},{"avgSi": 61.0,"avgSv": 54.0,"avgGe": 66.0,"avgHa": 60.0},{"avgSi": 57.0,"avgSv": 60.0,"avgGe": 61.0,"avgHa": 59.0},{"avgSi": 64.0,"avgSv": 58.0,"avgGe": 60.0,"avgHa": 61.0},{"avgSi": 82.0,"avgSv": 57.0,"avgGe": 59.0,"avgHa": 66.0},{"avgSi": 57.0,"avgSv": 51.0,"avgGe": 58.0,"avgHa": 55.0},{"avgSi": 72.0,"avgSv": 70.0,"avgGe": 63.0,"avgHa": 68.0},{"avgSi": 63.0,"avgSv": 69.0,"avgGe": 62.0,"avgHa": 65.0},{"avgSi": 53.0,"avgSv": 53.0,"avgGe": 58.0,"avgHa": 55.0},{"avgSi": 59.0,"avgSv": 66.0,"avgGe": 62.0,"avgHa": 63.0},{"avgSi": 50.0,"avgSv": 62.0,"avgGe": 60.0,"avgHa": 58.0},{"avgSi": 55.0,"avgSv": 59.0,"avgGe": 55.0,"avgHa": 56.0},{"avgSi": 63.0,"avgSv": 58.0,"avgGe": 60.0,"avgHa": 60.0},{"avgSi": 100.0,"avgSv": 50.0,"avgGe": 0.0,"avgHa": 50.0},{"avgSi": 55.0,"avgSv": 51.0,"avgGe": 41.0,"avgHa": 49.0},{"avgSi": 68.0,"avgSv": 65.0,"avgGe": 65.0,"avgHa": 66.0},{"avgSi": 58.0,"avgSv": 44.0,"avgGe": 51.0,"avgHa": 51.0},{"avgSi": 61.0,"avgSv": 58.0,"avgGe": 59.0,"avgHa": 59.0},{"avgSi": 68.0,"avgSv": 52.0,"avgGe": 56.0,"avgHa": 58.0}]},
                  text: "Bildung macht glücklich, auch als Beruf. Menschen die im Bildungs&shy;sektor beschäftigt sind, sind überdurch&shy;schnittlich glücklich, während Handel und Dienst&shy;leistungen vergleichsweise niedrige Werte zeigen. ",
                  title: "Branche",
                  teaser: "Sind die Menschen in allen Branchen gleich glücklich?",             
                  filter: [{key: "D04", label: "Bildung"},
                            {key: "D05", label: "Dienstleistung"},
                            {key: "D07", label: "Elektro"},
                            {key: "D10", label: "Forschung"},
                            {key: "D11", label: "Gesundheit"},
                            {key: "D12", label: "Handel"},
                          ]
                  }
                ],
        currentDiagramIdx: 0,
        diagramTransition: false,   
        defaultData: {criteria:{name:"all",timestamp:"2020/06/19 12:17:04",values:["all"]},data:[{avgSi:66.0,avgSv:59.0,avgGe:60.0,avgHa:62.0}]},
        autoSlide: true,          // flag to aktivate / deactivate sliding
        slidingInterval: 8000     // sliding time interval in ms
      }
    },
    created: function () {
      // init diagrams with default data
      this.diagrams.forEach(diag => {
        //diag.data = this.defaultData;
        diag.data = diag.defaultData;
        diag.data = this.filterData(diag.defaultData, diag.filter, diag.title)
      })
    },
    // `this` points to the vm instance
    mounted: function () {
      // retrieve data
      this.diagrams.forEach((diag) => {
        // callback for data processing
        let cb = (data) => {
          diag.data = this.filterData(data, diag.filter, diag.title)
        }
        // retrieve data
        const proxyClientService = new ProxyClientService();
        proxyClientService.retrieveOverallStatistics(diag.criteria, null, cb);


        // run timer to automatically swipe to next picture
        // after frist click stop auto swipe
        this.autoSwipe();
      
        //this.retrieveData(diag.criteria, i); 
      })
    },
    methods: {

      // run timer to automatically swipe to next
      // after fist click stop auto swipe
      autoSwipe() {
          setTimeout(() => {
            if (this.autoSlide) {
              this.slide('left')
            }
            this.autoSwipe()
          },  this.slidingInterval)
      },

      // filter data and relabel them
      // data: data to filter
      // filter: filter to use
      // title: diagram title
      // retuns new data
      filterData(data, filter, title) {
        var newData = {criteria:{name: title, timestamp: data.criteria.timestamp, values:[]}, data:[]};
        data.criteria.values.forEach((value, i) => {
          var label = null;
          filter.forEach(entry => {if (entry.key == value) label = entry.label; })
          if (label) {
            newData.criteria.values.push(label);
            newData.data.push(data.data[i]);
          }
        })
        //console.log("Filetered data: " + JSON.stringify(newData)); // eslint-disable-line no-console
        return newData;
      },

      // handle swipe events (right, left, top, bottom)
      swipeDiagram(direction) {
        //console.log("Swipe: " + direction); // eslint-disable-line no-console  
        this.slide(direction);
        // stop auto slide
        this.autoSlide = false;
      },

      // handle klick event on graphic selectors
      selectDiagram(idx) { 
        this.showDiagram(idx);
        // stop auto slide
        this.autoSlide = false;
      },

      // slide graphics
      slide(direction) {
        //console.log("Swipe: " + direction); // eslint-disable-line no-console  
        switch (direction) {
          case 'right':
            this.showDiagram(this.currentDiagramIdx == 0 ? (this.diagrams.length - 1) : (this.currentDiagramIdx - 1))
            break;
          case 'left':
            var xx = this.currentDiagramIdx == (this.diagrams.length - 1) ? 0 : (this.currentDiagramIdx + 1)
            this.showDiagram(xx)
            break;
        } 
      },

      // got to location
      gotoLocation(url, target) {
        window.open(url, target);    
      },

      // show diagram
      // set all data to 0 before showing
      // then back to orig value to animate them
      showDiagram(idx) {
        // old diagram
        if (idx == this.currentDiagramIdx) return;
        // do nothing if diagram transition is still in action
        if (!this.diagramTransition) {
          this.diagramTransition = true // lock transition
          this.diagrams[this.currentDiagramIdx].display = false;
          this.currentDiagramIdx = idx;
          var dataRestore = JSON.parse(JSON.stringify(this.diagrams[idx].data)); // remember data of new diagram
          var dataReset= JSON.parse(JSON.stringify(this.diagrams[idx].data)); // remember data of new diagram
          let vm = this;
          dataReset.data.forEach(data => { // set values of new diagram to 0
            data.avgSi = data.avgSv = data.avgGe = data.avgHa = 0;
          }) 
          vm.diagrams[idx].data = dataReset;
          //console.log("Reset data: " + JSON.stringify(vm.diagrams[idx].data.data)); // eslint-disable-line no-console
          // ensure update cicle between diagrams 
          setTimeout(function() {
            // new diagram
            vm.diagrams[idx].display = true;
            vm.diagrams[idx].data = dataRestore;     // restore data of new diagram
            //console.log("Restore data: " + JSON.stringify(vm.diagrams[idx].data.data)); // eslint-disable-line no-console
            vm.diagramTransition = false // unlock transition
          }, 600);
        }    
      }
    }
}


</script>

<style>

</style>
