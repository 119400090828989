<template>

  <div id="haw_report">
    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- haw report -->  
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">                    
                <div class="row">
                  <div class="col-12 d-flex align-items-center flex-column">
                    <h1 class="c-h1">Work-Happiness-Report 2024</h1>  
                    <p class="c-txt-orange" style="font-weight: bold;">Report zum herunterladen</p>      
                  </div>
                  <div class="col-12 py-3 col-md-4 d-flex order-2 order-md-1 justify-content-center justify-content-md-end">             
                    <img id="paper_stack" src="@/assets/paper_stack.png" alt="paper_stack">  
                  </div>
                  <div class="col-12 py-3 col-md-8 order-1 order-md-2 ">             
                    <p class="text-left">
                        Der Work-Happiness-Report 2024 ist da! 
                        Mit unserer HappinessandWork-Scale haben awork und Appinio 1.000+ Wissensarbeitende befragt. 
                        Die Ergebnisse sind wieder beeindruckend:
                        <br/><br/>
                        73% würden einen Teil ihres Gehalts für mehr Glück eintauschen.
                    </p>
                    <ul class="text-left">
                      <li>Die Tech-Branche ist Spitzenreiter</li>
                      <li>Arbeitsglück kann gezielt gefördert werden</li>
                    </ul>
                    <p class="text-left">
                        Der Report enthält neben den Studienergebnissen auch: 
                    </p> 
                    <ul class="text-left">  
                      <li>Praxis-Tipps für eine direkte Umsetzung im Team</li>
                    </ul>
                  </div>
                </div>    
              </div>
            </div>

            <!-- contact -->  
            <div class="row justify-content-center">
              <div class="col-11 col-md-10">                    
                <div class="row">
                  <div class="col-12">
                    <div>
                      <h1 class="c-h1">Ich möchte den Report herunterladen</h1>
                      <p class="c-txt-orange" style="font-weight: bold;">Hierzu benötigen wir deine Emailadresse und du erhältst einen Link zum Download</p>  
                    </div>       
                  </div>
                </div>  
                <!-- contact data -->    
                <div class="row">
                  <div class="col-12 col-md-8 offset-md-2">
                    <b-form-group id="input-group-6" class="text-left">
                      <b-form-checkbox-group v-model="form.report" id="checkboxes-8" class="d-flex flex-column">
                        <b-form-checkbox value="HAW_2024">Report 2024</b-form-checkbox>
                        <b-form-checkbox value="HAW_2023">Report 2023</b-form-checkbox>
                      </b-form-checkbox-group> 
                    </b-form-group>
                    <b-form-group id="input-group-4" class="text-left" label="Email" label-for="input-4" 
                          :invalid-feedback="invalidEmail" :state="stateEmail">
                      <b-form-input id="input-4" v-model="form.email" type="text" required placeholder="Emailadresse eingeben" :state="stateEmail"/>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <img id="bubble_haw_report_1" src="@/assets/bubble_red_3.png" alt="bubble">  
                  <div class="col-12 col-md-8 offset-md-2">
                    <b-form-group id="input-group-6" class="text-left">
                      <b-form-checkbox-group v-model="form.confirm" id="checkboxes-6" class="d-flex flex-column">
                        <b-form-checkbox value="ContactAllowed">
                          Happiness & Work darf meine angegebenen Daten 
                          verwenden mit mir Kontakt aufzunehmen und ich bin mit der <router-link to="/dp">Datenschutzerklärung</router-link> einverstanden.
                        </b-form-checkbox>
                        <b-form-checkbox value="Newsletter">
                          Ja, ich mag in puncto Glück & Arbeit informiert werden. 
                          Bitte schickt mir euren Newsletter, der Aktuelles aus der Forschung erklärt und über Neues informiert. </b-form-checkbox>
                      </b-form-checkbox-group> 
                    </b-form-group>

                    <div class="d-flex justify-content-left">  
                      <!-- button -->
                      <div class="c-btn c-btn-orange" @click="send" v-show="!invalidEmail && confirmed && reportSelected">
                        <span style="padding: 10px">Absenden</span>
                        <div class="c-btn-img c-btn-img-right-white"/>
                      </div>
                    </div> 
                  </div>
                </div>
                
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

import EmailClientService from '@/services/EmailClientService';

export default {
  name: 'HAW_Report',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` }//,
        //{ vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Work Happiness Report",
      metaDescription: "Work Happiness Report zum Herunterladen.",
      metaUrl: "/info/happiness_and_work_report",
      metaKeywords: "Work Happiness Report",
      metaImage: undefined,
      form: {
        report: ["HAW_2024"],
        email: '',
        interests: ["HAW Report"],
        confirm: []
      },
      //salutation: [{ text: 'Bitte auswählen ...', value: null }, 'Frau', 'Herr', ''], 
      show: true,
      msgStatus: '',
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    }
  },
  computed: {
    stateEmail() {return (new RegExp(this.emailRegex).test(this.form.email)) ? true : false},
    invalidEmail() {return !(new RegExp(this.emailRegex).test(this.form.email)) ? '*' : ''},
    confirmed() {return this.form.confirm.includes("ContactAllowed")},
    reportSelected() {return (this.form.report.length > 0)}
  },
    // called when component is mounted to the dom
  mounted: function () {
/*       const anchor = this.$router.currentRoute.hash;
    setTimeout(() => { 
      if (anchor && document.querySelector(anchor)) {
      //var elem = document.querySelector(this.$route.hash);
      var elem = document.querySelector(anchor);
      console.log("scoll to: " + anchor); // eslint-disable-line no-console
      elem.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }
    }, 1000) */
  },
  methods: {
    
    showConfirmBox(success) {
      var msg = success ? 'Wir haben dir per Email einen Download Link geschickt'
                        : 'Deine Anfrage konnte nicht verschickt werden. Wir kümmern uns um das Problem. Bitte sende uns eine Email an info@happinessandwork.de. Vielen Dank';
      this.$bvModal.msgBoxOk(msg, {
        title: 'Benachrichtigung',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: (success ? 'success' : 'danger'),
        headerClass: 'p-3 border-bottom-0',
        footerClass: 'p-3 border-top-0',
        centered: true
      })
        .then(value => {
          this.msgStatus = value;
        })
        .catch(err => {
          this.msgStatus = err;
        })
    },

    // send email notification
    send(event) {
      event.preventDefault();
      //alert("Send email: " + JSON.stringify(this.form)); 
      //this.showConfirmBox(true);


      const emailClientService = new EmailClientService();
      // create the callback function

      var cb = (data) => {
        //console.log("Send notification result: " + JSON.stringify(data.status)); // eslint-disable-line no-console
        this.showConfirmBox(data.status == 200 ? true : false );
      }
      emailClientService.sendNotification(this.form, cb);
    }

  }
  
}

</script>

<style>

</style>
