<template>

  <div id="contact">
    <div class="c-img-bg c-contact-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- contact -->  
            <div class="row justify-content-center">
              <div class="col-11 col-md-10">                    
                <div class="row">
                  <div class="col-12">
                    <div>
                      <h1 class="c-h1">Kontaktformular</h1>
                      <p class="c-txt-orange" style="font-weight: bold;">Wir freuen uns auf Deine Nachricht.</p>  
                    </div>       
                  </div>  
                </div>  
                <!-- contact data -->    
                <div class="row">
                  <div class="col-12 col-md-8 offset-md-2 py-3">             
                    <h2 class="text-left c-h2">Meine Kontaktdaten</h2>
                  </div>
                  <div class="col-12 col-md-8 offset-md-2">
                    <!--
                    <b-form-group id="input-group-1" class="text-left" label="Anrede" label-for="input-1">
                      <b-form-select id="input-1" v-model="form.salutation" :options="salutation" required/>
                    </b-form-group>
                    -->

                    <b-form-group id="input-group-2" class="text-left" label="Vorname" label-for="input-2" 
                          :invalid-feedback="invalidFirstname" :state="stateFirstname">
                      <b-form-input id="input-2" type="text" v-model="form.firstname" required placeholder="Vornamen eingeben" :state="stateFirstname"/>
                    </b-form-group>

                    <b-form-group id="input-group-3" class="text-left" label="Nachname" label-for="input-3" 
                          :invalid-feedback="invalidLastname" :state="stateLastname">
                      <b-form-input id="input-3" type="text" v-model="form.lastname" required placeholder="Nachnamen eingeben" :state="stateLastname"/>
                    </b-form-group>

                    <b-form-group id="input-group-4" class="text-left" label="Email" label-for="input-4" 
                          :invalid-feedback="invalidEmail" :state="stateEmail">
                      <b-form-input id="input-4" v-model="form.email" type="text" required placeholder="Emailadresse eingeben" :state="stateEmail"/>
                    </b-form-group>

                    <b-form-group id="input-group-5" class="text-left" label="Geburtstag" label-for="input-5" 
                          :invalid-feedback="invalidBirthday" :state="stateBirthday">
                      <b-form-input id="input-5" v-model="form.birthday" type="text" required placeholder="MM/TT- Glück ist unsere Mission. Lust auf schöne Geburtstagsgrüße?" :state="stateBirthday"/>
                    </b-form-group>


                    <!--<b-form-group id="input-group-4" class="text-left" label="Telefon" label-for="input-4" description="... wenn sie telefonischen Kontakt wünschen.">-->
                    <!--
                    <b-form-group id="input-group-5" class="text-left" label="Telefon" label-for="input-5"
                          :invalid-feedback="invalidEmailOrPhone" :state="stateEmailOrPhone">
                      <b-form-input id="input-5" v-model="form.phone" type="text" required placeholder="Telefonnummer eingeben" :state="stateEmailOrPhone"/>
                    </b-form-group>
                    -->
                  </div>
                </div>
                <div class="row">
                  <img id="bubble_contact_1" src="@/assets/bubble_red_3.png" alt="bubble">  
                  <div class="col-12 col-md-8 offset-md-2 py-3">  
                    <h2 class="text-left c-h2" >Ich bin ...</h2>
                  </div>
                  <div class="col-12 col-md-8 offset-md-2">
                    <b-form-group id="input-group-5" class="text-left">
                      <b-form-checkbox-group v-model="form.position" id="checkboxes-5" class="d-flex flex-column">
                        <b-form-checkbox value="owner">Geschäftsführer / Inhaber</b-form-checkbox>
                        <b-form-checkbox value="coach">Coach</b-form-checkbox>
                        <b-form-checkbox value="leader">Führungskraft</b-form-checkbox>
                        <b-form-checkbox value="hr">Personalabteilung</b-form-checkbox>
                        <b-form-checkbox value="other">sonstiges</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-8 offset-md-2 py-3">             
                    <h2 class="text-left c-h2" >Ich interessiere mich für ...</h2>
                  </div>
                  <div class="col-12 col-md-8 offset-md-2">

                    <b-form-group id="input-group-6" class="text-left">
                      <b-form-checkbox-group v-model="form.interests" id="checkboxes-6" class="d-flex flex-column">
                        <b-form-checkbox value="Happiness & Work für Dich">Happiness & Work für Dich</b-form-checkbox>
                        <b-form-checkbox value="Happiness & Homeoffice">Happiness & Homeoffice</b-form-checkbox>
                        <b-form-checkbox value="Happiness Hacks">Happiness Hacks</b-form-checkbox>
                        <b-form-checkbox value="Positive Leadership">Positive Leadership</b-form-checkbox>
                        <b-form-checkbox value="Keynotes & Seminare">Keynotes & Seminare</b-form-checkbox>
                        <b-form-checkbox value="Coaching">Coaching</b-form-checkbox>
                        <b-form-checkbox value="Individuelles Angebot">Individuelles Angebot</b-form-checkbox>
                        <b-form-checkbox value="sonstiges">sonstiges</b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>

                    <br>

                    <b-form-textarea id="textarea-rows" v-model="form.extra" placeholder="Ergänzungen ..." rows="8"/>

                    <br><br>
                    <b-form-group id="input-group-6" class="text-left">
                      <b-form-checkbox-group v-model="form.confirm" id="checkboxes-6" class="d-flex flex-column">
                        <b-form-checkbox value="ContactAllowed">
                          Happiness & Work darf meine angegebenen Daten 
                          verwenden mit mir Kontakt aufzunehmen und ich bin mit der <router-link to="dp">Datenschutzerklärung</router-link> einverstanden.
                        </b-form-checkbox>
                        <b-form-checkbox value="Newsletter">
                          Ja, ich mag in puncto Glück & Arbeit informiert werden. 
                          Bitte schickt mir euren Newsletter, der Aktuelles aus der Forschung erklärt und über Neues informiert. </b-form-checkbox>
                      </b-form-checkbox-group> 
                    </b-form-group>

                    <div class="d-flex justify-content-left">  
                      <!-- button -->
                      <div class="c-btn c-btn-orange" @click="send" v-show="!invalidFirstname && !invalidLastname && !invalidEmail && !invalidBirthday && confirmed">
                        <span style="padding: 10px">Absenden</span>
                        <div class="c-btn-img c-btn-img-right-white"/>
                      </div>
                    </div> 
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- team -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <div class="row align-items-center justify-content-center ">
            <div class="col-12 col-md-8 py-5">             
              <h2 id="team" class="text-left c-h2" >Ansprechpartner</h2>
              <br>
            </div>
          </div>

          <div class="row justify-content-center py-5">
            <div class="col-12 d-flex justify-content-center">
              <img id="arc" src="@/assets/arc.svg" alt="arc">
            </div>          
            <div class="col-4 d-flex justify-content-center">
              <img id="team_left" v-bind:src="team[0].img" v-bind:alt="team[0].name" v-bind:class="{'c-team-selected': teamSelected==0}" @click="teamSelected=0">
            </div> 
            <div class="col-4 d-flex justify-content-center">
              <img id="team_middle" v-bind:src="team[1].img" v-bind:alt="team[1].name" v-bind:class="{'c-team-selected': teamSelected==1}" @click="teamSelected=1">
            </div> 
            <div class="col-4 d-flex justify-content-center">
              <img id="team_right" v-bind:src="team[2].img" v-bind:alt="team[2].name" v-bind:class="{'c-team-selected': teamSelected==2}" @click="teamSelected=2">
            </div> 
          </div>

          <div class="row justify-content-center">
            <div id="team_container" class="col-12 col-md-10 align-items-center justify-content-center  py-5 py-md-0">
              <img id="team_cloud" src="@/assets/cloud.svg" alt="team_cloud">
              <div id="team_text" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                <p class="text-center">
                  <span class="c-txt-orange" style="font-weight: bold;">
                    {{ team[teamSelected].name }}
                  </span>
                  <br>{{ team[teamSelected].title }}
                </p>                                 
                <p class="text-center c-roboto-cond">{{ team[teamSelected].about }}</p>
                <a  v-bind:href="'mailto:' + team[teamSelected].email">
                  <img id="mail" class="c-social" src="@/assets/icon_email-64-grey.png" alt="email"> {{team[teamSelected].email}}
                </a>
                <p v-if="team[teamSelected].link" class="text-center c-roboto-cond">
                  <img class="c-social" src="@/assets/icon_youtube-64-grey.png" alt="youtube">
                  <router-link v-bind:to="team[teamSelected].link">{{team[teamSelected].linkLabel}}</router-link>
                </p>
              </div>
            </div> 
          </div>
        </div>    

      </div> 
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

import img_ricarda from '@/assets/team_ricarda.png'
//import img_ronja from '@/assets/team_ronja.png'
import img_anna from '@/assets/team_anna.png'
//import img_elisabeth from '@/assets/team_elisabeth.png'
import img_bastian from '@/assets/team_bastian.png'

import EmailClientService from '@/services/EmailClientService';

export default {
  name: 'Contact',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` } //,
        //{ vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Kontaktiere unser Team.",
      metaDescription: "Hier kannst Du uns kontaktieren und sehen wer hinter 'Happiness & Work' steckt.",
      metaUrl: "/contact",
      metaKeywords: "Kontakt Ansprechpartner Team 'Ricarda Rehwaldt'",
      metaImage: undefined,
      // contact dat
      team: [
        { name: "Prof. Dr. Ricarda Rehwaldt", title: "Gründerin von Felicicon", img: img_ricarda, email: "ricarda@happinessandwork.de", about: "Sie berät, begeistert und vernetzt all diejenigen, die Glück am Arbeitsplatz nicht für Zufall halten. Sie hat viele Jahre dazu geforscht was Menschen bei der Arbeit glücklich macht. Jetzt macht sie andere glücklich, indem sie ihr Wissen in der Happiness & Work-Akademie weitergibt.", link: "academy/keynotes", linkLabel: "Ricarda live erleben"},
        //{ name: "Ronja Hüttinger", title: "", img: img_ronja, email: "ronja@happinessandwork.de", about: "Ronja hat die Sonne im Herzen und sie ist euer Ansprechpartner Nummer eins für einfach alle Fragen, z.B. rund um Seminare, Vorträge, Buchungen und Termine. " },        
        { name: "Anna Niesing", title: "", img: img_anna, email: "anna@happinessandwork.de", about: "Wenn Du Fragen rund um unsere Seminare hast, ist Anna genau die richtige Ansprechpartnerin. Anna ist Psychologin, Moderatorin, Coach & Beraterin und außerdem abenteuerlustig und reisebegeistert. Wir (und unsere Seminarteilnehmer) lieben Sie für Ruhe und Klarheit und das tanzende Feuer in ihren Augen." },        
        { name: "Bastian Fehn", title: "", img: img_bastian, email: "bastian@happinessandwork.de", about: "Bastian Fehn ist Experte in der Konfliktmoderation und Kulturentwicklung von Organisationen. An Bastian lieben wir den strahlenden Optimismus, seine charmant proaktive Art und seine Fähigkeit immer ein Spielfilmzitat parat zu haben. Er ist der Richtige, wenn es um die HappinessHacks oder Seminare geht."}
        //{ name: "Elisabeth Wilhelm", title: "", img: img_elisabeth, email: "elisabeth@happinessandwork.de", about: "Elisabeth ist die richtige Ansprechpartnerin für alle Fragen rund um HR sowie People & Culture. Sie ist Unterstützerin und Beraterin dafür eure HR-Prozesse so zu gestalten, dass alle Abläufe und Kennzahlen eine Symbiose bilden, um die Bedarfe aller Stakeholder bestmöglich zu bedienen."}
      ],
      teamSelected: 0,
      form: {
        email: '',
        //phone: '',
        firstname: '',
        lastname: '',
        birthday: '',
        //salutation: null,
        position: [],
        interests: [],
        confirm: [],
        extra: ''
      },
      //salutation: [{ text: 'Bitte auswählen ...', value: null }, 'Frau', 'Herr', ''], 
      show: true,
      msgStatus: '',
      //phoneRegex: /\d?(\s?|-?|\+?|\.?)((\(\d{1,4}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)\d{3}(-|\.|\s)\d{4}/,
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      birthdayRegex: /(^.{0}$)|(^((?:0[1-9])|(?:1[0-2]))\/((?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))$)/
    }
  },
  computed: {
    stateFirstname() {return this.form.firstname.length >= 2 ? true : false},
    invalidLastname() {return (this.form.lastname.length < 2) ? '*' : ''},
    stateLastname() {return this.form.lastname.length >= 2 ? true : false},
    invalidFirstname() {return (this.form.firstname.length < 2) ? '*' : ''},
    stateEmail() {return (new RegExp(this.emailRegex).test(this.form.email)) ? true : false},
    invalidEmail() {return !(new RegExp(this.emailRegex).test(this.form.email)) ? '*' : ''},
    //stateEmailOrPhone() {return (new RegExp(this.phoneRegex).test(this.form.phone))  || (new RegExp(this.emailRegex).test(this.form.email)) ? true : false},
    //invalidEmailOrPhone() {return !(new RegExp(this.phoneRegex).test(this.form.phone)) && !(new RegExp(this.emailRegex).test(this.form.email)) ? 'Pflichtfeld' : ''},
    stateBirthday() {return (new RegExp(this.birthdayRegex).test(this.form.birthday)) ? true : false},
    invalidBirthday() {return !(new RegExp(this.birthdayRegex).test(this.form.birthday)) ? 'Monat/Tag (MM/TT)' : ''},
    confirmed() {return this.form.confirm.includes("ContactAllowed")}
  },
  // called when component is mounted to the dom
  mounted: function () {
/*     const anchor = this.$router.currentRoute.hash;
    setTimeout(() => { 
      if (anchor && document.querySelector(anchor)) {
        //var elem = document.querySelector(this.$route.hash);
        var elem = document.querySelector(anchor);
        console.log("scoll to: " + anchor); // eslint-disable-line no-console
        elem.scrollIntoView({ behavior: 'smooth', block: 'start'});        
      }
    }, 1000) */
  },
/*   beforeRouteUpdate (to, from, next) {
    console.log("bru from:: path:" + from.path + " hash: " + from.hash); // eslint-disable-line no-console
    console.log("bru  to:: path:" + to.path + " hash: " + to.hash);  // eslint-disable-line no-console
    next();
  }, */
  methods: {

    showConfirmBox(success) {
      var msg = success ? 'Deine Anfrage wurde verschickt.' 
                        : 'Deine Anfrage konnte nicht verschickt werden. Wir kümmern uns um das Problem. Bitte sende uns eine Email an info@happinessandwork.de. Vielen Dank';
      this.$bvModal.msgBoxOk(msg, {
        title: 'Benachrichtigung',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: (success ? 'success' : 'danger'),
        headerClass: 'p-3 border-bottom-0',
        footerClass: 'p-3 border-top-0',
        centered: true
      })
        .then(value => {
          this.msgStatus = value;
        })
        .catch(err => {
          this.msgStatus = err;
        })
    },

    // send email notification
    send(event) {
      event.preventDefault();
      //alert("Send email: " + JSON.stringify(this.form)); 

      const emailClientService = new EmailClientService();
      // create the callback function
      var cb = (data) => {
        //console.log("Send notification result: " + JSON.stringify(data.status)); // eslint-disable-line no-console
        this.showConfirmBox(data.status == 200 ? true : false );
      }
      emailClientService.sendNotification(this.form, cb);
    }

  }
}

</script>

<style>

</style>
