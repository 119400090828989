<template>

  <div id="welcome">

    <div class="c-img-bg c-orange-bg" >

      <div class="container-xl" style="position: relative;">
        <div style="position: absolute;">
          <img src="@/assets/puzzle.svg" alt="puzzle" style="transform: translate(-260px, -170px);">
        </div>
        <div class="row">
          <!-- navigation-->    
          <div class="col-12">
            <Navi showShare></Navi>
    
            <!-- how happy makes you your job -->
            <div class="row justify-content-center">
              <div class="col-11 col-md-10 col-lg-10">
                <div class="row d-flex align-items-center"> 
                  <div class="col-12 col-md-12 py-2 ">
                    <div class="d-flex justify-content-center justify-content-md-start">
                      <!-- button --> 
                      <router-link to="/info/happiness_and_work_report" style="text-decoration: none">
                        <div class="c-btn c-btn-white">
                          <span style="padding: 10px">Work-Happiness-Report 2024</span>
                          <div class="c-btn-img c-btn-img-right-orange"/>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-12 col-md-5 d-flex-column justify-content-center">                     
                      <h1 class="text-left c-txt-white c-h1">
                        Wie glücklich macht dich deine Arbeit?
                      </h1>
                      <p class="text-left c-txt-white">
                        <span>
                          Wäre es nicht wunderbar, wenn mehr Menschen ein bisschen glücklicher an ihrem Arbeitsplatz wären? 
                          Glück im Kontext der Arbeit entsteht aus drei Faktoren: Sinn&shy;empfinden, Selbst&shy;verwirklichung und Gemein&shy;schaft.
                        </span>
                     </p>
                  </div>

                  <!-- happiness circle -->
                  <div class="col-12 col-md-7">
                    <img src="@/assets/3f_circle.png" alt="HappinessAndWork_3_Faktoren_für_Glück" style="margin: 20px; width: 90%; max-width: 450px;">
                  </div>
  
                  <div class="col-12 col-md-12 py-2 ">
                    <div class="d-flex justify-content-center justify-content-md-start">
                      <!-- button --> 
                      <router-link to="/haw/selftest" style="text-decoration: none">
                        <div class="c-btn c-btn-white">
                          <span style="padding: 10px">Teste dein Glück</span>
                          <div class="c-btn-img c-btn-img-right-orange"/>
                        </div>
                      </router-link>
                      <!--
                      <div class="c-btn c-btn-white"  @click="gotoLocation('https://my.happinessandwork.de', '_self')">
                        <span style="padding: 10px;">Teste dein Glück</span>
                        <div class="c-btn-img c-btn-img-right-orange"/> <!- icon ->
                      </div>
                      -->
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom" style="position: relative; top:2px;"/>
    </div>

    <!-- sense - selfactualization - comunity -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 col-md-10">         
          <div class="row">
            <div class="offset-1 col-10 offset-md-0 col-md-12">
              <p class="c-txt-orange" style="font-weight: bold;">FORSCHUNGEN ZEIGEN</p>
              <h2 class="c-h2" style="color: #757575;">Glück im Kontext der Arbeit entsteht aus drei Faktoren</h2>          
            </div>
          </div>     
          <div class="row align-items-end">
            <div class="col-12 order-1 col-md-4 order-md-1">
              <img src="@/assets/3f_sense.svg" alt="HappinessAndWork_Sinn" style="margin: 20px; width: 100%; max-width: 280px;">
            </div>
            <div class="col-12 order-2 col-md-4 order-md-4">
              <h2 class="c-h2" style="color: #7CC9DB;">Sinnempfinden</h2>
            </div>
            <div class="col-12 order-3 col-md-4 order-md-7 align-self-start py-3 text-left">
              <p>
                Sinnempfinden erleben Menschen im Kontext ihrer Arbeit immer dann, wenn sie ein Ziel erreichen, 
                einen Beitrag zu einem übergeordneten Ziel leisten oder wenn ihre Arbeit einen Nutzen für andere Menschen hat. 
                Also immer dann, wenn sie sehen: Meine Arbeit wird wirklich gebraucht.
              </p>
            </div>  
            <div class="col-12 order-4 col-md-4 order-md-2">
              <img src="@/assets/3f_selfactualization.svg" alt="HappinessAndWork_Selbstverwirklichung" style="margin: 20px; width: 100%;  max-width: 280px;">
            </div>  
            <div class="col-12 order-5 col-md-4 order-md-5">
              <h2 class="c-h2 c-txt-orange">Selbst&shy;verwirklichung</h2>
            </div>
            <div class="col-12 order-6 col-md-4 order-md-8 align-self-start py-3 text-left">
              <p>
                „Meine Arbeit macht Spaß!“ ist ein typischer Ausspruch von Menschen, die sich in ihrer Arbeit selbst verwirklichen können. 
                Diese Mitarbeitenden setzen ihre Stärken ein, um ihre Ideen im Unternehmen umzusetzen, 
                haben Handlungsspielraum und entfalten ihre Potenziale immer weiter.
              </p>
            </div>
            <div class="col-12 order-7 col-md-4 order-md-3">
              <img src="@/assets/3f_comunity.svg" alt="HappinessAndWork_Gemeinschaft" style="margin: 20px; width: 100%; max-width: 280px;">
            </div>
            <div class="col-12 order-8 col-md-4 order-md-6">
              <h2 class="c-h2" style="color: #F5A019;">Gemeinschaft</h2>
            </div>            
            <div class="col-12 order-9 col-md-4 order-md-9 align-self-start py-3 text-left">
              <p>
                Wann ist mein Team eine echte Gemeinschaft? Eine Gemeinschaft zeichnet sich durch ein gemeinsames und verbindendes Ziel, 
                einen professionellen und geschützten Umgang und den Grad der Vertrautheit ihrer Mitglieder aus. 
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="d-flex justify-content-center">  
                <!-- button -->
                <router-link to="/haw/effects" style="text-decoration: none">
                  <div class="c-btn c-btn-orange">
                    <span style="padding: 10px">Effekte von Glück</span>
                    <div class="c-btn-img c-btn-img-right-white"/>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- academy -->      
    <div class="c-grey-bg">
      <!-- wave top -->
      <div class="c-wave c-wave-top"/>
      
      <!-- middle -->
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-11 col-md-10 py-5">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-7 py-2">
                    <div class="row">
                      <div class="col-12 py-2">
                        <h2 class="c-h2" style="color: #757575;">Happiness & Work Academy</h2> 
                      </div>
                      <div class="col-12 text-left">
                        <p>Wäre es nicht schön, wenn alle ein bisschen glücklicher beim Arbeiten wären? 
                          Das haben wir uns auch gefragt und deshalb Angebote zu Deiner Unterstützung, entwickelt: 
                          Für Dich persönlich, aber auch für Menschen, die Menschen unterstützen - wie Coaches, Feelgoodmanager und  HRler. 
                          In der Akademie bringen wir euch bei, wie Glück und Arbeit zusammenpassen, 
                          was die Wissenschaft darüber weiß und welche Methoden es gibt. 
                        </p> 
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex justify-content-center ">  
                        <!-- button -->
                        <router-link to="/academy" style="text-decoration: none">
                          <div class="c-btn c-btn-orange">
                            <span style="padding: 10px">Mehr erfahren</span>
                            <div class="c-btn-img c-btn-img-right-white"/>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-5">
                    <div id="laptop_1" style="position: relative;">
                      <img src="@/assets/MacBook-Silver.png" alt="Notebook" style="height: 400px">
                      <!--
                      <img src="@/assets/lucky_girls_1.jpg" style="position: absolute; top: 20px; left: 112px; height: 280px; width: 432px;">
                      -->
                      <div style="position: absolute; top: 20px; left: 112px; height: 280px; width: 432px;">
                        <b-carousel
                          id="carousel_academy"
                          v-model="slide"
                          :interval="4000"
                          fade
                          indicators
                          background="#ababab"
                          img-width="1024"
                          img-height="600"
                          style="text-shadow: 1px 1px 2px #333;"
                          @sliding-start="onSlideStart"
                          @sliding-end="onSlideEnd"
                        >
                          <!-- Slides with img slot -->
                          <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                          <b-carousel-slide 
                            caption="Positive Leadership"
                            text="Instrumente einer modernen Führung">
                            <template v-slot:img>
                              <img
                                class="d-block img-fluid w-100"
                                width="1024"
                                height="480"
                                src="@/assets/start_academy_pl.jpg"
                                alt="HappinessAndWork_Positive_Leadership" 
                              >
                            </template>
                          </b-carousel-slide>
                          <b-carousel-slide 
                            caption="Happiness & Homeoffice"
                            text="Den Arbeitsalltag im Homefoffice gestalten">
                            <template v-slot:img>
                              <img
                                class="d-block img-fluid w-100"
                                width="1024"
                                height="480"
                                src="@/assets/start_academy_hho.png"
                                alt="HappinessAndWork_Happiness_and_Homeoffice" 
                              >
                            </template>
                          </b-carousel-slide>
                          <b-carousel-slide 
                            caption="Dein Coaching"
                            text="Persönliche Begleitung auf dem Weg zum Glück ;-)">
                            <template v-slot:img>
                              <img
                                class="d-block img-fluid w-100"
                                width="1024"
                                height="480"
                                src="@/assets/start_academy_co.png"
                                alt="HappinessAndWork_Coaching" 
                              >
                            </template>
                          </b-carousel-slide>
                          <b-carousel-slide 
                            caption="Happiness & Work für Dich"
                            text="Das Glück in die eigenen Hände nehmen">
                            <template v-slot:img>
                              <img
                                class="d-block img-fluid w-100"
                                width="1024"
                                height="480"
                                src="@/assets/start_academy_hwfy.jpg"
                                alt="HappinessAndWork_for_you" 
                              >
                            </template>
                          </b-carousel-slide>
                          <b-carousel-slide 
                            caption="Individuelle Keynotes & Seminare"
                            text="Zugeschnitten für dein Unternehmen">
                            <template v-slot:img>
                              <img
                                class="d-block img-fluid w-100"
                                width="1024"
                                height="480"
                                src="@/assets/start_academy_kn.png"
                                alt="HappinessAndWork_Keynotes" 
                              >
                            </template>
                          </b-carousel-slide>
                          <b-carousel-slide 
                            caption=""
                            text="">
                            <template v-slot:img>
                              <img
                                class="d-block img-fluid w-100"
                                width="1024"
                                height="480"
                                src="@/assets/start_academy_hh.png"
                                alt="HappinessAndWork_Happiness_Hacks" 
                              >
                            </template>
                          </b-carousel-slide>
                        </b-carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Welcome',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` },
        { vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Wäre es nicht wunderbar wenn jeder Mensch bei seiner Arbeit glücklich sein könnte?",
      metaDescription: "'Happiness & Work' ist ein Projekt der FELICICON GmbH und unterstützt Dich und andere Menschen dabei, einfach etwas glücklicher bei der Arbeit zu sein. Es sind genau drei Dinge die hier wichtig sind - Sinnempfinden, Sebstverwirklichung und Gemeinschaft. ",
      metaUrl: "/welcome",
      metaKeywords: "'FELICICON GmbH' 'Happinessandwork Academy' Happinessandwork 'Ricarda Rehwaldt'",
      metaImage: "https://www.happinessandwork.de/share/welcome.png",
      // sliding
      slide: 0,
      sliding: null
    }
  },
  created: function () {
    },
    // `this` points to the vm instance
  mounted: function () {
    },
    methods: {
      // carousel
      onSlideStart(slide) {
        this.slide = slide
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.slide = slide
        this.sliding = false
      },

      // got to location
      gotoLocation(url, target) {
        window.open(url, target);    
      }
    }
}


</script>

<style>

.c-img-bg {
  background-size: cover;
  background-position-x: center;
}
.c-dp-bg { background-image: url('~@/assets/lucky_girls.jpg'); }
.c-imprint-bg { background-image: url('~@/assets/lucky_girls.jpg'); }
.c-academy-bg { background-image: url('~@/assets/lucky_girls.jpg'); }
.c-contact-bg { background-image: url('~@/assets/lucky_girls.jpg'); }
.c-effects-bg { background-image: url('~@/assets/lucky_girls.jpg'); }

.c-orange-bg { background: linear-gradient(44.21deg, #E9561F 3.36%, #F5A019 91.5%); }
.c-orange-bg { background: linear-gradient(136deg, #F5A019 3.36%, #E9561F 91.5%); }
.c-grey-bg { background-color: #f7f7f7; }

.c-logo-orange {
  content: url('~@/assets/logo_orange.svg');
  width: "148px";
  height: "72px";
}
.c-logo-white {
  content: url('~@/assets/logo_white.svg');
  width: "148px";
  height: "72px";
}
/* waves */
.c-wave {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 50px;
}
.c-wave-top {
  background-image: url('~@/assets/wave_bottom_left.svg');
  background-position: top;
}
.c-wave-bottom {
  background-image: url('~@/assets/wave_top_right.svg');
  background-position: bottom;
}

.c-txt-white {
  color: white !important;
}
.c-txt-dark {
  color: #5c5c5c;
}
.c-txt-orange {
  color: #E9561F;
}
.c-txt-blue {
  color: #7CC9DB;
}
.c-txt-yellow {
  color: #F5A019;
}

.c-h3 {
  font-weight: 200;
}
.c-h2 {
  font-weight: 500;
}
.c-h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

/* responsive styles */
/* 0 */
@media only screen and (min-width: 0px) {
  .c-wave {  height: 80px; }
  .c-h1 { font-size: 2.2rem}
  .c-h2 { font-size: 1.8rem}
  .c-h3 { font-size: 1.4rem}
  /* welcome */
  #laptop{ position: relative; top: 20px; left: -40px;}
  #carousel_academy .carousel-caption, #carousel_academy .carousel-indicators {right: 60%; left: 0}
  .c-diagram {height: 260px;}
  /* navi */
  #button_top { position: fixed; bottom: 10px; right: 20px; z-index: 2000; padding-left: 0px; }
  #button_back { position: fixed; bottom: 10px; z-index: 2000; padding-left: 0px; }
  /* effects */
  #bubble_sense { transform: translate(0px, 40%);}
  #bubble_self { transform: translate(0px, 0px);}  
  #bubble_comm { transform: translate(0px, 40%);}
  #bubble_happi { transform: translate(0px, 0px);}
  #bubble_mood { width: 80%; }
  #bubble_creativity { width: 80%; }
  #bubble_power { width: 80%; }
  #bubble_power_2 { width: 100px; position: absolute; left: 30px; top: -70px; }
  #bubble_motivation { width: 100px; position: absolute; left: 30px; top: -100px;}
  #bubble_moti { width: 80%; }
  #bubble_moti_2 { width: 70px; position: absolute; right: 60px; top: -100px;}
  /* academy */
  #bubble_academy_1 { width: 120px; transform: translate(0%, 40%) rotate(180deg) }
  #bubble_course_1 { width: 80%; }
  #bubble_course_2 { width: 80%; }
  #bubble_course_3 { width: 80%; }
  #bubble_course_4 { width: 80%; }
  /* academy pl */
  #bubble_pl { width: 80%; }
  #bubble_pl_course_1 { width: 80%; }
  #bubble_pl_course_2 { width: 80%; }
  #bubble_pl_course_3 { width: 80%; }
  /* academy co */
  #bubble_co { width: 80%; }
  /* academy kn */
  #bubble_kn { width: 80%; }
  /* academy kn top coaches*/
  #kn_top_coaches { width: 50%; }
  /* academy hh */
  #bubble_hh { width: 80%; }
  /* academy hho */
  #bubble_hho { width: 80%; }
  /* academy hho */
  #bubble_hwfy { width: 80%; }
  /* contact */
  #bubble_contact_1 { width: 130px; position: absolute; right: 20px; top: 650px;}
  #arc { width: 90%; position: absolute;}
  #team_left { position: relative; top: 10px; width: 60%; min-width: 60px; max-width: 150px;}
  #team_middle { position: relative; top: -30px; width: 60%; min-width: 60px; max-width: 150px;}  
  #team_right { position: relative; top: 10px; width: 60%; min-width: 60px; max-width: 150px;}
  #team_container { margin-bottom: 80px; margin-top: 20px;} 
  #team_cloud { width: 120%; position: relative; text-align: center; transform: scale(2.3) translate(0, 15px);}
  #team_text { width: 100%;}
    /* info */
  #book_1 { width: 80%; max-width: 220px; }  
  #book_2 { width: 80%; max-width: 220px; }
  #book_3 { width: 80%; max-width: 220px; }
  #bubble_interview {transform: scale(1.0) translate(50%, 0%);}
  #bubble_scale { width: 100%; }

  #pod_1 { width: 90%; max-width: 300px; }  
  #pod_2 { width: 90%; max-width: 300px; }
  #pod_3 { width: 90%; max-width: 300px; }
  #pod_4 { width: 90%; max-width: 300px; }
  #pod_5 { width: 90%; max-width: 300px; }

  #press_1 { width: 90%; max-width: 500px; border-radius: 25px;}  

  #bubble_haw_report_1 { width: 130px; position: absolute; right: -40px; top: 450px;}

  /* footer */
  .c-footer { margin-top: 180px; }
  #happy_employee {background-color: rgba(255, 255, 255, 0.816); padding: 20px; margin: 20px; border-radius: 20px; 
                   box-shadow: rgba(78, 46, 35, 0.4) 0px 13px 20px; position: absolute; z-index: 1000; top: -330px;}
  /* survey */
  .c-survey-diagram {height: 300px;}
}
/* xs */
@media only screen and (min-width: 300px) {
  .c-wave {  height: 90px; }
}
/* xs+ */
@media only screen and (min-width: 360px) {
    /* welcome */
  #carousel_academy .carousel-caption, #carousel_academy .carousel-indicators {right: 50%; left: 5%}
  .c-diagram {height: 300px;}
  /* contact */
  #team_cloud { width: 100%; transform: scale(2.0) translate(0, 15px);}
}
/* sm- */
@media only screen and (min-width: 425px) {
  /* welcome */
  #carousel_academy .carousel-caption, #carousel_academy .carousel-indicators {right: 30%; left: 15%}
  .c-diagram {height: 350px;}
  /* contact */
  #team_cloud { transform: scale(1.8) translate(0, 15px);}
  #team_left {  top: 20px; }
  #team_middle { top: -40px;}  
  #team_right { top: 20px; }
  /* info */
  #bubble_haw_report_1 { width: 130px; position: absolute; right: -40px; top: 350px;}
  /* footer */
  .c-footer { margin-top: 100px; }
  #happy_employee { top: -250px;}
  /* survey */
  .c-survey-diagram {height: 400px;}
}
/* sm */
@media only screen and (min-width: 576px) {
  .c-wave {  height: 110px; }
  /* welcome */
  #laptop { top: 20px; left: -20px;}
  #carousel_academy .carousel-caption, #carousel_academy .carousel-indicators {right: 20%; left: 15%}
  .c-diagram {height: 450px;}
  /* contact */
  #team_cloud { transform: scale(1.3) translate(0, 15px);}
  #team_text { width: 70%;}
  /* survey */
  .c-survey-diagram {height: 550px;}
}
/* md*/
@media only screen and (min-width: 768px) {
  .c-wave {  height: 120px; }
  .c-h1 { font-size: 1.9rem}
  .c-h2 { font-size: 1.6rem}
  .c-h3 { font-size: 1.3rem}
  /* welcome */
  #laptop { top: 70px; left: -40px;}
  #carousel_academy .carousel-caption, #carousel_academy .carousel-indicators {right: 45%; left: 15%}
  .c-diagram {height: 340px;}
  /* effects */
  #bubble_sense {transform: translate(30%, 0px);}
  #bubble_self {transform: translate(0px, 10%);}  
  #bubble_comm {transform: translate(70%, 0px);}
  #bubble_happi {transform: translate(0px, 40%);}
  #bubble_mood {transform: scale(1.2) translate(15%, -20%);}
  #bubble_creativity {transform: scale(1.4) translate(-10%, -25%);}
  #bubble_power {transform: scale(1.3) translate(-10%, 0%);}
  #bubble_motivation { width: 120px; position: absolute; left: auto; right: 30px; top: -70px;}
  #bubble_moti {transform: scale(1.3) translate(10%, -10%);}
  #bubble_moti_2 { width: 80px; position: absolute; right: 250px; top: -100px;}
  /* academy */
  #bubble_academy_1 { width: 180px; transform: translate(0%, 40%) rotate(150deg) }
  #bubble_course_1 {transform: scale(2.0) translate(-20%, -40%);}
  #bubble_course_2 {transform: scale(1.4) translate(15%, -50%);}
  #bubble_course_3 {transform: scale(1.7) translate(-50px, -30%);}
  #bubble_course_4 {transform: scale(1.6) translate(15%, -20%);}
  /* academy pl */
  #bubble_pl {transform: scale(2.0) translate(20%, 0%);}
  #bubble_pl_course_1 {transform: scale(2.0) translate(-10%, 0%);}
  #bubble_pl_course_2 {transform: scale(2.0) translate(10%, 0%);}
  #bubble_pl_course_3 {transform: scale(2.0) translate(-10%, 0%);}
  /* academy co */
  #bubble_co {transform: scale(1.6) translate(15%, -20%);}
  /* academy kn */
  #bubble_kn {transform: scale(2.0) translate(-20%, -10%);}
  /* academy kn top coaches*/
  #kn_top_coaches {transform: scale(3.0) translate(-40%, 0%);}
  /* academy hh */
  #bubble_hh {transform: scale(2.0) translate(-20%, -10%);}
  /* academy hho */
  #bubble_hho {transform: scale(1.9) translate(-20%, -0%);}
  /* academy hwfy */
  #bubble_hwfy {transform: scale(1.9) translate(-20%, -10%);}
  /* contact */
  #team_container { margin-bottom: 20px;} 
  #team_cloud { transform: scale(1.2) translate(0, 0);}
  #team_left {  top: 30px; }
  #team_middle { top: -50px;}  
  #team_right { top: 30px; }
  /* info */
  #bubble_interview {transform: scale(1.6) translate(0%, 10%);}
  #bubble_haw_report_1 { width: 130px; position: absolute; right: 20px; top: 300px;}
    /* footer */
  .c-footer { margin-top: 50px; }
  }
/* md+ */
@media only screen and (min-width: 860px) {
  /* academy */
  #bubble_course_1 {transform: scale(1.9) translate(-20%, -30%);}
  #bubble_course_2 {transform: scale(1.4) translate(15%, 0px);}
  #bubble_course_3 {transform: scale(1.5) translate(-30px, -15%);}
  #bubble_course_4 {transform: scale(1.3) translate(15%, -10%);}
  /* academy pl */
  #bubble_pl {transform: scale(1.7) translate(15%, 5%);}
  /* academy co */
  #bubble_co {transform: scale(1.3) translate(15%, -10%);}
  /* academy kn */
  #bubble_kn {transform: scale(1.9) translate(-20%, -10%);}
  /* academy kn top coaches*/
  #kn_top_coaches {transform: scale(2.7) translate(-80%, 0%);}
  /* academy hh */
  #bubble_hh {transform: scale(1.9) translate(-20%, -10%);}
  /* academy hho */
  #bubble_hho {transform: scale(1.7) translate(-20%, 0%);}
  /* academy hwfy */
  #bubble_hwfy {transform: scale(1.8) translate(-20%, -10%);}
  /* info */
  }
/* lg*/
@media only screen and (min-width: 992px) {
  .c-wave {  height: 130px; }
  /* welcome */
  #laptop{ top: 30px; left: -40px;}
  #carousel_academy .carousel-caption, #carousel_academy .carousel-indicators {right: 40%; left: 15%}
  #bubble_ident {transform: scale(1.3) translate(15%, -40%);}
  .c-diagram {height: 450px;}
  /* effects */
  #bubble_moti_2 { width: 120px; position: absolute; right: 250px; top: -200px;}
  /* academy */
  #bubble_course_1 {transform: scale(1.5) translate(-20%, -15%);}
  #bubble_course_2 {transform: scale(1.3) translate(15%, 10%);}
  #bubble_course_3 {transform: scale(1.2) translate(-40px, -5%);}
  #bubble_course_4 {transform: scale(1.2) translate(10%, 10%);}
  /* academy pl */
  #bubble_pl {transform: scale(1.5) translate(15%, 10%);}
  #bubble_pl_course_1 {transform: scale(1.8) translate(-10%, 0%);}
  #bubble_pl_course_2 {transform: scale(1.8) translate(10%, 0%);}
  #bubble_pl_course_3 {transform: scale(1.8) translate(-10%, 0%);}
  /* academy co */
  #bubble_co {transform: scale(1.2) translate(10%, 10%);}
  /* academy kn */
  #bubble_kn {transform: scale(1.5) translate(-20%, -5%);}
  /* academy kn top coaches*/
  #kn_top_coaches {transform: scale(2.0) translate(-100%, 0%);}
  /* academy hh */
  #bubble_hh {transform: scale(1.5) translate(-20%, -5%);}
  /* academy hho */
  #bubble_hho {transform: scale(1.4) translate(-20%, -0%);}
  /* academy hwfy */
  #bubble_hwfy {transform: scale(1.5) translate(-20%, -5%);}
  /* info */
  #bubble_haw_report_1 { width: 130px; position: absolute; right: 20px; top: 250px;}
  /* contact */
  #team_cloud { transform: scale(1.0) translate(0, 0);}
  #team_left {  top: 40px; }
  #team_middle { top: -60px;} 
  #team_right { top: 40px; }
}
/* xl*/
@media only screen and (min-width: 1200px) {
  .c-wave {  height: 150px; }
  .c-h1 { font-size: 1.8rem}
  /* welcome */
  #laptop{ top: 30px; left: -40px;}
  #carousel_academy .carousel-caption, #carousel_academy .carousel-indicators {right: 15%; left: 15%}
  .c-diagram {height: 500px;}
  /* navi */
   #button_top { right: auto; margin-left: 1056px; }
}

.c-wave-middle {
  background-color: #f7f7f7;
}

.c-team-selected {
  transform: scale(1.5);
}
/* share */
.c-share-white {
  position: fixed; 
  right: 0; 
  top: calc(50% - 125px);
  background: white;
  box-shadow: 0px 13px 20px rgba(78, 46, 35, 0.4);  
  border-top-left-radius: 15px;  
  border-bottom-left-radius: 15px;  
  color: #050505;
  cursor: pointer;
  z-index:1000;
}
.c-share-white:hover {
    box-shadow: 0px 13px 20px rgba(78, 46, 35, 0.8);
}

/* buttons */
.c-btn {
    border-radius: 26px;
    padding-left: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*font-family: 'Roboto Condensed';*/
}

.c-btn-white {
    background: white;
    box-shadow: 0px 13px 20px rgba(78, 46, 35, 0.4);    
    color: rgb(255, 145, 0);
    padding-right: 0px;
}
.c-btn-white:hover {
    box-shadow: 0px 13px 20px rgba(78, 46, 35, 0.8);
}
.c-btn-orange {
    /*
    background: linear-gradient(349.97deg, #E9561F 0%, #F5A019 100%);
    padding-right: 8px;
    */
    background: linear-gradient(60deg, #f0831b 0%, #F5A019 100%);
    box-shadow: 0px 13px 20px rgba(189, 118, 92, 0.4);    
    color: white;

}
.c-btn-orange:hover {
    box-shadow: 0px 13px 20px rgba(189, 118, 92, 0.8);
}
.c-btn-img {
  background-repeat: no-repeat;
  background-size: cover;
  width: 42px;
  height: 42px;
  margin: 4px;
}
.c-btn-img-right-orange { background-image: url(~@/assets/btn_arrow_right_orange.svg); }
.c-btn-img-left-orange { background-image: url(~@/assets/btn_arrow_left_orange.svg); }
.c-btn-img-up-orange { background-image: url(~@/assets/btn_arrow_up_orange.svg); }
.c-btn-img-right-white { background-image: url(~@/assets/btn_arrow_right_white.svg); }
.c-btn-img-left-white { background-image: url(~@/assets/btn_arrow_left_white.svg); }
.c-btn-img-up-white { background-image: url(~@/assets/btn_arrow_up_white.svg); }

.c-btn-img-white {
  align-self: stretch;
  width: 60px;
  background-image: url(~@/assets/btn_arrow_right_orange.svg);
}

.c-social {
  height: 24px;
  padding-right: 10px;
}
/* fonts */
.c-roboto {
    font-family: "Nunito";
}
.c-roboto-cond {
    font-family: "Nunito";
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#share_bar {
  position: fixed;
  right: 0;
  top: 50%;
  background-color: grey;
  height: 50px;
  width: 50px;
  border-top-left-radius: 10px;
  /* border-radius: 10px; */
  border-bottom-left-radius: 10px;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.c-link-white, .c-link-white:hover {
  color: white;
  text-decoration: none;
}
.c-link {
  cursor: pointer;
}

a:hover {
  text-decoration:none
}

#app {
  font-family: 'Nunito', 'Lato', 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #5c5c5c;*/
  font-weight: 300;
  min-width: 320px;
  font-size: 1.1em;
}

/* @import url(http://fonts.googleapis.com/css?family=Lato:400,100,300,500,700,900); */
@import url(https://fonts.googleapis.com/css?family=Nunito:400,300,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700);

</style>
