<template>

  <div id="academy_kn">

    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- happiness & work for leaders -->  
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">
                <div class="row align-items-end">
                  <div class="col-12 order-1 offset-md-5 col-md-5 order-md-1 py-3 align-self-start">             
                    <p class="c-txt-orange" style="font-weight: bold;">Für dein Unternehmen</p>  
                    <h2 class="text-center text-md-center c-h2" >Keynotes</h2>
                    <h3 class="text-center text-md-center c-h3 c-link" @click="gotoLocation('https://www.linkedin.com/in/ricardarehwaldt','_blank')" >Prof. Dr. Ricarda Rehwaldt</h3>
                  </div>
                  <!--
                  <div class="col-12 order-2 col-md-2 order-md-2 align-self-center">
                    <a  href="https://derarthur.de/top-coaches-10-22-online-advertorial-webseite-manager-magazin/" target="_blank">
                      <img id="kn_top_coaches" src="@/assets/top_coaches_badge.png" alt="HappinessAndWork_TopCoaches_Badge">
                    </a>
                  </div>
                  -->
                  <div class="col-12 order-3 col-md-5 order-md-3 align-self-center">
                    <img id="bubble_kn" src="@/assets/academy_bubble_keynotes.png" alt="HappinessAndWork_Keynotes">
                  </div>
                  <div class="col-12 order-3 col-md-7 order-md-3 py-3 align-self-start">             
                    <p class="text-center text-md-left">
                      Prof. Dr. Ricarda Rehwaldt unterstützt Führungskräfte und Vorstände dabei, 
                      Erkenntnisse positiver Psychologie praktisch in ihren Unternehmen anzuwenden. 
                      Seit vielen Jahren erforscht sie, was Menschen bei der Arbeit glücklich macht. 
                      Heute macht die Autorin mehrerer Bücher und Gründerin der Happiness & Work – Academy andere glücklich, 
                      indem sie ihr Wissen in Unternehmen trägt. Sie verzaubert in Keynotes durch Ehrlichkeit 
                      sowie leichte frech-fröhliche Sprache und steckt damit das Publikum an.
                    </p>
                      <div class="d-flex justify-content-left">  
                      <!-- button -->
                      <a  href="mailto:info@happinessandwork.de">
                        <div class="c-btn c-btn-orange" >
                          <span style="padding: 10px">Keynote anfragen</span>
                          <div class="c-btn-img c-btn-img-right-white"/>
                        </div>
                      </a>
                    </div>
                    <!--
                    <p class="text-left">
                      <a href="/downloads/HappinessAndWork_für_dich.pdf" download="HappinessAndWork_für_dich.pdf">Unser Themenspektrum & Deine Vorbereitung</a>
                    </p>
                    -->
                    <!--
                    <p class="text-left">
                      <a  href="mailto:ricarda.rehwaldt@happinessandwork.de">
                        <img id="mail" class="c-social" src="@/assets/icon_email-64-grey.png"> Kontakt aufnehmen
                      </a>
                    </p>
                    -->
                    <br>
                    <p id="Ricarda" class="text-center text-md-left c-txt-orange">
                      Hier kannst Du Ricarda in Aktion erleben:
                    </p> 
                  </div>

                  <div class="col-12 order-3 offset-md-3 col-md-9 order-md-3 py-3"> 
                    <div id="laptop_1" style="position: relative; ">
                      <img src="@/assets/MacBook-Silver.png" alt="HappinessAndWork_Mac" style="width: 130%; min-width: 300px;">
                      <div style="position: absolute;top: 5%;left: 21.5%;right: -7%; min-width: 210px; bottom: 26%;">
                        <!-- youtube iframe with progressive enhancement (extra queries after the url to optimize the embed) -->
                        <vue-plyr ref="plyr" style="--plyr-color-main: orange;">
                          <div class="plyr__video-embed">
                            <iframe
                              src="https://www.youtube.com/embed/l7W22pNr7_I?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
                              allowfullscreen
                              allowtransparency
                              allow="autoplay"
                            ></iframe>
                          </div>
                        </vue-plyr>
                        <!--
                        <vue-plyr ref="plyr">
                          <div data-plyr-provider="youtube" data-plyr-embed-id="l7W22pNr7_I"></div>
                        </vue-plyr>S
                        -->
                      </div>
                    </div>
                  </div>

                  <div class="col-12 order-4 offset-md-5 col-md-7 order-md-4">  
                    <p class="text-center text-md-left" style="top: -200px;">
                      Mehr direkt auf  
                      <a href="https://www.youtube.com/channel/UCZDKbyVEQPNJAuulVq5l_Kw" target="_blank">Youtube</a>
                    </p>
                    <!-- contact -->
                    <!--
                    <div class="d-flex justify-content-left">  
                      <!- button ->
                      <a  href="mailto:info@happinessandwork.de">
                        <div class="c-btn c-btn-orange" >
                          <span style="padding: 10px">Schreib mir</span>
                          <div class="c-btn-img c-btn-img-right-white"/>
                        </div>
                      </a>

                    </div>
                    -->
                  </div>

                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Academy_KN',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` },
        { vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Academy - Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind?",
      metaDescription: "Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind? Die Felicicon GmbH hat Weiterbildungsangebote für Mitarbeiter und Führungskräfte und führen in Mitarbeiter Events in das Thema ein.",
      metaUrl: "/academy/keynotes",
      metaKeywords: "Seminar Beratung Coaching Teamentwicklung Keynotes 'Happinessandwork Academy'",
      metaImage: "https://www.happinessandwork.de/share/academy.png"
    }
  },
  // called when component is mounted to the dom
  mounted: function () {
  },
  methods: {
      // got to location
      gotoLocation(url, target) {
        window.open(url, target);    
      }
  }
}

</script>

<style>

</style>
