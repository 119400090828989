/*
 This module provide the client for sending email notifications to the backend.
 If the origin includes 'localhost' it will use LOCAL_HOST as host.

*/

import axios from 'axios'
import https from 'https'

// survey id
const LOCAL_HOST = 'http://ccu3-webui.fritz.box/addons/red';
const API_ROOT = '/mail-notification';

// construct the API endpoint URL
const getEndpoint = () => {
    // assume APIs are served from the same server as Webapp
    // must be used in production to prevent CORS problems
    var loc = window.location;
    var baseHoststring = loc.protocol + "//" + loc.host;
    var hoststring = baseHoststring;
    // you can overwrite it here
    //hoststring = 'http://cloudia.fritz.box';
    //hoststring = "https://my.happinessandwork.de";
    if (loc.host.indexOf('localhost') !== -1) {
        hoststring = LOCAL_HOST;
    }     
    // happiness API configuration
    var api =  hoststring + API_ROOT;
    return api;
}

// client module for email notification
export default class EmailClientService {

    // retrieve overall statistics
    // msg: message to be send
    // callback: function to process result

    // send email notification
    sendNotification(msg, callback) {

      //console.log("Send email: " + JSON.stringify(msg)); // eslint-disable-line no-console

      let api = getEndpoint();

      // POST  https://my.happinessandwork.de/mail-notification
      new https.Agent({ rejectUnauthorized: false, keepAlive: true });
      axios.post(api, {
          data: msg
        })
        .then(response => {
          //console.log("Success " + JSON.stringify(response)); // eslint-disable-line no-console
          callback(response);
        })
        .catch(error => {
          console.log("Send mail notification failed!" + error); // eslint-disable-line no-console
          callback(error);
        });

    }

  
  }

  /* service usage
    import EmailClientService from './services/EmailClientService';

    const emailClientService = new EmailClientService();

    // create the callback function
    var cb = (data) => { do something)
    }

    emailClientService.sendNotification(msg, cb);
  */