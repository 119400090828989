<template>

  <div id="footer">
    <div class="row justify-content-center  py-3">
      <div class="col-11 col-md-10">

        <!-- happy employees -->
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8" >
            <div id="happy_employee">
              <span class="text-center c-txt-orange" style="margin: 10px; font-size: 1.5rem; font-weight: 500;">
                Glück und Arbeit verbinden – es beginnt bei Dir und Deinem Team.
              </span>
              <div class="d-flex justify-content-center">  
                <!-- button -->
                <router-link to="/contact" style="text-decoration: none">
                  <div class="c-btn c-btn-orange" >
                    <span style="padding: 10px">Kontakt</span>
                    <div class="c-btn-img c-btn-img-right-white"/>
                  </div>
                </router-link>
              </div>  
            </div>
          </div>
        </div>
        <!-- happiness and work -->
        <div class="row justify-content-center">
          <div class="col-12 col-md-11 col-lg-10">
            <div class="row justify-content-center align-items-center py-3">    
              <div class="col-12 col-sm-3 py-2 d-flex justify-content-center">
                <router-link to="/welcome">
                  <img class="c-logo-white" alt="HappinessAndWork_Logo">
                </router-link>
              </div>
              <div class="col-12 offset-sm-1 col-sm-8">
                <div class="text-center text-sm-left">
                  Ist ein Projekt der <span class="c-link" @click="gotoLocation('https://www.felicicon.com/', '_blank')"><b>FELICICON GmbH</b></span> und unterstützt Dich und andere Menschen dabei, einfach etwas glücklicher bei der Arbeit zu sein.
                  Wir zeigen Dir worauf es ankommt und Du erzählst es weiter! 
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <hr style="background-color: white;"/>
        -->
        <!-- links -->
        <div class="row justify-content-center">
          <div class="col-12 col-md-11 col-lg-10">
             <div class="row justify-content-start py-3">
              <div class="col-12 py-3 col-sm-4 py-sm-0 d-flex flex-column">
                  <div class="text-sm-left"><b>ANGEBOT</b></div>
                  <div class="text-sm-left c-link c-link-white" @click="goto('/academy')">Akademie</div>
                  <div class="text-sm-left c-link c-link-white" @click="goto('/info/books#scale')">HappinessandWork-Scale</div>
                  <div class="text-sm-left c-link c-link-white" @click="goto('/academy/keynotes')">Vorträge</div>
                  <div class="text-sm-left c-link c-link-white" @click="goto('/info/books')">Publikationen</div>
              </div>
              <div class="col-12 py-3 col-sm-4 py-sm-0 d-flex flex-column">
                  <div class="text-sm-center"><b>UNTERNEHMEN</b></div>
                  <div class="text-sm-center c-link c-link-white" @click="goto('/contact#team')">Ansprechpartner</div>
                  <div class="text-sm-center c-link c-link-white" @click="goto('/info/press')">Presse</div>
                  <div class="text-sm-center c-link c-link-white" @click="goto('/contact')">Kontakt</div>
              </div>
              <div class="col-12 py-3 col-sm-4 py-sm-0 d-flex flex-column">
                  <div class="text-sm-right"><b>RECHTLICHES</b></div>
                  <div class="text-sm-right c-link c-link-white" @click="goto('/imprint')">Impressum</div>
                  <div class="text-sm-right c-link c-link-white" @click="goto('/dp')">Datenschutz</div>
              </div>
            </div>
          </div>
        </div>
        <!-- social media -->  
        <div class="row">                                 
          <div class="col-12 d-flex justify-content-center justify-content-sm-start py-2" >
            <a  href="mailto:info@happinessandwork.de">
              <img id="mail" class="c-social" src="@/assets/icon_email-64-white.png" alt="HappinessAndWork_Email">
            </a>
            <!--<img id="twitter" class="c-social c-link" src="@/assets/icon_twitter-64-white.png" @click="gotoLocation('https://twitter.com/happinessandwo1', '_blank')">-->
            <img id="linkedin" class="c-social c-link" src="@/assets/icon_linkedin-64-white.png" alt="HappinessAndWork_LinkedIn" style="top: -2px; position: relative;" @click="gotoLocation('https://www.linkedin.com/showcase/happinessandwork/about/?viewAsMember=true', '_blank')">
            <img id="instagram" class="c-social c-link" src="@/assets/icon_instagram-64-white.png"  alt="HappinessAndWork_Instagram" @click="gotoLocation('https://www.instagram.com/happinessand_work/', '_blank')">
            <!--<img id="youtube" class="c-social" src="@/assets/icon_youtube-64-white.png" @click="gotoLocation('https://www.felicicon.de', '_blank')">-->
            <img id="facebook" class="c-social c-link" src="@/assets/icon_facebook-64-white.png" alt="HappinessAndWork_Facebook" style="margin-left: -6px;" @click="gotoLocation('https://www.facebook.com/Happinessandwork-112278506964316', '_blank')">
          </div>
        </div>
        <hr style="background-color: white;"/>
        <!-- copyright -->
        <div class="row">    
          <div class="col-12">
            <div class="text-center text-sm-right">
              Copyright {{new Date().getFullYear()}} | <span class="c-link" @click="gotoLocation('https://www.felicicon.com/', '_blank')">FELICICON GmbH</span>
            </div>
          </div>
        </div> 
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Footer',
  components: {
  },
  data() {
        return {
        }
    },
    // called when component is mounted to the dom
    mounted: function () {
    },
    methods: {
      // navigate to to path (e.g. /contact#team)
      // if path is the same as current path ensure to scoll to the hash position
      // as default natigation would not change scoll position if on the same page
      goto(path) {
        if (this.$router.currentRoute.fullPath == path) {
          // change scroll position on current page
          const anchor = this.$router.currentRoute.hash;
          var elem = document.querySelector(anchor);
          //console.log("goto - scoll to: " + anchor); // eslint-disable-line no-console
          elem.scrollIntoView({behavior: 'smooth', block: 'start'});
        } else {
          // chnage path
          this.$router.push(path)
        }
      },
      // got to location
      gotoLocation(url, target) {
        window.open(url, target);    
      }
    }
}

</script>

<style>
</style>
