<template>

  <div id="info_books">
    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- info -->  
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">                    
                <div class="row">
                  <div class="col-12 d-flex align-items-center flex-column">
                    <h1 class="c-h1">Bücher & Artikel</h1>  
                    <p class="c-txt-orange" style="font-weight: bold;">Weiterführende Literatur zum Thema "Glück & Arbeit"</p>      
                  </div>
                  <div class="col-12 py-3 col-md-8 d-flex align-items-center justify-content-center">             
                    <p class="text-center text-md-left">
                      Hier findest Du eine Auswahl von Büchern und Artikeln, die wir geschrieben haben. 
                      Wenn Du mehr wissen möchtest kannst Du ein <router-link to="/academy">Seminar</router-link> besuchen, einen <router-link to="/info/podcasts">Podcast</router-link> anhören und uns auf <a href="https://www.instagram.com/happinessand_work/" target="_blank">Instagram</a> & <a href="https://www.linkedin.com/showcase/happinessandwork/" target="_blank">LinkedIn</a> folgen. 
                      Außerdem kannst Du den Newsletter abonnieren (link) – so bist du perfekt informiert und verpasst nichts mehr. 
                      <a  href="mailto:info@happinessandwork.de">info@happinessandwork.de</a>.
                    </p>
                  </div>
                  <div class="col-12 py-3 col-md-4 d-flex justify-content-end">             
                    <img id="bubble_academy_1" src="@/assets/bubble_red_1.png" alt="bubble">  
                  </div>
                </div>    
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- books -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <div class="row align-items-start">
            <div class="col-12 py-3">             
              <h2 id="books" class="text-center text-md-center c-h2" >Bücher</h2>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10">
              <!-- book 1 -->
              <div class="row justify-content-center">  
                <div class="col-12 order-1 py-3 text-left text-md-left  ">
                  <h3 id="dgo" class="c-txt-orange"><b>Die glückliche Organisation</b></h3>
                </div>
                <div class="col-12 order-2 col-md-5 py-3 text-left text-md-left">
                  <img id="book_1" src="@/assets/books/die_glueckliche_organisation.png" alt="Buch_Die_glückliche_Organisation"> 
                </div>
                <div class="col-12 order-3 col-md-7 py-3 text-left ">            
                  <p>
                    Dieses Buch beinhaltet eine Studie zu Glück und Arbeit und eröffnet Einblicke zu Chancen und Hürden für positive Psychologie im Unternehmen. 
                    Glück und Zufriedenheit werden differenziert und folgende Aspekte des Glücks näher unter die Lupe genommen: Bedingungen, Entstehung, 
                    Einflussfaktoren und Gestaltbarkeit des Glücks in Organisationen. 
                    Dieses Buch zeigt Modellentwicklung in der Forschung, ist aber nicht andwendungsorientiert. (2017 / 360 Seiten)
                  </p>
                  <ul>
                    <li>Verlag: Springer Gabler</li>
                    <li>ISBN: 978-3-658-19250-1</li>
                    <li><a href="https://www.springer.com/de/book/9783658192501" target="_blank">Buch - Die glückliche Organisation</a></li>
                  </ul>
                </div>
              </div> 
              <!-- book 2 -->
              <div class="row justify-content-center">
                <div class="col-12 order-1 order-md-1 py-3 text-left text-md-right ">
                  <h3 class="c-txt-orange"><b>Glück im Unternehmen</b></h3>
                </div>
                <div class="col-12 order-2 col-md-5 order-md-3 py-3 text-left text-md-right">
                  <img id="book_2" src="@/assets/books/glueck_im_unternehmen.png" alt="Buch_Glück_im_Unternehmen"> 
                </div>
                <div class="col-12 order-3 col-md-7 order-md-2 py-3 text-left ">              
                  <p>
                    Positive Psychologie für Führung und Organisationsentwicklung kompakt: 
                    Die kleine Schwester der glücklichen Organisation – geschrieben für Führungskräfte, 
                    Berater und Unternehmer – Menschen also, die in der Regel wenig Zeit haben. Es fasst zentrale Ergebnisse zusammen 
                    und gibt Denkanstöße für die Umsetzung im Unternehmen, verzichtet aber auf komplexe Literaturangaben. Gut für alle diejenigen, 
                    die einen schnellen und kompakten Einstieg zum Thema suchen. (2019 / 45 Seiten)
                  </p>
                  <ul>
                    <li>Verlag: Springer</li>
                    <li>ISBN: 978-3-658-22760-9</li>
                    <li><a href="https://www.springer.com/de/book/9783658227609" target="_blank">Buch - Glück im Unternehmen</a></li>
                  </ul>
                </div>
              </div> 
              <!-- book 3 -->
              <div class="row justify-content-center">
                <div class="col-12 order-1 order-md-1 py-3 text-left text-md-left ">
                  <h3 class="c-txt-orange"><b>Führung und Organisation</b></h3>
                </div>
                <div class="col-12 order-2 col-md-5 py-3 text-left text-md-left">
                  <img id="book_3" src="@/assets/books/fuehrung_und_organisation.png" alt="Artikel_Führung_und_Organisation"> 
                </div>
                <div class="col-12 order-3 col-md-7 py-3 text-left ">  
                  <p>
                    Ein Werk mit Fokus auf den Gesundheits- und Sozialwirtschaftssektor. 
                    Die Herausgeber Marlies Fröse, Beate Naake und Maik Arnold  haben diesen Sammelband konzipiert. 
                    Zum Thema Glück findet sich ein Beitrag zum: Narzissmus, Macht und Sozialisation als Handlungsbarrieren. 
                    Narzissmus und Macht bei Führungskräften sind Barrieren für Glück in Unternehmen. 
                    Aber auch für Mitarbeitende finden sich Barrieren – eine davon ist unsere Sozialisation, 
                    denn wer kennt sie nicht die Sätze wie: „Erst die Arbeit, dann das Vergnügen.“ – oder „Wer lacht, hat noch Kapazitäten.“ ... (2019)
                  </p>
                  <ul>
                    <li>Verlag: SpringerVS</li>
                    <li>ISBN: 978-3-658-24192-6</li>
                    <li><a href="https://link.springer.com/chapter/10.1007/978-3-658-24193-3_10" target="_blank">Buch - Führung und Organisation</a></li>
                  </ul>
                </div>
              </div>
              <!-- book 4 -->
              <div class="row justify-content-center">
                <div class="col-12 order-1 order-md-1 py-3 text-left text-md-right ">
                  <h3 class="c-txt-orange"><b>Fragebogen zur Erfassung von Glück bei der Arbeit</b></h3>
                </div>
                <div class="col-12 order-2 col-md-5 order-md-3 py-3 text-left text-md-right">
                  <img id="book_2" src="@/assets/books/fragebogen_zur_erfassung_von_glueck.png" alt="Buch_Fragebogen_zur_Erfassung_von_Glück_bei_der_Arbeit"> 
                </div>
                <div class="col-12 order-3 col-md-7 order-md-2 py-3 text-left ">              
                  <p>
                    Erfolge werden in Unternehmen gerne gemessen. Wir haben deshalb mit der HappinessandWork-Scale (HaWoS) ein Testverfahren entwickelt, 
                    das genau dies tut. Die HAWOS misst das Glücksempfinden der Mitarbeitenden einer Organisation im Arbeitskontext 
                    über zwölf Items anhand der drei Faktoren des Arbeitsglücks: Sinnempfinden, Selbstverwirklichung und Gemeinschaftsgefühl. 
                    Die Items der HAWOS sind berufsbezogen formuliert, die Entwicklungs- und Validierungsstudien wurden an Personen mit Berufserfahrung durchgeführt. 
                    Die Ergebnisse sind die Grundlage, um entlang der Bedürfnisse der Organisation Handlungsempfehlungen abzuleiten. 
                    Dieses Buch enthält eine Anleitung, Hintergründe und Normwerte zum Testverfahren, nicht aber das Verfahren selbst. (2023/ 90 Seiten)
                  </p>
                  <ul>
                    <li>Verlag: Springer</li>
                    <li>ISBN: 978-3-662-67673-8</li>
                    <li><a href="https://link.springer.com/book/10.1007/978-3-662-67673-8" target="_blank">Fragebogen zur Erfassung von Glück bei der Arbeit</a></li>
                    <li>Zum Testverfahren: <a href="https://testothek.online/products/hawos" target="_blank">https://testothek.online/products/hawos</a></li>
                  </ul>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- articles -->
    <div class="c-grey-bg">
      <!-- wave top -->
      <div class="c-wave c-wave-top"/>

      <!-- middle -->
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">
                <div class="row align-items-end">
                  <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">              
                    <h2 id="press" class="text-center text-md-center c-h2" >Artikel</h2>
                  </div>
                  <div class="col-12 order-2 col-md-2 order-md-3">
                    <img id="bubble_interview" src="@/assets/bubble_red_3.png" alt="bubble">
                  </div>
                  <div class="col-12 order-3 col-md-10 order-md-2 py-3 align-self-start text-left text-md-left">

                    <div>    
                      <h3 class="c-txt-orange" ><b>Does Remote Work Make People Happy?</b> <br>Effects of Flexibilization of Work Location and Working Hours on Happiness at Work and Affective Commitment in the German Banking Sector</h3>   
                      <p>
                        Artikel erschienen im Juli/20212 in "International Journal of Environmental Research and Public Health, 19(15), 9117".
                      </p>      
                      <blockquote class="blockquote">
                        <p class="mb-1" ><em>"Die Studie zeigt positive Effekte von Remote Work auf Glück bei der Arbeit und Commitment und liefert Belege dafür, 
                          dass selbst gewählte Arbeitsumgebungen und Arbeitszeiten eine Möglichkeit bieten, 
                          die Arbeit glücksfördernder zu gestalten - auch in einer Branche, die sich noch im Umbruch befindet."</em></p>
                        <footer class="blockquote-footer">Kortsch, T., Rehwaldt, R., Schwake, M. E., & Licari, C. (2022) in <cite title="Artikel"><a href="https://doi.org/10.3390/ijerph19159117" target="_blank">International Journal of Environmental Research and Public Health</a></cite></footer>
                      </blockquote>
                    </div>
                    <br>

                   <div>    
                      <h3 class="c-txt-orange" ><b>Was macht bei der Arbeit glücklich?</b> <br>Arbeits- und Organisationspsychologie A&O</h3>   
                      <p>
                        Artikel erschienen im September/2021 in "Arbeits- und Organisationspsycholie A&O" bei hofgrefe
                      </p>      
                      <blockquote class="blockquote">
                        <p class="mb-1" ><em>"Ziel dieses Beitrags ist deshalb, ein Messinstrument zur Erfassung von Bedingungen von Glück bei der Arbeit zu entwickeln und zu validieren. 
                          Basierend auf einem Modell von Rehwaldt (2017) entstand in vier aufeinander aufbauenden qualitativen und quantitativen Studien eine 
                          vierdimensionale Skala zur Erfassung von Bedingungen von Glück bei der Arbeit (HappinessandWork-Scale)"</em></p>
                        <footer class="blockquote-footer">Ricarda Rehwaldt & Timo Kortsch in <cite title="Artikel"><a href="https://econtent.hogrefe.com/doi/10.1026/0932-4089/a000373" target="_blank">Arbeits- und Organistionspsychologie A&O</a></cite></footer>
                      </blockquote>
                    </div>
                    <br>

                    <div> 
                      <h3 class="c-txt-orange"><b>Wie man Glück bei der Arbeit messen und fördern kann</b> <br>WIRTSCHAFTS-PSYCHOLOGIE aktuell</h3>           
                      <p>
                        Artikel erschienen im Februar/2022
                      </p>
                      <blockquote class="blockquote">
                        <p class="mb-1" ><em>"Jeder ist seines Glückes Schmied" besagt der Volksmund. Aber trifft das auch für die Arbeit zu? Welche Faktoren helfen beim Schmieden des eigenen Glückes bei der Arbeit und wie kann man sie messen und gestalten?"</em></p>
                        <footer class="blockquote-footer">Timo Kortsch & Ricarda Rehwaldt in <cite title="Wirtschatspsychologie aktuell"><a href="https://wirtschaftspsychologie-aktuell.de/magazin/leben/glueck-arbeit-messen" target="_blank">WIRTSCHAFTS-PSYCHOLOGIE aktuell</a></cite></footer>
                      </blockquote>
                    </div>
                    <br>

                    <div>    
                      <h3 class="c-txt-orange" ><b>Das Arbeitsglück messen</b> <br> Controlling & Management Review</h3>   
                      <p>
                        Artikel erschienen im April/2020
                      </p>      
                      <blockquote class="blockquote">
                        <p class="mb-1" ><em>"Viele Unternehmen versuchen, die Zufriedenheit ihrer Mitarbeiter zu steigern. Arbeitsglück dagegen gerät nur selten in ihren Fokus. 
                          Dabei sind glückliche Mitarbeiter weniger krank, kündigen seltener und zeigen mehr Leistung. Höchste Zeit also, 
                          die positiven Wechselwirkungen von Glück bei der Arbeit zu erfassen und zu fördern."</em></p>
                        <footer class="blockquote-footer">Ricarda Rehwaldt in <cite title="Artikel"><a href="https://link.springer.com/article/10.1007/s12176-020-0097-3" target="_blank">Controlling & Management Review</a></cite></footer>
                      </blockquote>
                    </div>
                    <br>

                    <div>    
                      <h3 class="c-txt-orange" ><b>Faktoren des organisationalen Glücks</b> <br> Psyche und Arbeit</h3>   
                      <p>
                        Artikel erschienen im 11/2017
                      </p>      
                      <blockquote class="blockquote">
                        <p class="mb-1" ><em>Mehr als nur zufriedene Beschäftigte: Welche Rolle spielt das Glück in der modernen Arbeitswelt?</em></p>
                        <footer class="blockquote-footer">Ricarda Rehwaldt in <cite title="Artikel"><a href="https://psyche-und-arbeit.de/?p=7208" target="_blank">Psyche und Arbeit</a></cite></footer>
                      </blockquote>
                    </div>
                    <br>

                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- happiness & work scale -->
    <!--
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <div class="row align-items-end">
            <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">
              <h2 id="scale" class="text-center text-md-center c-h2" >HappinessandWork-Scale</h2>
            </div>
            <div class="col-12 order-2 col-md-6 order-md-2">
              <img id="bubble_scale" src="@/assets/academy_bubble_scale.png" alt="HappinessAndWork_Skala">
            </div>
            <div class="col-12 order-3 col-md-6 order-md-3 py-3 align-self-start"> 
              <p class="text-center text-md-left">
                Die HappinessandWork-Scale (Rehwaldt & Kortsch) misst Glück am Arbeitsplatz und erfasst über drei Subskalen 
                die Facetten Sinnempfinden, Selbstverwirklichung und Gemeinschaft. 
                Das organisationsdiagnostische Instrument und wir unterstützen Unternehmen dabei den Erfolg von Maßnahmen 
                zu evaluieren und die eigene Position zu bestimmen. 
                Ab 2022 wird die HappinessandWork-Scale auch bei <b>Springer-Test</b> erhältlich sein. 
              </p>           
              <p class="text-left">
                <a href="/downloads/HappinessAndWork_Scale.pdf" download="HappinessAndWork_Scale.pdf">Download Skalenbeschreibung</a>
                <br>
                <a href="http://www.springertests.de" target="_blank">HappinessandWork-Scale auf SpringerTest</a>
              </p>
            </div>
          </div> 
        </div>
      </div>
    </div>
    -->

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Info_Books',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` }//,
        //{ vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Bücher und Artikel",
      metaDescription: "Hier findest Du eine Auswahl von Büchern und Artikeln zum Thema Glück und Arbeit.",
      metaUrl: "/info/books",
      metaKeywords: "Bücher Artikel 'Ricarda Rehwaldt'",
      metaImage: undefined,
    }
  },
  // when component is mounted to the dom
  mounted: function () {
/*       const anchor = this.$router.currentRoute.hash;
      setTimeout(() => { 
        if (anchor && document.querySelector(anchor)) {
        //var elem = document.querySelector(this.$route.hash);
        var elem = document.querySelector(anchor);
        console.log("scoll to: " + anchor); // eslint-disable-line no-console
        elem.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }
      }, 1000) */
    },
    methods: {
    }
}

</script>

<style>

</style>
