import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import VueSocialSharing from 'vue-social-sharing'
import Vue2TouchEvents from 'vue2-touch-events'
import LoadScript from 'vue-plugin-load-script'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// cookie consent functionality includng google analytics 
import CookieConsent from "vue-cookieconsent"
//import 'vue-cookieconsent/vendor/cookieconsent.css'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import CookieConsentConfig from "@/consent/cookieconsent-config.js"

Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VueSocialSharing)
Vue.use(Vue2TouchEvents)
Vue.use(LoadScript)
Vue.use(VuePlyr)
Vue.config.productionTip = false

//import App from './App.vue'
import Welcome from '@/components/Welcome.vue'
import Selftest from '@/components/Selftest.vue'
import DataProtection from '@/components/DataProtection.vue'
import Impressum from '@/components/Impressum.vue'
import Effects from '@/components/Effects.vue'
import Academy from '@/components/Academy.vue'
import Academy_HWFY from '@/components/Academy_HWFY.vue'
import Academy_KN from '@/components/Academy_KN.vue'
import Academy_HHO from '@/components/Academy_HHO.vue'
import Academy_CO from '@/components/Academy_CO.vue'
import Academy_PL from '@/components/Academy_PL.vue'
import Academy_HH from '@/components/Academy_HH.vue'
import Academy_HWS from '@/components/Academy_HWS.vue'
import Contact from '@/components/Contact.vue'
import Info_Downloads from '@/components/Info_Downloads'
import Info_HAW_Report from '@/components/Info_HAW_Report.vue'
import Info_Books from '@/components/Info_Books.vue'
import Info_Podcasts from '@/components/Info_Podcasts.vue'
import Info_Press from '@/components/Info_Press.vue'
import Survey from '@/components/Survey.vue'

// 1. Define route components.
// These can be imported from other files
//const welcome = { template: Welcome}
//const dataProtection = { template: DataProtection }
//const impressum = { template: Impressum }

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
  { path: '/welcome', component: Welcome },
  { path: '/haw/selftest', component: Selftest },
  { path: '/haw/effects', component: Effects },
  { path: '/dp', component: DataProtection },
  { path: '/imprint', component: Impressum },
  { path: '/academy', component: Academy },
  { path: '/academy/happiness_and_work_for_you', component: Academy_HWFY },
  { path: '/academy/happiness_and_homeoffice', component: Academy_HHO },
  { path: '/academy/keynotes', component: Academy_KN },
  { path: '/academy/coaching', component: Academy_CO },
  { path: '/academy/happiness_and_work_scale', component: Academy_HWS },
  { path: '/academy/positive_leadership', component: Academy_PL },
  { path: '/academy/happiness_hacks', component: Academy_HH },
  { path: '/info/books', component: Info_Books },
  { path: '/info/podcasts', component: Info_Podcasts },
  { path: '/info/press', component: Info_Press },
  { path: '/info/downloads', component: Info_Downloads },
  { path: '/info/happiness_and_work_report', component: Info_HAW_Report },
 // { path: '/contact', component: Contact, props: true },
  { path: '/contact', component: Contact},
  { path: '/survey/:rid?', component: Survey },
  { path: '*', redirect: '/welcome' }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: 'history',    // due to SEO 
  //base: '/vue-router',
  //base: __dirname,
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    //console.log("sb from: " + from.fullPath + " to: " + to.fullPath + (savedPosition ? (" savedPosition: " + JSON.stringify(savedPosition)): "")); // eslint-disable-line no-console
    // start timer to scoll to hash position if any in path (e.g. /contact#team) or to top if no hash in path
    setTimeout(() => { 
      if (to.hash) {
        var elem = document.querySelector(to.hash);
        //console.log("afterEach - scoll to: " + to.hash); // eslint-disable-line no-console
        elem.scrollIntoView({ behavior: 'smooth', block: 'start'});
      } else {
        //console.log("afterEach - scoll to: " + to.hash); // eslint-disable-line no-console
        window.scrollTo(0,0);
      }
    }, 1000)
    if (savedPosition) {
      // savedPosition is only available for popstate navigations.
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})

/* // called after each page change
router.afterEach((to, from) => {
  console.log("ae from: " + from.fullPath + " to: " + to.fullPath); // eslint-disable-line no-console
  }
) */

// 
// diective to handle click outside events
// can be added to any element to register an action which is triggert if clicked outside the element
// usage: <div v-click-outside="<function>">
Vue.directive('click-outside', {
  priority: 700,
  bind(el, binding, vnode) {
    var vm = vnode.context;
    var callback = binding.value;

    el.clickOutsideEvent = function (event) {
        if (!(el == event.target || el.contains(event.target))) {
            return callback.call(vm, event);
        }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
  }
})

// dynamically update meta tags on each page for SEO
Vue.use(VueMeta, {
  // optional pluginOptions
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: false
})

// initialize and run cookieconsent plugin
// See: https://github.com/orestbida/cookieconsent#all-configuration-options
const consentOptions = CookieConsentConfig
Vue.use(CookieConsent, consentOptions)

// 4. Create and mount the root instance.
// Make sure to inject the router with the router option to make the
// whole app router-aware.
const app = new Vue({  // eslint-disable-line no-unused-vars
  router,
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "- Glück im Kontext von Arbeit.",
    // all metaInfo.title will be injected into this template
    titleTemplate:  chunk => `Happiness & Work ${chunk}`,
    meta: [
      { vmid: "keywords",  name: 'keywords', template:  chunk => `'Ricarda Rehwakdt' Organisation Arbeit Glück Happiness Arbeitszufriedenheit Mitarbeiterzufriedenheit Unternehmenskultur ${chunk}`, content: "Seminar Beratung Coaching Teamentwicklung" },
      { vmid: "description", name: 'description', template: chunk => `Glück im Kontext von Arbeit. ${chunk}`, content: "Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind?"},
      { vmid: "author", name: 'author', content: "Prof. Dr. Ricarda Rehwaldt - FELICICON GmbH"},
      { vmid: "geo.region", name: 'geo.region', content: "DE-BE"},
      { vmid: "geo.placename", name: 'geo.placename', content: "Berlin"},
      // social tagging
      { vmid: "og:title", property: "og:title", template: chunk => `Happiness & Work ${chunk}`, content: "- Glück im Kontext von Arbeit." },
      { vmid: "og:type", property: "og:type", content: "website" },
      { vmid: "og:url", property: "og:url", template: chunk => `https://www.happinessandwork.de${chunk}`, content: "" },
      { vmid: "og:description", property: "og:description", content: "'Happiness & Work' ist ein Projekt der FELICICON GmbH und unterstützt Dich und andere Menschen dabei, einfach etwas glücklicher bei der Arbeit zu sein. Wir zeigen Dir worauf es ankommt und Du erzählst es weiter!" },
      { vmid: "og:author", name: 'og:author', content: "Prof. Dr. Ricarda Rehwaldt - FELICICON GmbH"},
      { vmid: "og:image", name: 'og:image', content: "https://www.happinessandwork.de/share/start.png"}
    ]
  },
  script: [],
  updated: function () {},
  mounted: function () {},
  methods: {
  }
}).$mount('#app')

