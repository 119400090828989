<template>

  <div id="navi">

    <!-- back -->
    <div id="button_back" class="c-btn c-btn-white" @click="goBack()">
      <!--span style="padding: 10px;">Back</span-->
      <div class="c-btn-img c-btn-img-left-white"/>
    </div>

    <!-- top -->
    <div id="button_top" class="c-btn c-btn-white" @click="goTop()">
      <!--span style="padding: 10px;">Top</span-->
      <div class="c-btn-img c-btn-img-up-white"/>
    </div>

    <!-- share --> 
    <!--
    <share v-if="showShare" class="p-2 c-share-white" />
    -->

    <!-- top navigation -->
    <b-navbar toggleable="md" type="light" variant="transparent" v-bind:class="{ 'navbar-dark' : !isDarkMode, 'navbar-light' : isDarkMode }">  
      <b-navbar-brand href="#">
        <router-link to="/welcome">
          <div  v-bind:class="{ 'c-logo-white' : !isDarkMode, 'c-logo-orange' : isDarkMode }" style="transform: translate(30px, 10px);" />
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <!--
          <b-nav-item to="/survey" active-class="active">Selbsttest</b-nav-item>
          <b-nav-item href="#" @click="gotoLocation('https://my.happinessandwork.de', '_self')">Teste dein Glück</b-nav-item>

          <b-nav-item to="/welcome" active-class="active">Glück & Arbeit</b-nav-item>
          -->

          <b-nav-item-dropdown text="Glück & Arbeit" :toggle-class="($route.path.startsWith('/haw') || $route.path === '/welcome') ? 'active' : ''" right >            
            <b-dropdown-item to="/welcome" >Home</b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item to="/haw/selftest" active-class="active" >- Teste dein Glück</b-dropdown-item>
            <b-dropdown-item to="/haw/effects" active-class="active" >- Effekte von Glück</b-dropdown-item>
          </b-nav-item-dropdown>

          <!--<b-nav-item to="/effects" active-class="active">Effekte</b-nav-item>-->
          <!--<b-nav-item to="/academy" active-class="active">Akademie </b-nav-item>-->
          
          <!--<b-nav-item-dropdown href="#" text="Test" right>-->
          <b-nav-item-dropdown text="Academy" :toggle-class="$route.path.startsWith('/academy') ? 'active' : ''" right >
            <b-dropdown-item to="/academy" >Übersicht</b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item to="/academy/happiness_and_work_for_you" active-class="active" >- Happiness & Work für dich</b-dropdown-item>
            <b-dropdown-item to="/academy/happiness_and_homeoffice" active-class="active" >- Happiness & Homeoffice</b-dropdown-item>
            <b-dropdown-item to="/academy/happiness_hacks" active-class="active" >- Happiness Hacks</b-dropdown-item>
            <b-dropdown-item to="/academy/positive_leadership" active-class="active" >- Positive Leadership</b-dropdown-item>
            <b-dropdown-item to="/academy/keynotes" active-class="active" >- Keynotes</b-dropdown-item>
            <b-dropdown-item to="/academy/coaching" active-class="active" >- Coaching</b-dropdown-item>
            <b-dropdown-item to="/academy/happiness_and_work_scale" active-class="active" >- Glück bei der Arbeit messen</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item to="/contact" active-class="active">Kontakt</b-nav-item>
          <!--<b-nav-item to="/info" active-class="active">Lesen & Hören</b-nav-item>-->
          <b-nav-item-dropdown text="Lesen & Hören" :toggle-class="$route.path.startsWith('/info') ? 'active' : ''" right >
            <b-dropdown-item to="/info/books" active-class="active" >- Bücher & Artikel</b-dropdown-item>
            <b-dropdown-item to="/info/press" active-class="active" >- Presse</b-dropdown-item>
            <b-dropdown-item to="/info/podcasts" active-class="active" >- Podcasts</b-dropdown-item>
            <b-dropdown-item to="/info/downloads" active-class="active" >- Downloads</b-dropdown-item>
            <b-dropdown-item to="/info/happiness_and_work_report" active-class="active" >- Happiness & Work Report</b-dropdown-item>
          </b-nav-item-dropdown>
          <!--
          <b-nav-item href="#" disabled>Downloads</b-nav-item>
          -->
        </b-navbar-nav>

        <!-- Right aligned nav items
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#">
            <b-button pill size="md" >JETZT STARTEN</b-button>
          </b-nav-item>
        </b-navbar-nav>
        -->
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
//import Share from '@/components/Share.vue'

export default {
  name: 'Navi',
  props: {
    darkMode: Boolean,      // if true use dark colors, otherwise light
    showShare: Boolean      // if true show share popout
  },
  components: {
    //Share
  },
  data() {
        return {
          isDarkMode: this.$props.darkMode,
          isShowShare: this.$props.showShare
        }
    },
    computed: {
      //isHwfy: function() {return this.$route.path === '/academy/happiness_and_work_for_you'}
    },
    // called when component is mounted to the dom
    mounted: function () {
    },
    methods: {

      // history back
      goBack() {
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        //window.history.length > 1 ? window.history.back() : this.$router.push('/')
      },

      // top
      goTop() {
        window.scrollTo({  top: 0, left: 0, behavior: 'smooth' });
      },

      // got to location
      gotoLocation(url, target) {
        window.open(url, target);    
      }
    }
}

</script>

<style>
/* navi dropdown */

.b-nav-dropdown .dropdown-item {
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
}
.b-nav-dropdown a.dropdown-item.active, .b-nav-dropdown a.dropdown-item:active {
  color: rgba(0, 0, 0, 0.9);
  background-color: transparent;
}
</style>
