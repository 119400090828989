<template>

  <div id="dataProtection">

    <div class="c-img-bg c-dp-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- data protection -->
            <div class="row justify-content-center py-3">
              <div class="col-11 coll-md-10 col-lg-10">                    
                <h1 class="text-left c-h1">
                  Datenschutz&shy;erklärung
                </h1>              
              </div>
            </div>          
            <!-- generals -->
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10 col-lg-10">                    
                <h2 class="text-left py-3 c-h2">
                  Grund&shy;legendes
                </h2>
                <p class="text-left">
                  Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber (Ricarda Rehwaldt | Felicicon) informieren.
                  Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.
                  Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- data access & cookies -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10 col-lg-10">  
          <!-- data access -->                  
          <h2 class="text-left py-3 c-h2">
            Zugriffsdaten
          </h2>
          <p class="text-left">
            Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) 
            Daten über Zugriffe auf die Website und speichern diese als „Server-Logfiles“ auf dem Server der Website ab. 
            Folgende Daten werden so protokolliert:
          </p>
          <ul class="text-left">
            <li>Besuchte Website</li>
            <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
            <li>Menge der gesendeten Daten in Byte</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Verwendete IP-Adresse</li>
          </ul>
          <p class="text-left">
            Die Server-Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht. 
            Die Speicherung der Daten erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können. 
            Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.
          </p>
          <!-- cookies -->
          <h2 class="text-left py-3 c-h2">
            Reichweitenmessung & Cookies
          </h2>
          <p class="text-left">
            Diese Website verwendet Cookies zur pseudonymisierten Reichweitenmessung, die entweder von unserem Server oder dem Server Dritter 
            an den Browser des Nutzers übertragen werden. Bei Cookies handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert werden. 
            Ihr Browser greift auf diese Dateien zu. Durch den Einsatz von Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser Website. 
            Falls Sie nicht möchten, dass Cookies zur Reichweitenmessung auf Ihrem Endgerät gespeichert werden, können Sie dem Einsatz dieser Dateien hier widersprechen:
          </p>
          <ul class="text-left">
            <li><a href="https://optout.networkadvertising.org/?c=1#!%2F" target="_blank">Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative</a></li>
            <li><a href="https://optout.aboutads.info/?lang=EN&c=2#!%2F" target="_blank">Cookie-Deaktivierungsseite der US-amerikanischen Website</a></li>
            <li><a href="https://optout.networkadvertising.org/?c=1#!%2F" target="_blank">Cookie-Deaktivierungsseite der europäischen Website</a></li>
          </ul>
          <p class="text-left">
            Gängige Browser bieten die Einstellungsoption, Cookies nicht zuzulassen. Hinweis: Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website 
            ohne Einschränkungen zugreifen können, wenn Sie entsprechende Einstellungen vornehmen.
          </p>
        </div>
      </div>
    </div>

    <!-- Google analytics -->
    <div class="c-grey-bg">
      <!-- wave top -->
      <div class="c-wave c-wave-top"/>
      
      <!-- middle -->
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-center py-3">
              <div class="col-11 coll-md-10 col-lg-10"> 
                <!-- Google analytics -->                   
                <h2 class="text-left py-3 c-h2">
                  Google Analytics
                </h2>
                <p class="text-left">
                  Diese Website nutzt aufgrund unserer berechtigten Interessen zur Optimierung und Analyse unseres Online-Angebots 
                  im Sinne des Art. 6 Abs. 1 lit. f. DSGVO den Dienst „Google Analytics“, welcher von der Google Inc. 
                  (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten wird. Der Dienst (Google Analytics) verwendet „Cookies“ – Textdateien, 
                  welche auf Ihrem Endgerät gespeichert werden. Die durch die Cookies gesammelten Informationen werden im Regelfall an einen Google-Server in den USA gesandt und dort gespeichert.
                  Google LLC hält das europäische Datenschutzrecht ein und ist unter dem 
                  <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" target="_blank"> Privacy-Shield-Abkommen zertifiziert</a>.
                </p>
                <p class="text-left">
                  Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten 
                  der EU und des Europäischen Wirtschaftsraum und in den anderen Vertragsstaaten des Abkommens gekürzt. 
                  Nur in Einzelfällen wird die IP-Adresse zunächst ungekürzt in die USA an einen Server von Google übertragen und dort gekürzt. 
                  Durch diese Kürzung entfällt der Personenbezug Ihrer IP-Adresse. 
                  Die vom Browser übermittelte IP-Adresse des Nutzers wird nicht mit anderen von Google gespeicherten Daten kombiniert.
                </p>
                <p class="text-left">
                  Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche wir als Websitebetreiber mit der Google Inc. geschlossen haben, 
                  erstellt diese mithilfe der gesammelten Informationen eine Auswertung der Websitenutzung und der Websiteaktivität 
                  und erbringt mit der Internetnutzung verbundene Dienstleistungen.
                </p>
                <p class="text-left">
                  Die von Google in unserem Auftrag erhobenen Daten werden genutzt, um die Nutzung unseres Online-Angebots durch die einzelnen Nutzer auswerten zu können, 
                  z. B. um Reports über die Aktivität auf der Website zu erstellen, um unser Online-Angebot zu verbessern.
                </p>
                <p class="text-left">
                  Sie haben die Möglichkeit, die Speicherung der Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser entsprechende Einstellungen vornehmen. 
                  Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Ihr Browser keine Cookies zulässt.
                </p>
                <p class="text-left">
                  Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten Informationen (inklusive Ihrer IP-Adresse) 
                  an die Google Inc. gesendet und von der Google Inc. genutzt werden. <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">Dieser Link führt Sie zu dem entsprechenden Plugin</a>.
                </p>
                <p id="optout" class="text-left">
                  Alternativ können sie hier ihre zuvor getroffenen Entscheidung bezüglich der <a href="#optout" @click="changeConsent">Verwendung von Cookies</a> wieder ändern.
                </p>
                <p class="text-left">
                  Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.:
                </p>
                <ul class="text-left">
                  <li><a href="https://policies.google.com/technologies/partner-sites?hl=de" target="_blank">Daten, die von Google-Partnern erhoben werden</a></li>
                  <li><a href="https://adssettings.google.de/authenticated" target="_blank">Einstellungen über Werbung, die Ihnen angezeigt wird</a></li>
                  <li><a href="https://policies.google.com/technologies/ads?hl=de" target="_blank">Verwendung von Cookies in Anzeigen"</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom" />
    </div>

    <!-- user rights, data deletion & dissent -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10 col-lg-10">
          <!-- user rights-->
          <h2 class="text-left py-3 c-h2">
            Rechte des Nutzers
          </h2>
          <p class="text-left">
            Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, 
            welche personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung 
            falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend, 
            können Sie auch Ihr Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, 
            können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.
          </p> 
          <!-- data deletion -->                  
          <h2 class="text-left py-3 c-h2">
            Löschung von Daten
          </h2>
          <p class="text-left">
            Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert, 
            haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht 
            mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht durchgeführt werden kann, 
            da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung. 
            In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.        
          </p>
          <!-- dissent -->
          <h2 class="text-left py-3 c-h2">
            Widerspruch
          </h2>
          <p class="text-left">
            Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung 
            ihrer personenbezogenen Daten zu jeder Zeit widersprechen.
            Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person 
            gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, 
            Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, 
            wenden Sie sich bitte an folgende E-Mail-Adresse: <a href="mailto:ricardarehwaldt@felicicon.com">ricarda.rehwaldt@felicicon.com</a>.    
          </p>        
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Dataprotection',
  components: {
    Navi,
    Footer
  },
  data() {
        return {
        }
    },
    // called when component is mounted to the dom
    mounted: function () {
    },
    methods: {
      // got to location
      gotoLocation(url, target) {
        window.open(url, target);    
      },
      // change consent decision made earlier
      changeConsent () {
        this.$cc.show(1, true)
        //this.$gtag.optOut()
      }
    }
}
</script>

<style>

</style>
