<template>

  <div id="effects">
    <div class="c-img-bg c-effects-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- efects -->
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">                    
                <div class="row">
                  <div class="col-12 py-5 col-md-3 py-md-0">
                    <div class="row">
                      <div class="col-4 col-md-12 d-flex justify-content-center">
                        <img id="bubble_sense" src="@/assets/3f_sense.svg" alt="HappinessAndWork_Sinn" style="width: 100%; min-width: 120px; ">
                      </div> 
                      <div class="col-4 col-md-12 d-flex justify-content-center">
                        <img id="bubble_self" src="@/assets/3f_selfactualization.svg"  alt="HappinessAndWork_Selbstverwirklichung" style="width: 100%; min-width: 125px; ">
                      </div> 
                      <div class="col-4 col-md-12 d-flex justify-content-center">
                        <img id="bubble_comm" src="@/assets/3f_comunity.svg" alt="HappinessAndWork_Gemeinscahft" style="width: 100%; min-width: 130px; ">
                      </div> 
                    </div>
                  </div>
                  <div class="col-12 col-md-6 d-flex align-items-center">
                    <div>
                      <p class="c-txt-orange" style="font-weight: bold;">FORSCHUNGEN ZEIGEN</p>
                      <h1 class="c-h1" >Effekte & Nutzen von Glück in Organisationen</h1>  
                    </div>       
                  </div>
                  <div class="col-4 offset-4 py-5 col-md-3 offset-md-0 py-md-0 d-flex align-items-center justify-content-center">
                    <img id="bubble_happi" src="@/assets/3f_happiness.svg" alt="HappinessAndWork_Glück" style="width: 100%; min-width: 120px;">
                  </div>     
                </div>    
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- mood -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <div class="row align-items-end">
            <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">             
              <h2 class="text-center text-md-left c-h2" >Positive Stimmung</h2>
            </div>
            <div class="col-12 order-2 col-md-7 order-md-3">
              <img id="bubble_mood" src="@/assets/effects_bubble_mood.png" alt="HappinessAndWork_Stimmung">
            </div>
            <div class="col-12 order-3 col-md-5 order-md-2 py-3 align-self-start">             
              <p class="text-center text-md-left">
                Glück hat einen positiven Einfluss auf die Stimmung bei euch und bei den Menschen um euch herum. 
                Dies merkt ihr nicht nur im Teamklima, sondern auch daran, dass die Anzahl der Konflikte sinkt 
                und die Hilfsbereitschaft bei euch und euren Kollegen steigt. 
                Denn: Glück führt zu einer positiven Ausstrahlung, erweitert den Blick und steckt Menschen in der Umgebung an. 
              </p>
            </div>
          </div> 
        </div>
      </div>
    </div>

    <!-- creativity -->
    <div class="c-grey-bg">
      <!-- wave top -->
      <div class="c-wave c-wave-top"/>
      
      <!-- middle -->
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">
                <div class="row align-items-end">
                  <div class="col-12 order-1 col-md-6 offset-md-6 order-md-1 py-3 align-self-start">             
                    <h2 class="text-center text-md-left c-h2" >Kreativität</h2>
                  </div>
                  <div class="col-12 order-2 col-md-6 order-md-2">
                    <img id="bubble_creativity" src="@/assets/effects_bubble_creativity.png" alt="HappinessAndWork_Kreativität"> 
                  </div>
                  <div class="col-12 order-3 col-md-6 order-md-3 py-3 align-self-start">             
                    <p class="text-center text-md-left">
                      Glückliche Menschen sind offener für neue Lösungen und haben einfach vielmehr Ideen.
                      Gerade dort wo viele Ideen gebraucht werden, ist Glück also eine wichtige Bedingung.
                      Glück ist einfach die Grundvoraussetzung für Kreativität, da nur eine positive Grundstimmung 
                      die nötige Offenheit ermöglicht, die Kreativität braucht. 
                      Denn Ideen und die Begeisterung für diese münden in Kreativität und ermöglichen Flow 
                      und Leichtigkeit im Arbeitserleben. 
                    </p>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- motivation -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <img id="bubble_moti_2" src="@/assets/bubble_red_2.png" alt="bubble">   
          <div class="row align-items-end">
              <div class="col-12 order-2 col-md-6 order-md-3">
              <img id="bubble_moti" src="@/assets/effects_bubble_motivation.png" alt="HappinessAndWork_Motivation">
            </div>
            <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">             
              <h2 class="text-center text-md-left c-h2">Motivation</h2>
            </div>
            <div class="col-12 order-3 col-md-6 order-md-2 py-3 align-self-start">             
              <p class="text-center text-md-left">
                Glückliche Menschen erleben sich und Ihre Aufgaben als sinnvoll und erfüllend. 
                Deshalb sind sie auch engagiert und motiviert bei der Sache. 
                Nur wer wirklich hinter einer Sache steht und sich für sein Team einsetzen will, 
                ist auch bereit seine Stärken und Ressourcen zu investieren, um die Aufgabe zum bestmöglichen Ergebnis zu bringen. 
                Und gute Ergebnisse machen stolz und fördern die persönliche Weiterentwicklung. 
              </p>
            </div>
          </div> 
        </div>
      </div>
    </div>

    <!-- power -->     
    <div class="c-grey-bg">
      <!-- wave top -->
      <div class="c-wave c-wave-top"/>
      
      <!-- middle -->
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">
                <img id="bubble_power_2" src="@/assets/bubble_red_3.png" alt="bubble">
                <div class="row align-items-end">
                  <div class="col-12 order-1 col-md-6 offset-md-6 order-md-1 py-3 align-self-start">             
                    <h2 class="text-center text-md-left c-h2" style="">Leistung</h2>
                  </div>
                  <div class="col-12 order-2 col-md-6 order-md-2">
                    <img id="bubble_power" src="@/assets/effects_bubble_power.png" alt="HappinessAndWork_Leistung">
                  </div>
                  <div class="col-12 order-3 col-md-6 order-md-3 py-3 align-self-start">             
                    <p class="text-center text-md-left">
                    Glück führt insgesamt zu einer höheren Leistungsfähigkeit. Die Anzahl der Krankheitstage reduziert sich, 
                    Krankheitssymptome werden schwächer, Hürden werden niedriger und der Blick öffnet sich - für Möglichkeiten 
                    und für die Perspektiven anderer Menschen. Dies führt zu einer Ausstrahlung, die andere Menschen anzieht. 
                    Deshalb werden glückliche Menschen eher angesprochen, es wird ihnen schneller geholfen und sie sind 
                    auch selbst leistungsfähiger als andere.  
                    </p>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- read more -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10 py-5 ">
          <div class="row d-flex align-items-center">
            <div class="col-12 order-2 col-md-6 order-md-2">
              <span class="text-center text-md-right">
                Du magst noch mehr dazu nachlesen?  Dann klick hier:
              </span>    
            </div>
            <div class="col-12 order-3 col-md-6 order-md-3 align-self-start"> 
              <div class="d-flex justify-content-md-start justify-content-center">  
                <!-- button -->
                <router-link to="/info/books#dgo" style="text-decoration: none">
                  <div class="c-btn c-btn-orange">
                    <span style="padding: 10px">Mehr zum Lesen</span>
                    <div class="c-btn-img c-btn-img-right-white"/>
                  </div>
                </router-link>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Effects',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` },
        { vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Glück am Arbeitsplatz lohnt sich.",
      metaDescription: "Glück am Arbeitsplatz fördert Stimmung, Kreativität, Motivtion und Leistung.",
      metaUrl: "/haw/effects",
      metaKeywords: "'Positive Stimmung' Kreativität Motivation Leistung",
      metaImage: "https://www.happinessandwork.de/share/effects.png",
    }
  },
  // called when component is mounted to the dom
  mounted: function () {
    },
  methods: {
      // got to location
      gotoLocation(url, target) {
        window.open(url, target);    
      }
    }
}

</script>

<style>

</style>
