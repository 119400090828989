<template>

  <div id="downloads">
    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- downloads -->  
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">                    
                <div class="row">
                  <div class="col-12 d-flex align-items-center flex-column">
                    <h1 class="c-h1">Downloads</h1>  
                    <p class="c-txt-orange" style="font-weight: bold;">Dokumente & Bilder zum herunterladen</p>      
                  </div>
                  <div class="col-12 py-3 col-md-8 d-flex align-items-center justify-content-center">             
                    <p class="text-center text-md-left">
                        Hier haben wir euch einige Dinge zusammengestellt, die ihr für Artikel, Blogs und Post gebrauchen könntet. 
                        Wir freuen uns über Kommentare in den Sozialen Medien und eure Fragen. Ihr braucht mehr? Bekommt ihr! 
                        Bei: <a  href="mailto:info@happinessandwork.de">info@happinessandwork.de</a>.
                    </p>
                  </div>
                  <div class="col-12 py-3 col-md-4 d-flex justify-content-end">             
                    <img id="bubble_academy_1" src="@/assets/bubble_red_1.png" alt="bubble">  
                  </div>
                </div>    
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- links 1 -->
    <div class="container-xl">
      <div class="row">
        <div class="col-12">
          <div class="row justify-content-center">
            <div class="col-11 coll-md-10">
              <div class="row align-items-end">
                <div class="col-12 col-md-12 py-3 align-self-start"> 
                  <p class="text-left">
                    Einsatz der HappinessandWork-Scale in Training, Workshops & Teamentwicklung
                  </p>       
                  <ul class="text-left">
                    <li><a href="/downloads/HappinessAndWork_WorkshopTemplate.pdf" download="HappinessAndWork_WorkshopTemplate.pdf">Vorlage [PDF]</a></li>
                  </ul>              
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- links 2 -->
    <div class="c-grey-bg">
      <!-- wave top -->
      <div class="c-wave c-wave-top"/>

      <!-- middle -->
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">
                <div class="row align-items-end">
                  <!--
                  <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">              
                    <h2 class="text-center text-md-center c-h2">Downloads</h2>
                  </div>
                  -->
                  <div class="col-12 order-1 col-md-7 order-md-3">
                    <img id="bubble_course_4" src="@/assets/academy_bubble_info.png" alt="HappinessAndWork_Information">
                  </div>
                  <div class="col-12 order-2 col-md-5 order-md-2 py-3 align-self-start"> 
                    <p class="text-left">
                      Logos
                    </p>       
                    <ul class="text-left">
                      <li><a href="/downloads/happiness_logo_white.png" download="HappinessAndWork_Logo-white.png">Happiness & Work Logo (white) [PNG]</a></li>
                      <li><a href="/downloads/happiness_logo_white.svg" download="HappinessAndWork_Logo-white.svg">Happiness & Work Logo (white) [SVG]</a></li>
                      <li><a href="/downloads/happiness_logo_orange.png" download="HappinessAndWork_Logo-orange.png">Happiness & Work Logo (orange) [PNG]</a></li>
                      <li><a href="/downloads/happiness_logo_orange.svg" download="HappinessAndWork_Logo-orange.svg">Happiness & Work Logo (orange) [SVG]</a></li>
                    </ul>
                    <p class="text-left">
                      Diagramme
                    </p>       
                    <ul class="text-left">
                      <li><a href="/downloads/happiness_overall.png" download="HappinessAndWork_Glueck_im_Unterehmen.png">Glück in Unternehmen</a></li>
                      <li><a href="/downloads/happiness_gender.png" download="HappinessAndWork_Glueck_und_Geschlecht.png">Glück & Geschlecht</a></li>
                      <li><a href="/downloads/happiness_education.png" download="HappinessAndWork_Glueck_und_Bildung.png">Glück & Bildung</a></li>
                      <li><a href="/downloads/happiness_industrial_sector.png" download="HappinessAndWork_Glueck_und_Branche.png">Glück & Branche</a></li>
                      <li><a href="/downloads/happiness_company_size.png" download="HappinessAndWork_Glueck_und_Unternehmensgroesse.png">Glück & Unternehmensgröße</a></li>
                      <li><a href="/downloads/happiness_salary.png" download="HappinessAndWork_Glueck_und_Gehalt.png">Glück & Gehalt</a></li>
                    </ul>                
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="row c-wave c-wave-bottom"/>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Downloads',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` }//,
        //{ vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Downloads",
      metaDescription: "Hier findest Du Material zum Herunterladen.",
      metaUrl: "/info/downloads",
      metaKeywords: "Downloads",
      metaImage: undefined,
    }
  },
    // called when component is mounted to the dom
    mounted: function () {
/*       const anchor = this.$router.currentRoute.hash;
      setTimeout(() => { 
        if (anchor && document.querySelector(anchor)) {
        //var elem = document.querySelector(this.$route.hash);
        var elem = document.querySelector(anchor);
        console.log("scoll to: " + anchor); // eslint-disable-line no-console
        elem.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }
      }, 1000) */
    },
    methods: {
    }
}

</script>

<style>

</style>
