<template>

  <div id="survey">

    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <!-- navigation-->             
            <Navi dark-mode></Navi>

            <!-- survey -->
            <div class="row justify-content-center" v-touch:swipe.prevent="swipeHandler">
              <div class="col-11 coll-md-10">   
                <div class="row">
                  <div class="col-12 py-3 d-flex align-items-center flex-column">
                    <h1 class="c-h1" >So glücklich bist du bei der Arbeit</h1>    
                  </div>
                </div>                

                <transition name="fade" mode="out-in">
                  <!-- my happiness -->
                  <div class="row" v-if="displayMy" key="my">
                    <div class="col-12 py-3 col-md-4 d-flex flex-column align-items-center justify-content-start justify-content-md-center">
                      <div class="text-left text-md-center c-txt-orange" style="font-weight: normal; font-size: 1.3rem;">
                        <p>Du bist zu {{this.data.data.data[0].avgHa}}% glücklich bei der Arbeit.</p>     
                        <p>{{compareText}}</p> 
                      </div>
                    </div>                   
                    <div class="col-12 py-3 col-md-8 d-flex align-items-center justify-content-center">      
                      <svg id="gauges-my" class="c-survey-diagram" width="100%" viewBox="0 0 200 200">                      
                        <text text-anchor="middle" font-size="0.5rem" transform="translate(100,10)" style="fill: #000000;">DEIN ERGEBNIS</text>                       
                        <Gauge id="myHa" class="ha" v-bind:cx="100" v-bind:cy="80" v-bind:r="60" v-bind:value="this.data.data.data[0].avgHa" v-bind:label="['Glück','']" v-bind:icon="require('@/assets/icon_happiness_white.svg')" v-bind:setup='{"circleColor": "#00986d","textColor":"#00986d","waveTextColor":"#00986d","waveColor":"#99deca","circleOuterGap":0.025}'/>
                        <Gauge id="mySi" class="si" v-bind:cx="40" v-bind:cy="142" v-bind:r="30" v-bind:value="this.data.data.data[0].avgSi" v-bind:label="['Sinn','']" v-bind:icon="require('@/assets/icon_sense_white.svg')" v-bind:setup='{"circleColor": "#81cbdc","textColor":"#81cbdc","waveTextColor":"#81cbdc","waveColor":"#b7e2ec"}'/>
                        <Gauge id="mySv" class="sv" v-bind:cx="100" v-bind:cy="164" v-bind:r="30" v-bind:value="this.data.data.data[0].avgSv" v-bind:label="['Selbstverwirk-','lichung']" v-bind:icon="require('@/assets/icon_selfactualization_white.svg')" v-bind:setup='{"circleColor": "#ec6d3f","textColor":"#ec6d3f","waveTextColor":"#ec6d3f","waveColor":"#f3c5b4"}'/>
                        <Gauge id="myGe" class="ge" v-bind:cx="160" v-bind:cy="142" v-bind:r="30" v-bind:value="this.data.data.data[0].avgGe" v-bind:label="['Gemeinschaft','']" v-bind:icon="require('@/assets/icon_comunity_white.svg')" v-bind:setup='{"circleColor": "#f6a82e","textColor":"#f6a82e","waveTextColor":"#f6a82e","waveColor":"#fbe0b7"}'/>
                      </svg>
                    </div>
                  </div>

                  <!-- happiness compared -->
                  <div class="row" v-else key="all">
                    <div class="col-12 py-3 col-md-4 d-flex align-items-center justify-content-start justify-content-md-end text-left">
                      <div class="text-left text-md-center c-txt-orange" style="font-weight: normal; font-size: 1.3rem;">
                        <p>So glücklich bist du im Vergleich zu allen Befragten.</p>     
                      </div>
                    </div>   
                    <div class="col-12 py-3 col-md-8 d-flex align-items-center justify-content-center">   
                      <svg id="svg_compare" class="c-survey-diagram" width="100%" viewBox="0 0 480 510" >
                        <Barchart id="chart_compare" v-bind:width="400" v-bind:height="400" darkMode rotateXLabels v-bind:data="this.data.data"/>
                      </svg>   
                      <!--
                      <svg id="gauges_compare" class="c-survey-diagram" width="100%" viewBox="0 0 200 200">
                        <text text-anchor="end" font-size="0.7rem" font-weight="bold" transform="translate(55,30)" alignment-baseline="middle" style="fill: #00986d;">Glück</text>
                        <text text-anchor="end" font-size="0.6rem" font-weight="bold" transform="translate(75,80)" alignment-baseline="middle" style="fill: #81cbdc;">Sinnempfinden</text>
                        <text text-anchor="end" font-size="0.6rem" font-weight="bold" transform="translate(75,115)" alignment-baseline="middle" style="fill: #ec6d3f;">Selbstverwirklichung</text>
                        <text text-anchor="end" font-size="0.6rem" font-weight="bold" transform="translate(75,150)" alignment-baseline="middle" style="fill: #f6a82e;">Gemeinschaft</text>
                        <line x1="60" y1="30" x2="170" y2="30" stroke="#00986d" stroke-width="0.03rem" />
                        <line x1="80" y1="80" x2="170" y2="80" stroke="#81cbdc" stroke-width="0.03rem"/>
                        <line x1="80" y1="115" x2="170" y2="115" stroke="#ec6d3f" stroke-width="0.03rem"/>
                        <line x1="80" y1="150" x2="170" y2="150" stroke="#f6a82e" stroke-width="0.03rem"/>

                        <line x1="170" y1="30" x2="170" y2="175" stroke="black" stroke-width="0.03rem"/>
                        <Gauge id="compAllHa" class="ha" v-bind:cx="170" v-bind:cy="30" v-bind:r="25" v-bind:value="this.data.data[1].avgHa" v-bind:label="['','']" v-bind:icon="require('@/assets/icon_happiness_white.svg')" v-bind:setup='{"circleColor": "#00986d80","textColor":"#00986d","waveTextColor":"#00986d","waveColor":"#99deca80","circleOuterGap":0.025}'/>
                        <Gauge id="compAllSi" class="si" v-bind:cx="170" v-bind:cy="80" v-bind:r="15" v-bind:value="this.data.data[1].avgSi" v-bind:label="['','']" v-bind:icon="require('@/assets/icon_sense_white.svg')" v-bind:setup='{"circleColor": "#81cbdc80","textColor":"#81cbdc","waveTextColor":"#81cbdc","waveColor":"#b7e2ec80"}'/>
                        <Gauge id="compAllSv" class="sv" v-bind:cx="170" v-bind:cy="115" v-bind:r="15" v-bind:value="this.data.data[1].avgSv" v-bind:label="['','']" v-bind:icon="require('@/assets/icon_selfactualization_white.svg')" v-bind:setup='{"circleColor": "#ec6d3f80","textColor":"#ec6d3f","waveTextColor":"#ec6d3f","waveColor":"#f3c5b480"}'/>
                        <Gauge id="compAllGe" class="ge" v-bind:cx="170" v-bind:cy="150" v-bind:r="15" v-bind:value="this.data.data[1].avgGe" v-bind:label="['','']" v-bind:icon="require('@/assets/icon_comunity_white.svg')" v-bind:setup='{"circleColor": "#f6a82e80","textColor":"#f6a82e","waveTextColor":"#f6a82e","waveColor":"#fbe0b780"}'/>
                        <text text-anchor="middle" font-size="0.5rem" transform="translate(170,185)" style="fill: #00000080;">
                          <tspan  x="0">ALLE</tspan> 
                          <tspan x="0" y="0.6rem">BEFRAGTEN</tspan>
                        </text>

                        <line x1="110" y1="30" x2="110" y2="175" stroke="black" stroke-width="0.03rem"/>
                        <Gauge id="compMyHa" class="ha" v-bind:cx="110" v-bind:cy="30" v-bind:r="25" v-bind:value="this.data.data[0].avgHa" v-bind:label="['','']" v-bind:icon="require('@/assets/icon_happiness_white.svg')" v-bind:setup='{"circleColor": "#00986d","textColor":"#00986d","waveTextColor":"#00986d","waveColor":"#99deca","circleOuterGap":0.025}'/>
                        <Gauge id="compMySi" class="si" v-bind:cx="110" v-bind:cy="80" v-bind:r="15" v-bind:value="this.data.data[0].avgSi" v-bind:label="['','']" v-bind:icon="require('@/assets/icon_sense_white.svg')" v-bind:setup='{"circleColor": "#81cbdc","textColor":"#81cbdc","waveTextColor":"#81cbdc","waveColor":"#b7e2ec"}'/>
                        <Gauge id="compMySv" class="sv" v-bind:cx="110" v-bind:cy="115" v-bind:r="15" v-bind:value="this.data.data[0].avgSv" v-bind:label="['','']" v-bind:icon="require('@/assets/icon_selfactualization_white.svg')" v-bind:setup='{"circleColor": "#ec6d3f","textColor":"#ec6d3f","waveTextColor":"#ec6d3f","waveColor":"#f3c5b4"}'/>
                        <Gauge id="compMyGe" class="ge" v-bind:cx="110" v-bind:cy="150" v-bind:r="15" v-bind:value="this.data.data[0].avgGe" v-bind:label="['','']" v-bind:icon="require('@/assets/icon_comunity_white.svg')" v-bind:setup='{"circleColor": "#f6a82e","textColor":"#f6a82e","waveTextColor":"#f6a82e","waveColor":"#fbe0b7"}'/>                    
                        <text text-anchor="middle" font-size="0.5rem" transform="translate(110,185)" style="fill: #000000;">
                          <tspan  x="0">DEIN</tspan>
                          <tspan x="0" y="0.6rem">ERGEBNIS</tspan>
                        </text>
                      </svg>
                      -->
                    </div>
                  </div>
                </transition>

                <div class="row">
                    <div class="col-12 py-3 col-md-8 offset-md-4 d-flex align-items-center justify-content-center">                 
                      <div class="d-flex justify-content-center py-2"> 
                        <!-- button -->
                        <div class="c-btn c-btn-orange" @click="displayMy = !displayMy">
                          <span style="padding: 10px">{{displayMy ? 'Vergleichen' : 'Dein Ergebnis'}}</span>
                          <div class="c-btn-img c-btn-img-right-white"/>
                        </div> 
                      </div>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- sense - selfactualization - comunity -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 col-md-10">         
          <div class="row">
            <div class="offset-1 col-10 offset-md-0 col-md-12">
              <p class="c-txt-orange" style="font-weight: bold;">FORSCHUNGEN ZEIGEN</p>
              <h2 class="c-h2" style="color: #757575;">Glück im Kontext der Arbeit entsteht aus drei Faktoren</h2>          
            </div>
          </div>     
          <div class="row align-items-end">
            <div class="col-12 order-1 col-md-4 order-md-1">
              <img src="@/assets/3f_sense.svg" alt="HappinessAndWork_Sinn" style="margin: 20px; width: 100%; max-width: 280px;">
            </div>
            <div class="col-12 order-2 col-md-4 order-md-4">
              <h2 class="c-h2" style="color: #7CC9DB;">Sinn&shy;empfinden</h2>
            </div>
            <div class="col-12 order-3 col-md-4 order-md-7 align-self-start py-3 text-left">
              <p>
                Sinnempfinden erleben Menschen im Kontext ihrer Arbeit immer dann, wenn sie ein Ziel erreichen, 
                einen Beitrag zu einem übergeordneten Ziel leisten oder wenn ihre Arbeit einen Nutzen für andere Menschen hat. 
                Also immer dann wenn sie sehen: Meine Arbeit wird wirklich gebraucht.
              </p>
            </div>  
            <div class="col-12 order-4 col-md-4 order-md-2">
              <img src="@/assets/3f_selfactualization.svg" alt="HappinessAndWork_Selbstverwirklichung" style="margin: 20px; width: 100%;  max-width: 280px;">
            </div>  
            <div class="col-12 order-5 col-md-4 order-md-5">
              <h2 class="c-h2 c-txt-orange">Selbst&shy;verwirklichung</h2>
            </div>
            <div class="col-12 order-6 col-md-4 order-md-8 align-self-start py-3 text-left">
              <p>
                „Meine Arbeit macht Spaß!“ - ist ein typischer Ausspruch von Menschen, die sich in der Arbeit selbst verwirklichen können. 
                Diese Mitarbeiter setzen ihre Stärken ein, um ihre Ideen im Unternehmen umzusetzen, haben Handlungsspielraum und entfalten ihre Potenziale immer weiter.
              </p>
            </div>
            <div class="col-12 order-7 col-md-4 order-md-3">
              <img src="@/assets/3f_comunity.svg" alt="HappinessAndWork_Gemeinscahft" style="margin: 20px; width: 100%; max-width: 280px;">
            </div>
            <div class="col-12 order-8 col-md-4 order-md-6">
              <h2 class="c-h2" style="color: #F5A019;">Gemeinschaft</h2>
            </div>            
            <div class="col-12 order-9 col-md-4 order-md-9 align-self-start py-3 text-left">
              <p>
                Wann ist mein Team eine echte Gemeinschaft? Eine Gemeinschaft zeichnet sich durch ein gemeinsames und verbindendes Ziel, 
                einen professionellen und geschützten Umgang und den Grad der Vertrautheit ihrer Mitglieder aus.
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="d-flex justify-content-center" style="margin-bottom: 30px;"> 
              </div>
            </div> 
          </div>
          <!--
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="d-flex justify-content-center" style="margin-bottom: 30px;">  
                <!- button ->
                <div class="c-btn c-btn-orange" @click="$router.push('/effects')">
                    <span style="padding: 10px">Effekte von Glück</span>
                    <div class="c-btn-img c-btn-img-right-white"/>
                </div>
              </div>
            </div>
          </div>
          -->
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">
      <!-- wave bottom -->
      <div class="c-wave c-wave-top"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'
import Gauge from '@/components/Gauge.vue'
import Barchart from '@/components/Barchart.vue'

import ProxyClientService from '@/services/ProxyClientService.js'

export default {
  name: 'Survey',
  components: {
    Navi,
    Footer,  
    Gauge,
    Barchart
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` },
        { vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Wie Glücklich bist Du bei der Arbeit?",
      metaDescription: "Das Ergebnis des Selbttests zeigt wie es um deinem Glück bei der Arbeit bestellt ist.",
      metaUrl: "/survey",
      metaKeywords: "Selbsttest Ergebnisse",
      metaImage: "https://www.happinessandwork.de/share/survey.png",
      // data
      data: { data: {}, title: "Du im Vergleich",
        filter: [ {key: "rid", label: "Du"},
                  {key: "all", label: "Alle Befragten"},
                  {key: "gender", label: "Dein Geschlecht"}, 
                  {key: "education", label: "Dein Abschluss"}, 
                  {key: "age", label: "Deine Alter"},
                  {key: "salaryRange", label: "Dein Gehalt"},
                  {key: "industrialSector", label: "Deine Branche"}
                ]
      },
      //defaultData: {"criteria":{"name":"all","timestamp":"2020/06/19 12:17:04","values":["rid"]},"data":[{"avgSi":0,"avgSv":0,"avgGe":0,"avgHa":0}]},
      defaultData: {"criteria":{"name":"Du im Vergleich","timestamp":"2020/06/24 15:51:34","values":["Du","Alle Befragten","Dein Geschlecht","Dein Abschluss","Deine Alter"]},"data":[{"avgSi":0,"avgSv":0,"avgGe":0,"avgHa":0},{"avgSi":67,"avgSv":59,"avgGe":60,"avgHa":62},{"avgSi":69,"avgSv":57,"avgGe":59,"avgHa":62},{"avgSi":65,"avgSv":66,"avgGe":60,"avgHa":64},{"avgSi":64,"avgSv":57,"avgGe":60,"avgHa":60}]},
      displayMy: true
    }
  },
  computed: {
      // compute compare text
      compareText: function () {
        var allIdx = this.data.data.criteria.values.indexOf(this.data.filter[1].label);  // all
        var ridIdx = this.data.data.criteria.values.indexOf(this.data.filter[0].label);  // rid
        if (ridIdx != -1 && allIdx != -1 ) {
          var allVal = +this.data.data.data[allIdx].avgHa;
          var ridVal = +this.data.data.data[ridIdx].avgHa;
          if (allVal == ridVal) {
            return 'Du bist genauso glücklich wie der Durchschnitt aller Befragten.'
          } else if (allVal < ridVal) {
            return 'Du bist ' + (ridVal - allVal) + '% glücklicher als der Durchschnitt aller Befragten.'
          } else {
            return 'Der Durchschnitt aller Befragten ist ' + (allVal - ridVal) + '% glücklicher als du.'
          }
        } else {
          return '';
        }
      }
    },
    created: function () {
      // init diagram with default data
      this.data.data = this.defaultData;
    },
    // `this` points to the vm instance
    mounted: function () { 
      // retrieve data
      // callback for data processing
      let cb = (data) => {
        this.data.data = this.filterData(data, this.data.filter, this.data.title)
      }
      const proxyClientService = new ProxyClientService();
      // extract survey_id and response_id from route request parameter (see router config:  { path: '/survey/:rid?', component: Survey },)
      // rid is optional
      // rid parameter may contain just the response_id (e.g. 38344-43254-36624-42384-43241) which can be obfuscated or not
      // or both response_id and survey_id - pattern: <rid>--<sid> (e.g. 38344-43254-36624-42384-43241--772744) - rid my be obfuscated, sid not
      // if one of those is missing the appropriate default value applies
      let rid = null
      let sid = null
      //console.log("RID param: " + this.$route.params.rid); // eslint-disable-line no-console
      if (this.$route.params.rid) {
        let values = this.$route.params.rid.split('--')
        rid = values[0]
        sid = values.length > 1 ? values[1]: sid
      }
      proxyClientService.retrieveRidStatistics(rid, sid, cb);

    },
    methods: {
      // filter data and relabel them
      // data: data to filter
      // filter: filter to use
      // title: diagram title
      // retuns new data
      filterData(data, filter, title) {
        var newData = {criteria:{name: title, timestamp: data.criteria.timestamp, values:[]}, data:[]};
        data.criteria.values.forEach((value, i) => {
          var label = null;
          filter.forEach(entry => {if (entry.key == value) label = entry.label; })
          if (label) {
            newData.criteria.values.push(label);
            newData.data.push(data.data[i]);
          }
        })
        //console.log("Filetered data: " + JSON.stringify(newData)); // eslint-disable-line no-console
        return newData;
      },
      // got to location
      gotoLocation(url, target) {
        window.open(url, target);    
      },
      // handle swipe events (right, left, top, bottom)
      swipeHandler(direction) {
        //console.log("Swipe: " + direction); // eslint-disable-line no-console  
        switch (direction) {
          case 'right':
          case 'left':
            this.displayMy = !this.displayMy
            break;
        } 
      }
    }
}

</script>

<style>

</style>
