<template>

  <div id="academy_hwfy">

    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- happiness & Work for you -->  
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">
                <div class="row align-items-end">
                  <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">             
                    <p class="c-txt-orange" style="font-weight: bold;">Denk' an Dich</p>  
                    <h2 class="text-center text-md-center c-h2" >Happiness & Work für Dich</h2>
                  </div>
                  <div class="col-12 order-2 col-md-5 order-md-2 align-self-center">
                    <img id="bubble_hwfy" src="@/assets/academy_bubble_hwfy.png" alt="HappinessAndWork_for_you">
                  </div>
                  <div class="col-12 order-3 col-md-7 order-md-3 py-3 align-self-start">             
                    <p class="text-center text-md-left">
                      Dieser Workshop ist für alle, die verstehen möchten, was Menschen bei der Arbeit glücklich macht und Lust haben ihr Arbeitsleben positiver zu gestalten.  
                    </p>
                    <p class="text-center text-md-left">
                      Das Seminar besteht aus einer Online-Vorbereitungsphase und 2 Tagen Intensivseminar in Berlin. 
                      Danach kennst Du den Zusammenhang von Glück und Arbeit, die neuesten Erkenntnisse 
                      der Positiven Psychologie und Du hast Methoden gelernt, die Dir (und Deinen Kollegen)
                      helfen das Glückempfinden bei der Arbeit zu steigern. Die Reise beginnt bei Dir!
                    </p>
                    <!-- button -->
                    <div class="d-flex justify-content-left">
                      <a  href="mailto:info@happinessandwork.de">
                        <div class="c-btn c-btn-orange" >
                          <span style="padding: 10px">Seminar anfragen</span>
                          <div class="c-btn-img c-btn-img-right-white"/>
                        </div>
                      </a>
                    </div>
                    <!--
                    <ul class="text-left">
                      <li>Format: Seminar</li>
                      <li>Zeitraum: 2,5 Tage</li>
                      <li>Teilnehmerzahl: 14</li>
                    </ul>
                    -->
                    <!--
                    <p class="text-left">
                      <br>
                      <a href="/downloads/HappinessAndWork_für_dich.pdf" download="HappinessAndWork_für_dich.pdf">Infos zum Download</a>
                    </p>
                    -->
                    <!--
                    <p class="text-center text-md-left">
                      Sommer-Special: Einmal im Jahr findet dieses Seminar 
                      an einem ganz besonders schönen Fleck dieser Erde statt. Energie pur!
                    </p>
                    <p class="text-left">
                      <a href="/downloads/HappinessAndWork_für_dich_Sommerspecial.pdf" download="HappinessAndWork_für_dich_Sommerspecial.pdf">Infos zum Download</a>
                    </p>
                    <p class="text-center text-md-left">
                      <i>2021 macht unser Sommer-Special eine "Pandemie-Pause". Wir freuen uns umso mehr auf den Sommer 2022 mit Euch.</i>
                    </p>
                    -->
                  </div>
                  
                  <div class="col-12 order-4 col-md-12 order-md-4 py-3 align-self-center" v-show="eventbriteLoaded">
                    <p class="text-left">
                        <br/>
                    </p>

                    <!-- eventbrite order iframe -->
                    <!--
                    <div role="tablist">
                      <template v-for="(course, idx) in eventbriteCourse" >
                        <div role="tab" :key="idx">
                          <div class="d-flex justify-content-left">  
                            <div class="c-btn c-btn-orange d-flex justify-content-center" style="width: 250px; padding-right:10px;" :class="course.visible ? null : 'collapsed'" :aria-expanded="course.visible ? 'true' : 'false'" :aria-controls="'accordion-' + course.eventId" @click="course.visible = !course.visible">
                              <span style="padding: 10px">{{course.date}}</span>
                            </div>
                          </div>
                          <b-collapse :id="'accordion-' + course.eventId" accordion="course1-events" v-model="course.visible" role="tabpanel">
                            <b-card class="m-2">
                              <div :id="'eventbrite-widget-container-'+ course.eventId"></div>
                            </b-card>
                          </b-collapse>
                        </div>
                      </template>
                    </div>
                    -->

                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Academy_HWFY',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` },
        { vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Academy - Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind?",
      metaDescription: "Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind? Die Felicicon GmbH hat Weiterbildungsangebote für Mitarbeiter und Führungskräfte und führen in Mitarbeiter Events in das Thema ein.",
      metaUrl: "/academy/happiness_and_work_for_you",
      metaKeywords: "Seminar Beratung Coaching Teamentwicklung Keynotes 'Happinessandwork Academy'",
      metaImage: "https://www.happinessandwork.de/share/academy.png",
      // eventbride
      eventbriteCourse : [
        //{date: '10. - 12. September 2020', eventId: '116082992355', visible: false},
        //{date: '12. - 14. November 2020', eventId: '116977666349', visible: false},
        //leadership{date: '06. - 08. April 2022', eventId: '165875950433', visible: false},
        //{date: '11. - 13. November 2021', eventId: '129660751799', visible: false},
        //{date: '09. - 11. Juni 2022', eventId: '149147647629', visible: false},
        //{date: '10. - 12. November 2022', eventId: '165748505241', visible: false},
        {date: '8. - 10. Juni 2023', eventId: '503473311227', visible: false},
        {date: '16. - 18. November 2023', eventId: '503456821907', visible: false}
        //{date: '11. - 13. Juni 2021', eventId: '98193488367', visible: false}
      ],
      eventbriteLoaded: false   // status of eventbrite script loading
    }
  },
  // called when component is mounted to the dom
  mounted: function () {
    // load js code from eventbrite
    const eventbriteScript = 'https://www.eventbrite.de/static/widgets/eb_widgets.js'
    this.$loadScript(eventbriteScript)
      .then(() => {
        // Script is loaded
        let eb = {
          // Required
          widgetType: 'checkout',
          eventId: null,
          iframeContainerId: null,
          // Optional
          iframeContainerHeight: 450,  // Widget height in pixels. Defaults to a minimum of 425px if not provided
          onOrderComplete: this.orderCompletedCallback  // Method called when an order has successfully completed
        }
        // prepare iframes for course
        this.eventbriteCourse.forEach((course) => {
          eb.eventId = course.eventId;
          eb.iframeContainerId = 'eventbrite-widget-container-' + course.eventId,
          // create Iframes
          window.EBWidgets.createWidget(eb)
        })
        // stop spinner
        this.eventbriteLoaded = true
      })
      .catch(() => {
        // Failed to fetch script
        console.log('Failed to load eventbrite script: ' + eventbriteScript); // eslint-disable-line no-console
      });
  },
  methods: {
    // called when order complete
    orderCompletedCallback() {
      console.log('Order complete!'); // eslint-disable-line no-console
      // send event to analytics
      this.$gtag.event('complete', {
        event_category: 'order',
        event_label: 'Academy',
        value: 123
      })
      /*
      this.$ga.event({
        eventCategory: 'order',
        eventAction: 'complete',
        eventLabel: 'Academy',
        eventValue: 123
      })
      */
    }
  }
}

</script>

<style>

</style>
