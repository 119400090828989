/*
 This module provide the client functions to retrieve data from the lime proxy.
 The endpoint will be assumed to be on the same host as the website origin (CORS prevention).
 If the origin includes 'localhost' it will use LOCAL_HOST as host.

*/

import axios from 'axios'
import https from 'https'

import ObfuscationService from '@/services/ObfuscationService.js'

// survey id
const SURVEY_ID = 772744;
// default response id
const RESPONSE_ID = 12000;
//const LOCAL_HOST = 'http://localhost:8081';
const LOCAL_HOST = 'https://my.happinessandwork.de';
const API_ROOT = '/lime-proxy2/api/happinesssurvey';

// construct the API endpoint URL
const getEndpoint = () => {
    // assume APIs are served from the same server as Webapp
    // must be used in production to prevent CORS problems
    var loc = window.location;
    var baseHoststring = loc.protocol + "//" + loc.host;
    var hoststring = baseHoststring;
    // you can overwrite it here
    //hoststring = 'http://cloudia';			// 
    //hoststring = "https://my.happinessandwork.de"
    if (loc.host.indexOf('localhost') !== -1) {
      hoststring = LOCAL_HOST;
    }   
 
    // happiness API configuration
    var api =  hoststring + API_ROOT;
    return api;
}

// client module for retreiving data from lime proxy
export default class ProxyClientService {

    // retrieve overall statistics
    // criteria: criteria to retrieve / if null no criteria
    // sid: survey id (not obfuscated) - if null use default
    // callback: function to process result
    retrieveOverallStatistics(criteria, sid, callback) {
      if (sid == null) {
        //console.log("SID is missing!"); // eslint-disable-line no-console
        // if no survey id given use default
        sid = sid == null ? SURVEY_ID : sid
      }   
      let api = getEndpoint();
      // GET  http://cloudia/lime-proxy/api/happinesssurvey/{sid}
      // GET  http://cloudia/lime-proxy/api/happinesssurvey/{sid}/criteria/crit
      // crit: age | gender | salary ...
      var httpsAgent = new https.Agent({ rejectUnauthorized: false, keepAlive: true });
      axios.get(api + '/' + sid + (criteria ? ('/criteria/' + criteria) : ''), {
          params: {}, headers: {}, responseType: 'json', httpsAgent: httpsAgent
        })
        .then(response => {
          //console.log("Success " + JSON.stringify(response.data)); // eslint-disable-line no-console
          // call callback function
          callback(response.data);
        })
        .catch(error => {
          console.log("Read data failed!" + error); // eslint-disable-line no-console
        });
    }

    // retrieve statistics for response rid from survey survey sid
    // rid: reponse id - can be obfuscated - if null use default
    // sid: survey id (not obfuscated) - if null use default
    // callback: function to process result
    retrieveRidStatistics(rid, sid, callback) {
        if (rid == null) {
            //console.log("RID is missing!"); // eslint-disable-line no-console
            // if no response id given use default
            rid = (rid == null) ? RESPONSE_ID : rid
        }
        if (sid == null) {
          //console.log("SID is missing!"); // eslint-disable-line no-console
          // if no survey id given use default
          sid = (sid == null) ? SURVEY_ID : sid
        }
        //console.log("RID: " + rid + " SID: " + sid); // eslint-disable-line no-console
        let api = getEndpoint();
        
        // obfuccate the rid if not obfuscated
        var ridObf = '';
        if (rid.toString().indexOf('-') == -1) {
          const obfuscationService = new ObfuscationService();
          ridObf = obfuscationService.obfusc(rid);
          //console.log("Obfuscate: " + rid + " ==> " + ridObf); // eslint-disable-line no-console
        } else {
          ridObf = rid;
        }
        //console.log("RID: " + ridObf + " SID: " + sid); // eslint-disable-line no-console
        //var unobf = obfuscationService.unobfusc(obf, 27);
        //console.log(obf + " ==> " + unobf); // eslint-disable-line no-console
        // GET  http://cloudia/lime-proxy/api/happinesssurvey/{sid}/rid{rid}
        var httpsAgent = new https.Agent({ rejectUnauthorized: false, keepAlive: true });
        axios.get(api + '/' + sid + '/rid/' + ridObf, {
            params: {}, headers: {}, responseType: 'json', httpsAgent: httpsAgent
          })
          .then(response => {
            //console.log("Success " + JSON.stringify(response.data)); // eslint-disable-line no-console
            // call callback function
            callback(response.data);
          })
          .catch(error => {
            console.log("Read data failed!" + error); // eslint-disable-line no-console
          });
    }
  
  }

  /* service usage
    import ProxyClientService from './services/ProxyClientService';

    const proxyClientService = new ProxyClientService();

    // create teh callback function
    var cb = (data) => {this.diagrams[diagramIdx].data = this.filterData(response.data, this.diagrams[diagramIdx].filter, this.diagrams[diagramIdx].title)
    }

    proxyClientService.retrieveData(criteria, cb);
  */