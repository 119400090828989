<template>
  <!--svg v-bind:id="chartId" width="560" height="500" viewBox="0 0 560 500"/-->
  <g v-bind:id="chartId" class="linechart"/>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'Linechart',
  props: {
    id: String,
    data: {},
    width: Number,
    height: Number,
    curveMode: String,   // Basis, Linear, Cardinal, Step, Natural (default)
    rotateXLabels: Boolean,  // if true rotate x axis labels
    darkMode: Boolean  // if true all lines and labels are dark, otherwise light
  },
    data() {
        return {
          chartId: 'chart-' + this.$props.id,
          diagram: null,
          loadData: {},
          width_: this.$props.width != undefined ? this.$props.width : 200,
          height_: this.$props.height != undefined ? this.$props.height : 200,
          fadeInTime: 1000,
          fadeOutTime: 300,
          label: {"avgSi": "Sinnempfinden", "avgSv": "Selbstverwirklichung", "avgGe": "Gemeinschaft", "avgHa": "Glück"},
          chartData: (this.$props.data != undefined && this.$props.data != null) ? this.$props.data : {
            "criteria":    {
              "name": "sallery",
              "values":       [ "-30T€", "31-50T€", "51-70T€", "70-100T€", "100T€+" ]
            },
            "data":    [
                    { "total": 125, "avgSi": 62, "avgSv": 56, "avgGe": 64, "avgHa": 61 },
                    { "total": 103, "avgSi": 62, "avgSv": 59, "avgGe": 64,"avgHa": 61 },
                    { "total": 69, "avgSi": 65, "avgSv": 63, "avgGe": 61, "avgHa": 63 },
                    { "total": 12, "avgSi": 58, "avgSv": 60, "avgGe": 50, "avgHa": 56 },
                    { "total": 12, "avgSi": 70, "avgSv": 73, "avgGe": 63, "avgHa": 68}
            ]
          },
          // curveBasis, curveLinear,  ,curveCardinal, curveStep, curveNatural
           curveFunction: this.$props.curveMode == 'Basis' ? d3.curveBasis 
                       : (this.$props.curveMode == 'Linear' ? d3.curveLinear 
                       : (this.$props.curveMode== 'Cardinal' ? d3.curveCardinal 
                       : (this.$props.curveMode == 'Step' ? d3.curveStep
                       //: this.$curveNatural == 'Natural' ? d3.$curveNatural
                       : d3.curveBasis))) 
        }
    },
    mounted: function () {
      // `this` points to the vm instance
      // this.fetchDate();
      // create diagram
      this.diagram = this.setupDiagram(this.chartId);
      // draw data
      this.diagram.update(this.chartData);

    },
    watch: {
      
        data(newData) { // eslint-disable-line no-unused-vars
                //console.log('Data changed to ' + newData.criteria.name) // eslint-disable-line no-console
                // update gauge
                this.diagram.update(this.$props.data); 
        }
    },
    methods: {

        // setup chart
        setupDiagram(elementId) {

          var svg = d3.select("#" + elementId),
              margin = {top: 10, right: 20, bottom: 30, left: 40},
              width = this.width_,
              height = this.height_
            //width = +svg.attr("width") - margin.left - margin.right,
            //height = +svg.attr("height") - margin.top - margin.bottom

          var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .attr("class", this.$props.darkMode ? "dark" : "light");

          // outer x scale
          var x0 = d3.scaleBand()
              .rangeRound([0, width])
              //.paddingInner(0.15)
              ;

          // y scale (top to bottom)
          var y = d3.scaleLinear()
              .rangeRound([height, 0]);
          // color scale 
          var z = d3.scaleOrdinal()
              .range(["#7CC9DB", "#E9561F", "#F5A019", "#00986D"]);

          // gridlines in x axis function
          function make_x_gridlines() {		
              return d3.axisBottom(x0)
                  .ticks(5)
          }

          // gridlines in y axis function
          function make_y_gridlines() {		
              return d3.axisLeft(y)
                  .ticks(10)
          }

          // x (vertical) gridlines
          g.append("g")			
              .attr("class", "x grid")
              .attr("transform", "translate(0," + height + ")")

          // y (horizontal) gridlines
          g.append("g")			
              .attr("class", "y grid")

          // x axis + legend
          g.append("g")
              .attr("class", "x axis " + (this.$props.rotateXLabels ? "rotate-labels" : ""))
              .attr("transform", "translate(0," + height + ")")
            .append("text")
              //.attr("transform", "translate(" + width + ",0)")
              .style("transform", "rotate(0deg) translate(495px,0px)")
              .attr("class","label")
              .attr("y", 6)
              .attr("dy", "-.8em")
              .attr("fill", function() {return this.textColor})
              .style("text-anchor", "end")
              .text("Value");            

          // y axis + legend
          g.append("g")
              .attr("class", "y axis")
            .append("text")
              .attr("transform", "rotate(-90) translate(-5)") // rotate the text!
              .attr("class","label")
              .attr("y", 6)
              .attr("dy", ".8em")
              .attr("fill", function() {return this.textColor})
              .style("text-anchor", "end")
              .text("Prozent");

          // legend
          g.append("g")
            .attr("class", "legend")
            .attr("text-anchor", "end")
            // temp only
/*             .append("text")
                .attr("class","title")
                .attr("y", 0)
                .attr("dy", "1em")
                .text("unknown")
                .style("transform", "translate(250px, 0px)") */
                ;
      
          var vm = this;

          // draw diagram content and updates if data changed
          function draw(data) {
            //console.log('data ' + JSON.stringify(data)) // eslint-disable-line no-console

            //var keys = Object.keys(data.data[0]).slice(1,5) // => [avgSi, avgSv, avgGe, avgHa]
            var keys = Object.keys(data.data[0])        // => [avgSi, avgSv, avgGe, avgHa]
            //console.log('keys ' + JSON.stringify(keys)) // eslint-disable-line no-console

            // transform data  (add data.graphs: [{name: "avgSi", values: [val1, val2, val3, ...]}, {name: "avgSv", values: [val1, val2, val3, ...]}, ...])
            data.graphs = [];
            keys.forEach((key) => {
                var graph = [];
                data.data.forEach(data => { graph.push(data[key])})
                data.graphs.push({name: key, values: graph})
              }
            )
            //console.log('graphs ' + JSON.stringify(data.graphs)) // eslint-disable-line no-console

            // map criteria values [] to outer x range
            x0.domain(data.criteria.values.map(function(d){ return d;}))
            // offset for linestart x
            var offs = x0(data.criteria.values[1])/2;
            // create domain for y axis based on max values of [avgSi, avgSv, avgGe, avgHa] data
            var maxVal = d3.max(data.graphs, function(d) { return d3.max(d.values, function(val) { return val; }); })
            var minVal= d3.min(data.graphs, function(d) { return d3.min(d.values, function(val) { return val; }); })
            if (minVal === 0 && maxVal === 0) {maxVal = 100} // make y axis to go from 0 -100 if values are 0
            
            //console.log('Y Range: ' + d3.max([minVal-5, 0]) + ' - ' + d3.min([maxVal+15, 100])) // eslint-disable-line no-console
            y.domain([d3.max([minVal-5, 0]), d3.min([maxVal+15, 100])]).nice();

            // populate x gridlines
            g.select('.x.grid').transition().duration(vm.fadeInTime).call(make_x_gridlines()
              .tickSize(-height)
              .tickFormat(""));

            // populate y gridlines
            g.select('.y.grid').transition().duration(vm.fadeInTime).call(make_y_gridlines()
              .tickSize(-width)
              .tickFormat(""));

            // populate x axis
            g.select('.x.axis').transition().duration(vm.fadeInTime).call(d3.axisBottom(x0))
             .select(".label")
                .text(function() {return data.criteria.name});

            // populate y axis
            g.select('.y.axis').transition().duration(vm.fadeInTime).call(d3.axisLeft(y).ticks(null, "s"));

            //update title 
            //g.select(".title").text(data.criteria.name);

            // bind data to linegroup (and line)
            var linegroups = g.selectAll(".linegroup").data(data.graphs)
            // remove linegroup
            linegroups.exit().transition().duration(vm.fadeOutTime)
              .style('fill-opacity', 1e-6)
              .remove();
            // enter linegroup
            var lineg = linegroups.enter().append("g")
              .attr("class", "linegroup")
            
            lineg.append("path")
                  .attr("class", "line_1")  
                  //.attr("stroke-width", 8)
                  //.attr("stroke", "#ffffff")
                  .datum(function(d){return d.values})
                  .transition().duration(vm.fadeInTime)
                  .call(drawPath)   
              ;
            lineg.append("path")
                  .attr("class", "line_2")
                  //.attr("stroke-width", 6)
                  .attr("stroke", function(d){ return z(d.name)})
                  .datum(function(d){return d.values})
                  .transition().duration(vm.fadeInTime)
                  .call(drawPath)   
              ;
            // update linegroups
            linegroups.select(".line_1")
                .datum(function(d){return d.values})
              ;
            linegroups.select(".line_2")
                .attr("stroke", function(d){ return z(d.name)})
                .datum(function(d){return d.values})
              ;              


            // bind line data (taken from parent (linegroup) data)
            var lines1 = g.selectAll(".linegroup").selectAll(".line_1");
            var lines2 = g.selectAll(".linegroup").selectAll(".line_2");

            // remove lines
            lines1.exit().transition().duration(vm.fadeOutTime)
              .style('fill-opacity', 1e-6)
              .remove()
            ;
            lines2.exit().transition().duration(vm.fadeOutTime)
              .style('fill-opacity', 1e-6)
              .remove()
            ;
            // enter lines
            lines1.enter()
              .append("path")
                  .attr("class", "line_1")  
                  //.attr("stroke-width", 8)
                  //.attr("stroke", "#ffffff")
                  .datum(function(d){return d.values})
                  .transition().duration(vm.fadeInTime)
                  .call(drawPath) 
            ;
            lines2.enter()
              .append("path")
                  .attr("class", "line_2")
                  //.attr("stroke-width", 6)
                  .attr("stroke", function(d){ return z(d.name)})
                  .datum(function(d){return d.values})
                  .transition().duration(vm.fadeInTime)
                  .call(drawPath)
            ;
            // update lines
            lines1
              .transition().duration(vm.fadeInTime)
              .call(drawPath)
            ;
            lines2
              .transition().duration(vm.fadeInTime)
              .call(drawPath)
            ;

            function drawPath(selection) {
              selection.attr("fill", "none")
                .attr("stroke-linejoin", "round")
                .attr("stroke-linecap", "round")
                .attr("d", d3.line()               
                  .curve(vm.curveFunction)  //curveBasis, curveLinear,  ,curveCardinal, curveStep, curveNatural
                  .x(function(d,i) { return x0(data.criteria.values[i]) + offs})
                  .y(function(d) { return y(d)})
                  )   
            }


/* 
             // draw all graphs

            //offs= 10
              // draw all graphs
            keys.forEach((key,j) => {
              // white shadow
              g.append("path")
                .datum(data.graphs[j].values)
                .attr("fill", "none")
                .attr("stroke", "#ffffff")
                .attr("stroke-width", 7)
                .attr("stroke-linejoin", "round")
                .attr("stroke-linecap", "round")
                .attr("d", d3.line()
                  .x(function(d,i) { return x0(data.criteria.values[i]) + offs})
                  //.y(function(d) { return y(d.avgSi) })
                  //.y(function(d) { return y(d[key]) })
                  .y(function(d) { return y(d) })
                  )  
              // colored line
              g.append("path")
                .datum(data.graphs[j].values)
                .attr("fill", "none")
                .attr("stroke", z(key))
                .attr("stroke-width", 4)
                .attr("stroke-linejoin", "round")
                .attr("stroke-linecap", "round")
                .attr("d", d3.line()
                  .x(function(d,i) { return x0(data.criteria.values[i])+ offs})
                  //.y(function(d) { return y(d.avgSi) })
                  //.y(function(d) { return y(d[key]) })
                  .y(function(d) { return y(d) })
                  )       
            });  */

            // bind data to legend
            var legendItem = g.selectAll(".legend").selectAll("g")
              .data(keys.slice().reverse());

            // remove legend item
            legendItem.exit().transition().duration(vm.fadeOutTime)
              .style('fill-opacity', 1e-6)
              .remove()
            ;
            // enter legend item
            var lg = legendItem.enter()
              .append("g")
                .attr("transform", function(d, i) { return "translate(0," + i * 24 + ")"; })
                ;
            lg.append("rect").call(drawLegendRect);
            lg.append("text").call(drawLegendText);

            // update legend item
            lg.transition().duration(vm.fadeInTime).selectAll("g").select("rect").call(drawLegendRect);
            lg.transition().duration(vm.fadeInTime).selectAll("g").select("text").call(drawLegendText);

            // draw legend item
            function drawLegendRect(selection) {
               selection.attr("x", width - 19)
                .attr("rx", "3px")
                .attr("ry", "3px")
                .attr("width", 19)
                .attr("height", 19)
                .attr("fill", z);
            }
            function drawLegendText(selection) {
               selection.attr("x", width - 24)
                .attr("y", 9.5)
                .attr("dy", "0.32em")
                .text(function(d) { return vm.label[d]; });
            }
            
          }

          // update
          function Diagram() {
            // update chart
            this.update = function(data) {
                /* eslint-disable no-debugger */
                //debugger;
                draw(data);    
            };
          }
          return new Diagram();
        } // end setupChart

    }
  }

</script>

<style lang="scss">
.linechart {
  .light, .light text {
    stroke: #ffffff;
    fill: #ffffff;
    color: #ffffff;
  }
  .dark, .dark text {
    stroke: #111111;
    fill: #111111;
    color: #111111;
  }
  .x.rotate-labels text {
    transform: rotate(40deg) translate(10px,0px) ;
    text-anchor: start;
  }
  .axis line {
    /* stroke: #ffffff; */
  }

  .axis path {
    /* stroke: #ffffff; */
  }

  .axis text,
  .legend text {
    /* fill: #ffffff; */
    stroke-width: 0;
    font-family: 'Nunito';
    font-size: 1rem;
    font-weight: lighter;
  } 

  .grid line {
    /* stroke: #ffffff; */
    stroke-opacity: 0.1;
    shape-rendering: crispEdges;
  }

  .grid .domain {
    stroke-width: 0px;
  }

  .legend rect {
    stroke-width: 1;
    stroke: #ffffff;
  }

  .line_1 {
    stroke: #ffffff;
    stroke-width: 8;
  }
  .line_2 {
    stroke-width: 6;
  }
}

</style>

