<template>

  <div id="info_press">
    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- info -->  
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">                    
                <div class="row">
                  <div class="col-12 d-flex align-items-center flex-column">
                    <h1 class="c-h1">Presse</h1>  
                    <p class="c-txt-orange" style="font-weight: bold;">Was in der Presse über uns zu lesen ist</p>      
                  </div>
                  <div class="col-12 py-3 col-md-8 d-flex align-items-center justify-content-center">             
                    <p class="text-center text-md-left">
                      Hier findest Du eine Auswahl an Pressebeiträgen, Interviews und Vorträgen von uns. 
                      Wenn Du selbst über Glück bei der Arbeit schreiben möchtest oder einen Keynotespeaker suchst, 
                      schreib uns an <a  href="mailto:info@happinessandwork.de">info@happinessandwork.de</a>. 
                    </p>
                  </div>
                  <div class="col-12 py-3 col-md-4 d-flex justify-content-end">             
                    <img id="bubble_academy_1" src="@/assets/bubble_red_1.png" alt="bubble">  
                  </div>
                </div>    
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- press -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <!--
          <div class="row align-items-start">
            <div class="col-12 py-3">             
              <h2 id="books" class="text-center text-md-center c-h2" >Bücher</h2>
            </div>
          </div>
          -->
          <div class="row justify-content-center">
            <div class="col-12 col-md-10">
              <!-- Campus Stories -->
              <div class="row justify-content-center align-items-center">  
                <div class="col-12 order-1 py-3 text-left text-md-left  ">
                  <h3 id="dgo" class="c-txt-orange"><b>Die Presse</b> – Jeder (Arbeitgeber) ist des Glückes Schmied</h3>
                </div>
                <div class="col-12 order-2 col-md-7 py-3 text-left text-md-left">
                  <img id="press_1" src="@/assets/press/htw_campus_story.png" alt="Ricarda_Rehwaldt_Die_Presse"> 
                </div>
                <div class="col-12 order-3 col-md-5 py-3 text-left ">            
                  <p>
                    "Ein Fokus auf bloße Zufriedenheit reicht heute nicht mehr aus, um langfristig Talente zu binden“, sagt Ricarda Rehwaldt, Gründerin der Happiness & Work Akademie.
                  </p>
                  <ul>
                    <li><a href="https://www.diepresse.com/6246605/jeder-arbeitgeber-ist-des-glueckes-schmied" target="_blank">Die Presse</a></li>
                  </ul>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- press -->
    <div class="c-grey-bg">
      <!-- wave top -->
      <div class="c-wave c-wave-top"/>

      <!-- middle -->
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">
                <div class="row align-items-end">
                  <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">              
                    <h2 id="press" class="text-center text-md-center c-h2" >Von uns & über uns</h2>
                  </div>
                  <div class="col-12 order-2 col-md-2 order-md-3">
                    <img id="bubble_interview" src="@/assets/bubble_red_3.png" alt="bubble">
                  </div>
                  <div class="col-12 order-3 col-md-10 order-md-2 py-3 align-self-start text-left text-md-left">
                    <div> 
                      <h3 class="c-txt-orange"><b>Neue Züricher Zeitung</b> - Sonderbeilage Bildung 06/2022</h3>         
                      <p>
                        In der Sonderbeilage 'Bildung' vom 8.6.2022 wurden im Beitrag 'Freude statt Mühe' Forschungs- und Umfrageergebnisse von 'Happiness & Work' veröffentlicht. Ricarda Rehwaldt stand hierzu in einem Interview Rede und Antwort.
                      </p>
                      <blockquote class="blockquote">
                        <div class="row">
                          <div class="col-12 col-md-5 py-3 text-left text-md-left">
                            <p class="mb-1" ><em>"Sind Sie zufrieden in Ihrem Job? Schon möglich. Aber macht Sie Ihre Arbeit auch glücklich? ..."</em></p>
                          </div>
                          <div class="col-12 col-md-7 py-3 text-left text-md-left">
                            <img id="press_1" src="@/assets/press/nzs_zufriedenheit_allein_reicht_nicht.png" alt="NeueZüricherZeitung_Zufriedenheit_allein_reicht_nicht"> 
                            <p style="font-size: 0.6em">
                              Illustration: Joana Kelén, Neue Züricher Zeitung
                            </p>
                          </div>
                        </div>
                        <footer class="blockquote-footer">Neue Züricher Zeitung - 'Die glückliche Karriere - ein Schwerpunkt'<cite title="Neue Züricher Zeitung - 'Die glückliche Karriere - ein Schwerpunkt'"><a href="https://www.nzz.ch/bildung/karriere" target="_blank">Onlineausgabe</a></cite></footer>
                        <footer class="blockquote-footer">Neue Züricher Zeitung - 'Zufriedenheit allein reicht nicht: Diese drei Faktoren machen glücklich bei der Arbeit'<cite title="Neue Züricher Zeitung - 'Zufriedenheit allein reicht nicht: Diese drei Faktoren machen glücklich bei der Arbeit'"><a href="https://www.nzz.ch/bildung/sinn-selbstverwirklichung-team-so-macht-arbeit-gluecklich-ld.1686551" target="_blank">Onlineausgabe'</a></cite></footer>
                        <footer class="blockquote-footer">Neue Züricher Zeitung - Sonderbeilage 'Bildung' <cite title="Neue Züricher Zeitung - Sonderbeilage 'Bildung' "><a href="/downloads/NeuZuericherZeitung_ZufliedenheitAlleinReichtNicht_2022.pdf" download="NeueZuericherZeitung_SonderbeilageBildung_2022_06-08.pdf">Download Sonderbeilage 'Bildung'</a></cite></footer>
                      </blockquote>
                    </div>
                    <br>

                    <div> 
                      <h3 class="c-txt-orange"><b>Springer Professional</b> - International Day of Happiness 2019</h3>         
                      <p>
                        Anlässlich des International Day of Happiness 2019 wurde Ricarda Rehwaldt von Andrea Amerland für Springer Professional zu ihrer Expertise zu Glück und Arbeit interviewt.
                      </p>
                      <blockquote class="blockquote">
                        <p class="mb-1" ><em>"In 2018 lag das Glück in Organisationen über alle Branchen bei 63%. Hier wird deutlich: 
                        Es gibt noch Luft nach oben."</em></p>
                        <footer class="blockquote-footer">Ricarda Rehwaldt im <cite title="Interview Springer Professional"><a href="https://www.springerprofessional.de/organisationsentwicklung/mitarbeitermotivation/-in-unternehmen-gibt-es-kaum-wissen-darueber--wie-glueck-funktioniert-/16540340" target="_blank">Interview bei Springer Professional</a></cite></footer>
                      </blockquote>
                    </div>
                    <br>

                    <div> 
                      <h3 class="c-txt-orange"><b>Ärzteblatt</b> - Glück in ärztlichen Arbeitsalltag - was ist wichtig?</h3>           
                      <p>
                          Ein Interview mit Dr. Ricarda Rehwaldt, die zu diesem Thema forscht und publiziert.
                      </p>
                      <blockquote class="blockquote">
                        <p class="mb-1" ><em>"Die Erkenntnis aus der Forschung ist, dass das eigene Glücksempfinden nur zu zehn Prozent durch äußere Bedingungen beeinflusst wird. Wir haben es zum größten Teil selbst in der Hand."</em></p>
                        <footer class="blockquote-footer">Ricarda Rehwaldt im <cite title="Interview Ärzteblatt"><a href="https://aerztestellen.aerzteblatt.de/de/redaktion/glueck-im-aerztlichen-arbeitsalltag" target="_blank">Interview im Ärzteblatt</a></cite></footer>
                      </blockquote>
                    </div>
                    <br>

                    <div> 
                      <h3 class="c-txt-orange"><b>zero360</b> - Was macht uns bei der Arbeit glücklich?</h3>           
                      <p>
                         Dr. Ricarda Rehwaldt im Interview mit zero360
                      </p>
                      <blockquote class="blockquote">
                        <p class="mb-1" ><em>"Glück ist etwas anderes als Zufriedenheit."</em></p>
                        <footer class="blockquote-footer">Ricarda Rehwaldt im <cite title="Interview zero360"><a href="https://zero360.de/blog/was-macht-uns-bei-der-arbeit-gluecklich/" target="_blank">Interview bei zero360</a></cite></footer>
                      </blockquote>
                    </div>
                    <br>

                    <div>    
                      <h3 class="c-txt-orange" >Dr. Ricarda Rehwaldt bei <b>Springer Professional</b></h3>   
                      <p>
                        Sie ist Autoren verschiedener bei Springer Professional erschienener Bücher. 
                      </p>      
                      <blockquote class="blockquote">
                        <p class="mb-1" ><em></em></p>
                        <footer class="blockquote-footer">Ricarda Rehwaldt bei <cite title="Person Springer Professional"><a href="https://www.springerprofessional.de/ricarda--rehwaldt/16540604" target="_blank">Springer Professional</a></cite></footer>
                      </blockquote>
                    </div>
                    <br>

                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Info_Press',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` }//,
        //{ vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- in der Presse",
      metaDescription: "Hier findest Du Interview und Informationen aus der Presse über uns.",
      metaUrl: "/info/press",
      metaKeywords: "Presse Stories",
      metaImage: undefined,
    }
  },
  // called when component is mounted to the dom
  mounted: function () {
/*       const anchor = this.$router.currentRoute.hash;
      setTimeout(() => { 
        if (anchor && document.querySelector(anchor)) {
        //var elem = document.querySelector(this.$route.hash);
        var elem = document.querySelector(anchor);
        console.log("scoll to: " + anchor); // eslint-disable-line no-console
        elem.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }
      }, 1000) */
    },
    methods: {
    }
}

</script>

<style>

</style>
