


var DATAPROTECTION_LINK = '<a href="/dp" class="cc-link"> Datenschutzerklärung</a>';
/* eslint-disable */

function analyticsOn() {
    console.log('analytics on!')
    // load gtag script from google

    // google MESS-ID
    const googleMessId = 'G-WVR9WW4M8T'

    cc.loadScript('https://www.googletagmanager.com/gtag/js?id=' + googleMessId, function () {
        // script loaded
        // Define dataLayer and the gtag function.
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
    
        gtag('consent', 'default', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        })
        gtag('js', new Date());
        gtag('config', googleMessId);
    });
}

function analyticsOff() {
    console.log('analytics off!')
    // disable consent in google analytics
    // will still send ping to google but they will not be stored anymore
    // after next page refresh nothing will be send anymore
    typeof gtag === 'function' && gtag('consent', 'update', {
        'analytics_storage': 'denied',
        'ad_storage': 'denied',
    });
}

const CookieConsentConfig = {
    current_lang: 'de',
    autoclear_cookies: true,                    // default: false
    cookie_name: 'cc_cookie_consent',           // default: 'cc_cookie'
    cookie_expiration: 365,                     // default: 182
    cookie_necessary_only_expiration: 30,       // no default
    page_scripts: true,                         // default: false
    force_consent: false,                       // default: false

    // auto_language: null,                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                           // default: true
    // delay: 0,                                // default: 0
    hide_from_bots: true,                       // default: true
    // remove_cookie_tables: false              // default: false
    // cookie_domain: location.hostname,        // default: current domain
    // cookie_path: '/',                        // default: '/'
    // cookie_same_site: 'Lax',                 // default: 'Lax'
    // use_rfc_cookie: false,                   // default: false
    // revision: 0,                             // default: 0                 

    gui_options: {
        consent_modal: {
            layout: 'cloud',                    // box,cloud,bar
            position: 'bottom center',          // bottom,middle,top + left,right,center
            transition: 'slide'                 // zoom,slide
        },
        settings_modal: {
            layout: 'bar',                      // box,bar
            position: 'left',                   // right,left (available only if bar layout selected)
            transition: 'zoom'                 // zoom,slide
        }
    },

    onFirstAction: function(){
        console.log('onFirstAction fired!');
    },
    onAccept: function (cookie) {
        console.log('onAccept fired!')
        // If analytics category is disabled => disable google analytics
        if (cc.allowedCategory('analytics')) {
            analyticsOn();
        }
    },

    onChange: function (cookie, changed_preferences) {
        console.log('onChange fired!');
        if (changed_preferences.indexOf('analytics') != -1) {
            // If analytics category is disabled => disable google analytics otherwise enable
            if (!cc.allowedCategory('analytics')) {
                analyticsOff()
            } else {
                analyticsOn()
            }
        }

    },

    languages: {
        'de': {
            consent_modal: {
                title: 'Diese Webseite verwendet Cookies',
                description: 'Unsere Webseite verwendet Cookies um die Funtion der Seite sicherzustellen und Tracking Cookies um zu verstehen wie die Seite benutzt wird. Letzteres geschieht nur, wenn sie dem zustimmen.' + DATAPROTECTION_LINK,
                primary_btn: {
                    text: 'Alle akzeptieren',
                    role: 'accept_all'      //'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Individuelle Auswahl',
                    role: 'settings'       //'settings' or 'accept_necessary'
                },
                revision_message: '<br><br> Dear user, terms and conditions have changed since the last time you visisted!'
            },
            settings_modal: {
                title: 'Cookie Einstellungen',
                save_settings_btn: 'Auswahl speichern',
                accept_all_btn: 'Alle akzeptieren',
                reject_all_btn: 'Alle ablehnen',
                close_btn_label: 'Schließen',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Gültigkeit'}
                ],
                blocks: [
                    {
                        title: 'Cookie Nutzung',
                        description: 'Die Nutzung von Cookies stellt grundlegende Funktionen der Seite sicher und verbessert ihr Nutzererlebnis. Sie können entscheiden welche Art von Cookies sie akzeptieren oder ablehnen. Für weitere Details lesen sie die ' + DATAPROTECTION_LINK,
                    }, {
                        title: 'Technisch notwendige Cookies',
                        description: 'Diese Cookies stellen die Funktion der Seite sicher und verhindern, dass sie bei jedem einzelnen Seitenaufruf ihre Zustimmungserklrärung zur Nutzung von Cookies weiderholen müssen.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true  //cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            {
                                col1: 'cc_cookie_consent',
                                col2: 'happinessandwork.de',
                                col3: 'Cookie Consent Informtionen'
                            } 
                        ]
                    }, {
                        title: 'Analytics Cookies',
                        description: 'Diese Cookies sammeln Informationen darüber, wie sie die Webseite verwenden, welche Seiten sie aufrufen und welchen Links sie folgen. All diese daten werden anonym erhoben und lassen keine Rückschlüsse auf ihre Person zu.',
                        //see: https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: '_ga',
                                col2: 'google.com',
                                col3: 'Unterscheidung von Nutzern'
                            },
                            {
                                col1: '_ga_*',
                                col2: 'google.com',
                                col3: 'Sitzungsinformationen',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: 'Unterscheidung von Nutzern',
                            },
                            {
                                col1: '_gat',
                                col2: 'google.com',
                                col3: 'Steuerung der Anfragehäufigkeit'
                            } 
                        ]
                    },
                    /*
                    {
                        title: 'Targeting & Advertising cookies',
                        description: 'If this category is deselected, <b>the page will reload when preferences are saved</b>... <br><br>(demo example with reload option enabled, for scripts like microsoft clarity which will re-set cookies and send beacons even after the cookies have been cleared by the cookieconsent\'s autoclear function)',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false,
                            reload: 'on_disable'            // New option in v2.4, check readme.md
                        },
                        cookie_table: [
                            {
                                col1: '^_cl',               // New option in v2.4: regex (microsoft clarity cookies)
                                col2: 'yourdomain.com',
                                col3: 'These cookies are set by microsoft clarity',
                                // path: '/',               // New option in v2.4
                                is_regex: true              // New option in v2.4
                            }
                        ]
                    }, 
                    */
                    {
                        title: 'Weitere Informationen',
                        description: 'Wenn sie weire Informationen benötigen kontaktieren sie uns unter <a class="cc-link" href="mailto:office@felicicon.com">office@felicicon.com</a>.',
                    }
                ]
            }
        }
    }
}

export default CookieConsentConfig
/* eslint-disable */