<template>

  <div id="academy_pl">

    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- academy -->  
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">                    
                <div class="row align-items-end">
                  <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">             
                    <p class="c-txt-orange" style="font-weight: bold;">3 Module</p>  
                    <h2 class="text-center text-md-center c-h2" >Positive Leadership</h2>
                  </div>

                  <div class="col-12 order-1 py-1 col-md-12 order-md-1 d-flex align-items-center justify-content-center">             
                    <p class="text-center text-md-left">
                        Positive Psychologie ist wie gemacht für Führungskräfte:
                        Ihr Ziel ist es, Menschen dabei zu unterstützen ihre
                        Stärken zu nutzen, Glück zu empfinden und persönliches
                        Wachstum zu erfahren. Neben der Führung von Teams
                        geht es im Positive Leadership Ansatz darum, die besten
                        Fähigkeiten und Talente der Mitarbeitenden in der Arbeit
                        zu fördern. 
                    </p>
                  </div>
                  <div class="col-12 order-3 py-1 col-md-7 order-md-2 align-self-start justify-content-center">             
                    <p class="text-center text-md-left">
                        Unser Programm "Positive Leadership" besteht aus drei
                        separat buchbaren Modulen. Im ersten Modul werden die
                        Grundlagen gelegt und Wissen zum Thema Positive
                        Leadership vermittelt. Hier kombinieren wir Wissenschaft
                        und Transfer. Du weißt also nicht nur Wie man etwas
                        macht, sondern auch Warum. Das zweite Modul ist ein
                        Intensivseminar in Präsenz. Hier stehen die positiv-psychologische Haltung und Führungsrolle besonders in
                        schwierigen Situationen des Führungsalltags im
                        Vordergrund. Das dritte Modul ist ein Coaching, das Dich
                        ganz persönlich unterstützt auch in den letzten Winkel
                        Deines beruflichen Alltags Leichtigkeit zu bringen. 
                    </p>
                    <!-- button -->
                    <div class="d-flex justify-content-left">
                      <a  href="mailto:info@happinessandwork.de">
                        <div class="c-btn c-btn-orange" >
                          <span style="padding: 10px">Seminar anfragen</span>
                          <div class="c-btn-img c-btn-img-right-white"/>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="col-12 order-2 py-1 col-md-5 order-md-3 d-flex justify-content-center align-self-center">             
                    <!--<img id="bubble_academy_1" src="@/assets/bubble_red_1.png">  -->
                    <img id="bubble_pl" src="@/assets/academy_bubble_leadership.png" alt="HappinessAndWork_Positive_Leadership">  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>
    
    <div id="seminare" class="container-xl">
      <!-- one -->
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <div class="row align-items-end">
            <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">             
              <p class="c-txt-orange" style="font-weight: bold;">Modul 1</p>  
              <h2 class="text-center text-md-center c-h2" >Positive Leadership - Basics</h2>
            </div>
            <div class="col-12 order-2 col-md-3 order-md-2 align-self-center">
              <img id="bubble_pl_course_1" src="@/assets/academy_bubble_one.png" alt="HappinessAndWork_Positive_Leadership_Basics">
            </div>
            <div class="col-12 order-3 col-md-9 order-md-3 py-3 align-self-start">             
              <p class="text-center text-md-left">
                Das erste Modul umfasst die Online-Einheiten: 1. Einführung in Positive Leadership, 2.
                Ein Modell für Positive Leadership, 3. Sinnstiftung als Positive Leader, 4. Stärken fördern
                als Positive Leader, 5. Gemeinschaftsgefühl erzeugen als Positive Leader, 6. Reflexion
                und Auswertungsgespräch mit einer Trainerin. Jede Einheit besteht aus einer
                Videosequenz und einer Transferaufgabe.
              </p>
              <ul class="text-left">
                <li>Format: 5 Online Einheiten + 1 Reflexionsgespräch</li>
                <li>Zeitaufwand: 7-15 Stunden</li>
              </ul>
            </div>
          </div> 
        </div>
      </div>
      <!-- two -->
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <div class="row align-items-end">
            <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">             
              <p class="c-txt-orange" style="font-weight: bold;">Modul 2</p>  
              <h2 class="text-center text-md-center c-h2" >IntensivSeminar: Rolle als Positive Leader</h2>
            </div>
            <div class="col-12 order-2 col-md-3 order-md-3 align-self-center">
              <img id="bubble_pl_course_2" src="@/assets/academy_bubble_two.png" alt="HappinessAndWork_Positive_Leadership_Rolle_als_Leader">
            </div>
            <div class="col-12 order-3 col-md-9 order-md-2 py-3 align-self-start">             
              <p class="text-center text-md-left">
                Das zweite Modul ist ein 2 tägiges Präsenzseminar und findet 2x jährlich in Berlin statt
                (Mi 14-18 Uhr, Do 10-18 Uhr, Fr 10-14 Uhr). Hier geht es vorrangig um die Haltung
                und Rolle als Positive Leader. Der Fokus liegt auf dem praktischen Erleben und
                Erproben im geschützten Raum. Dabei dient die Gruppe als Resonanzrahmen, in dem
                professionell mit Selbst- und Fremdwahrnehmung gearbeitet wird. 
              </p>
              <ul class="text-left">
                <li>Format: 2 Tage Präsenzseminar (inkl. Verpflegung) / Onlinevorbereitung ca.3 Stunden</li>
                <li>Teilnehmerzahl: max. 10 - 14</li>
              </ul>
            </div>
          </div> 
        </div>
      </div>
      <!-- three -->
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <div class="row align-items-end">
            <div class="col-12 order-1 col-md-12 order-md-1 py-3 align-self-start">             
              <p class="c-txt-orange" style="font-weight: bold;">Modul 3</p>  
              <h2 class="text-center text-md-center c-h2" >Positive Leadership - Coaching</h2>
            </div>
            <div class="col-12 order-2 col-md-3 order-md-2 align-self-center">
              <img id="bubble_pl_course_3" src="@/assets/academy_bubble_three.png" alt="HappinessAndWork_Positive_Leadership_Coaching">
            </div>
            <div class="col-12 order-3 col-md-9 order-md-3 py-3 align-self-start"> 
              <p class="text-center text-md-left">
                Den Abschluss unseres Programms - und damit fließenden Übergang in Deine (neu)
                gelebte Führungspraxis - bildet ein Führungskräftecoaching zur Bearbeitung Deiner
                individuellen Führungsanliegen und -herausforderungen. Ein erfahrener Coach
                unterstützt Dich darin, die Haltung und Instrumente des Positive Leadership nachhaltig
                in Deinen Arbeitsalltag zu integrieren und Deine persönlichen Ziele zu erreichen. Das
                Coaching findet online statt.
              </p>
              <ul class="text-left">
                <li>Format:  Individuelles Coaching a 90 Min online (buchbar ab 3 Einheiten)</li>
              </ul>
            </div>
          </div> 
        </div>
      </div>
      <!-- download -->

      <div class="row justify-content-center">
        <div class="col-11 coll-md-10 py-5">
          <!--
          <div class="row">
            <div class="col-12 col-md-12">
              <p class="text-center">
                <a href="/downloads/HappinessAndWork_PositiveLeadership.pdf" download="HappinessAndWork_PositiveLeadership.pdf">Infos zum Download</a>
              </p>
            </div>
            -->

            <!--
            <div class="col-12 col-md-12" v-show="eventbriteLoaded">
              <p class="text-left" style="margin-bottom: 0; ">Modul 1 jetzt online buchen:</p>
              <!- eventbrite order iframe ->
              <div role="tablist">
                <template v-for="(course, idx) in eventbriteCourse" >
                  <div role="tab" :key="idx">
                    <div class="d-flex justify-content-left">  
                      <!- button ->
                      <div class="c-btn c-btn-orange d-flex justify-content-center" style="width: 250px; padding-right:10px;" :class="course.visible ? null : 'collapsed'" :aria-expanded="course.visible ? 'true' : 'false'" :aria-controls="'accordion-' + course.eventId" @click="course.visible = !course.visible">
                        <span style="padding: 10px">{{course.date}}</span>
                      </div>
                    </div>
                    <b-collapse :id="'accordion-' + course.eventId" accordion="course1-events" v-model="course.visible" role="tabpanel">
                      <b-card class="m-2">
                        <!- iframe ->
                        <div :id="'eventbrite-widget-container-'+ course.eventId"></div>
                      </b-card>
                    </b-collapse>
                  </div>
                </template>
              </div>
            </div>

            <div class="col-12 col-md-12" v-show="eventbriteLoaded">
              <p class="text-left" style="margin-bottom: 0;">Modul 2 jetzt online buchen:</p>
              <!- eventbrite order iframe ->
              <div role="tablist">
                <template v-for="(course, idx) in eventbriteCourse2" >
                  <div role="tab" :key="idx">
                    <div class="d-flex justify-content-left">  
                      <!- button ->
                      <div class="c-btn c-btn-orange d-flex justify-content-center" style="width: 250px; padding-right:10px;" :class="course.visible ? null : 'collapsed'" :aria-expanded="course.visible ? 'true' : 'false'" :aria-controls="'accordion2-' + course.eventId" @click="course.visible = !course.visible">
                        <span style="padding: 10px">{{course.date}}</span>
                      </div>
                    </div>
                    <b-collapse :id="'accordion2-' + course.eventId" accordion="course2-events" v-model="course.visible" role="tabpanel">
                      <b-card class="m-2">
                        <!- iframe ->
                        <div :id="'eventbrite-widget-container-'+ course.eventId"></div>
                      </b-card>
                    </b-collapse>
                  </div>
                </template>
              </div>
            </div>


            <div class="col-12 col-md-12">
              <p class="c-txt-orange text-left" style="font-weight: bold; margin-bottom: 0;  margin-top: 1em;">
                Alle Module sind einzeln aber auch im Kombipaket buchbar
              </p>  
            </div>
            <div class="col-12 col-md-12">
              <!- contact ->
              <div class="d-flex justify-content-left">  
                <!- button ->
                <router-link to="/contact" style="text-decoration: none">
                  <div class="c-btn c-btn-orange" >
                    <span style="padding: 10px">Kombipaket anfragen</span>
                    <div class="c-btn-img c-btn-img-right-white"/>
                  </div>
                </router-link>
              </div>
             </div>
          </div>
          --> 
        </div>
      </div> 
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Academy_PL',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` },
        { vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Academy - Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind?",
      metaDescription: "Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind? Die Felicicon GmbH hat Weiterbildungsangebote für Mitarbeiter und Führungskräfte und führen in Mitarbeiter Events in das Thema ein.",
      metaUrl: "/academy/positive_leadership",
      metaKeywords: "Seminar Beratung Coaching Teamentwicklung Keynotes Leadership 'Happinessandwork Academy'",
      metaImage: "https://www.happinessandwork.de/share/academy.png",
      // eventbride
      eventbriteCourse : [
        //{date: '10. - 12. September 2020', eventId: '116082992355', visible: false},
        //{date: '12. - 14. November 2020', eventId: '116977666349', visible: false},
        //{date: '06. - 08. April 2022', eventId: '165875950433', visible: false},
        //{date: '07. - 09. September 2022', eventId: '168732855513', visible: false},
        {date: 'Kursstart ab  April 2023', eventId: '503552969487', visible: false}
      ],
      eventbriteCourse2 : [
        {date: '14. - 16. September 2023', eventId: '503494163597', visible: false}
      ],
      eventbriteLoaded: false   // status of eventbrite script loading
    }
  },
  // called when component is mounted to the dom
  mounted: function () {
    // load js code from eventbrite
    const eventbriteScript = 'https://www.eventbrite.de/static/widgets/eb_widgets.js'
    this.$loadScript(eventbriteScript)
      .then(() => {
        // Script is loaded
        let eb = {
          // Required
          widgetType: 'checkout',
          eventId: null,
          iframeContainerId: null,
          // Optional
          iframeContainerHeight: 450,  // Widget height in pixels. Defaults to a minimum of 425px if not provided
          onOrderComplete: this.orderCompletedCallback  // Method called when an order has successfully completed
        }
        // prepare iframes for course
        this.eventbriteCourse.forEach((course) => {
          eb.eventId = course.eventId;
          eb.iframeContainerId = 'eventbrite-widget-container-' + course.eventId,
          // create Iframes
          window.EBWidgets.createWidget(eb)
        })
        // prepare iframes for course
        this.eventbriteCourse2.forEach((course) => {
          eb.eventId = course.eventId;
          eb.iframeContainerId = 'eventbrite-widget-container-' + course.eventId,
          // create Iframes
          window.EBWidgets.createWidget(eb)
        })
        // stop spinner
        this.eventbriteLoaded = true
      })
      .catch(() => {
        // Failed to fetch script
        console.log('Failed to load eventbrite script: ' + eventbriteScript); // eslint-disable-line no-console
      });
  },
  methods: {
    /*
    // called when order complete
    orderCompletedCallback() {
      console.log('Order complete!'); // eslint-disable-line no-console
      // send event to analytics
      this.$gtag.event('complete', {
        event_category: 'order',
        event_label: 'Academy',
        value: 123
      })

      this.$ga.event({
        eventCategory: 'order',
        eventAction: 'complete',
        eventLabel: 'Academy',
        eventValue: 123
      })
    }
    */
  }
}

</script>

<style>

</style>
