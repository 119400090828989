<template>

  <div id="impressum">
    <div class="c-img-bg c-imprint-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- data protection -->
            <div class="row justify-content-center py-3">
              <div class="col-11 coll-md-10 col-lg-10">                    
                <h1 class="text-left  c-h1">
                  Impressum
                </h1>              
              </div>
            </div>          
            <!-- generals -->
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10 col-lg-10">                    
                <h2 class="text-left  py-3 c-h2">
                  Dienstanbieter
                </h2>
                <p class="text-left ">
                  FELICICON GmbH - Strategische Organisationsentwicklung<br>
                  <br>              
                  Fliederblütenweg 39<br>
                  13127 Berlin<br>
                  <br>
                  www.felicicon.com<br>
                  Tel. +49 30 78714337<br>
                  Fax +49 30 78714338<br>
                  <br>
                  info@felicicon.com<br>
                  www.felicicon.com<br>
                  <br>
                  Geschäftsführung: Dr. Ricarda Rehwaldt<br>
                  Registergericht: Amtsgericht Charlottenburg – Berlin HRB205113B<br>
                  Steuer-Nr. 37/285/50592<br>
                  Ust-ID DE323279711<br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- AGB & copyright -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10 col-lg-10">  
          <!-- AGB -->                  
          <h2 class="text-left py-3 c-h2">
            Allgemeine Geschäftsbedingungen
          </h2>
          <p class="text-left">
						Die Allgemeinen Geschäftsbedingungen der FELICICON GmbH können hier herunterladen.
            <a href="/downloads/FELICICON-GmbH_AGBs.pdf" download="FELICICON-GmbH_AGBs.pdf">AGBs</a>
          </p>
          <!-- copyright -->                  
          <h2 class="text-left py-3 c-h2">
            Copyright Information
          </h2>
          <p class="text-left">
						Alle Inhalte dieser Website unterliegen - soweit nicht anders angegeben - dem Copyright der FELICICON GmbH
          </p>
          <!-- liability -->                  
          <h2 class="text-left py-3 c-h2">
            Haftung
          </h2>
          <p class="text-left">
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. 
            Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. 
            Dies gilt ebenfalls, wenn auf diese via Link verwiesen wird oder wenn diese in Berichterstattungen erwähnt werden. 
            Die Autorin distanziert sich ausdrücklich von allen Inhalten, die gegen geltendes Recht verstoßen. 
            Dies gilt insbesondere für Links, die in einem neuen Browserfenster geöffnet werden.
          </p>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Impressum',
  components: {
    Navi,
    Footer
  },
  data() {
        return {
        }
    },
    // called when component is mounted to the dom
    mounted: function () {
    },
    methods: {
      // got to location
      gotoLocation(url, target) {
        window.open(url, target);    
      }
    }
}

</script>

<style>

</style>
