<template>

  <div id="academy">

    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- academy -->  
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">                    
                <div class="row">
                  <div class="col-12 d-flex align-items-center flex-column">
                    <h1 class="c-h1">Happiness & Work Akademie</h1>  
                    <p class="c-txt-orange" style="font-weight: bold;">UNSER ANGEBOT</p>      
                  </div>
                  <div class="col-12 py-3 col-md-8 d-flex align-items-center justify-content-center">             
                    <p class="text-center text-md-left">
                        Lerne wie Glück funktioniert! Das geht leicht, denn wir haben verschiedene Formate entwickelt. 
                        Eines haben aber alle gemeinsam: Sie bringen Dir für Deine persönliche Situation nahe, 
                        wie der aktuelle Stand der Forschung ist und lassen Platz für Austausch. 
                        Buzzwordbingo und Fachchinesisch haben wir einfach gestrichen und durch jede Menge Spaß ersetzt. 
                        Gespannt auf uns? Wir auch! – auf Dich!  
                    </p>
                  </div>
                  <div class="col-12 py-3 col-md-4 d-flex justify-content-end">             
                    <img id="bubble_academy_1" src="@/assets/bubble_red_1.png" alt="bubble">  
                  </div>
                </div>    
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- course overview -->
    <div class="container-xl">
      <!-- 1-3 -->
      <div class="row justify-content-center">
        <div class="col-11 col-md-10">   
          <!--      
          <div class="row">
            <div class="offset-1 col-10 offset-md-0 col-md-12">
              <p class="c-txt-orange" style="font-weight: bold;">FORSCHUNGEN ZEIGEN</p>
              <h2 class="c-h2" style="color: #757575;">Glück im Kontext der Arbeit entsteht aus drei Faktoren</h2>          
            </div>
          </div>     
          -->
          <div class="row align-items-end">
            <!-- happiness & work für dich -->
            <div class="col-12 order-1 col-md-4 order-md-4">
              <img src="@/assets/academy_bubble_hwfy-sm.png" alt="HappinessAndWork_for_you" style="margin: 20px; width: 80%; max-width: 280px;">
            </div>
            <div class="col-12 order-2 col-md-4 order-md-1">
              <h2 class="c-h2 c-txt-yellow">Happiness & Work für Dich</h2>
            </div>
            <div class="col-12 order-3 col-md-4 order-md-7 align-self-start py-3 text-left">
              <p>
                Dieser Workshop ist für alle, die verstehen möchten,
                was Menschen bei der Arbeit glücklich macht und Lust haben ihr Arbeitsleben positiver zu gestalten. 
              </p>
            </div> 
            <div class="col-12 order-4 col-md-4 order-md-10">
              <div class="d-flex justify-content-center">  
                <!-- button -->
                <router-link to="/academy/happiness_and_work_for_you" style="text-decoration: none">
                  <div class="c-btn c-btn-orange" >
                    <span style="padding: 5px">Zum Angebot</span>
                    <div class="c-btn-img c-btn-img-right-white"/>
                  </div>
                </router-link>
              </div>
            </div>
            <!-- happiness & homeoffice -->
            <div class="col-12 order-5 col-md-4 order-md-5">
              <img src="@/assets/academy_bubble_home-sm.png" alt="HappinessAndWork_and_Homeoffice" style="margin: 20px; width: 80%;  max-width: 280px;">
            </div>  
            <div class="col-12 order-6 col-md-4 order-md-2">
              <h2 class="c-h2 c-txt-blue">Happiness & Homeoffice</h2>
            </div>
            <div class="col-12 order-7 col-md-4 order-md-8 align-self-start py-3 text-left">
              <p>
                In letzten Jahr haben wir oft von Frustration & Erschöpfung im Homeoffice gehört. 
                Deshalb haben wir dieses 4-Wochen-Online-Programm für strukturiertes und gesundes Arbeiten entwickelt.
              </p>
            </div>
            <div class="col-12 order-8 col-md-4 order-md-11">
              <div class="d-flex justify-content-center">  
                <!-- button -->
                <router-link to="/academy/happiness_and_homeoffice" style="text-decoration: none">
                  <div class="c-btn c-btn-orange">
                    <span style="padding: 5px">Zum Angebot</span>
                    <div class="c-btn-img c-btn-img-right-white"/>
                  </div>
                </router-link>
              </div>
            </div>            
            <!-- positive leadership -->
            <div class="col-12 order-9 col-md-4 order-md-6">
              <img src="@/assets/academy_bubble_leadership-sm.png" alt="HappinessAndWork_Positive_Leadership" style="margin: 20px; width: 85%; max-width: 280px;">
            </div>
            <div class="col-12 order-10 col-md-4 order-md-3">
              <h2 class="c-h2 c-txt-orange">Positive Leadership</h2>
            </div>            
            <div class="col-12 order-11 col-md-4 order-md-9 align-self-start py-3 text-left">
              <p>
                Positive Psychologie ist wie gemacht für Führungskräfte:
                Ihr Ziel ist es, Menschen dabei zu unterstützen ihre
                Stärken zu nutzen, Glück zu empfinden und persönliches
                Wachstum zu erfahren.
              </p>
            </div>
            <div class="col-12 order-12 col-md-4 order-md-12">
              <div class="d-flex justify-content-center">  
                <!-- button -->
                <router-link to="/academy/positive_leadership" style="text-decoration: none">
                  <div class="c-btn c-btn-orange" >
                    <span style="padding: 5px">Zum Angebot</span>
                    <div class="c-btn-img c-btn-img-right-white"/>
                  </div>
                </router-link>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
    <!-- 4-6 -->
    <div class="c-grey-bg">
      <!-- wave top -->
      <div class="c-wave c-wave-top"/>
      
      <!-- middle -->
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-11 col-md-10">   
                <div class="row align-items-end">
                  <!-- happiness hacks -->
                  <div class="col-12 order-1 col-md-4 order-md-4">
                    <img src="@/assets/academy_bubble_hacks-sm.png" alt="HappinessAndWork_Happiness_Hacks" style="margin: 20px; width: 80%; max-width: 280px;">
                  </div>
                  <div class="col-12 order-2 col-md-4 order-md-1">
                    <h2 class="c-h2 c-txt-orange">Happiness Hacks</h2>
                  </div>
                  <div class="col-12 order-3 col-md-4 order-md-7 align-self-start py-3 text-left">
                    <p>
                      Positive Psychologie ist in aller Munde. 
                      Aber wie kann jeder von uns das für sich im Alltag umsetzen? Dazu geben unsere Happiness Hacks Auskunft. 
                    </p>
                  </div> 
                  <div class="col-12 order-4 col-md-4 order-md-10">
                    <div class="d-flex justify-content-center">  
                      <!-- button -->
                      <router-link to="/academy/happiness_hacks" style="text-decoration: none">
                        <div class="c-btn c-btn-orange">
                          <span style="padding: 5px">Zum Angebot</span>
                          <div class="c-btn-img c-btn-img-right-white"/>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <!-- keynotes & seminare -->
                  <div class="col-12 order-5 col-md-4 order-md-5">
                    <img src="@/assets/academy_bubble_keynotes-sm.png" alt="HappinessAndWork_Keynotes" style="margin: 20px; width: 80%;  max-width: 280px;">
                  </div>  
                  <div class="col-12 order-6 col-md-4 order-md-2">
                    <h2 class="c-h2 c-txt-yellow">Individuelle Keynotes & Seminare</h2>
                  </div>
                  <div class="col-12 order-7 col-md-4 order-md-8 align-self-start py-3 text-left">
                    <p>
                      Wir konzipieren Keynotes & Seminare für Teams und Unternehmen mit euren Schwerpunkten, Tools und Zeitfenstern. 
                    </p>
                  </div>
                  <div class="col-12 order-8 col-md-4 order-md-11">
                    <div class="d-flex justify-content-center">  
                      <!-- button -->
                      <router-link to="/academy/keynotes" style="text-decoration: none">
                        <div class="c-btn c-btn-orange">
                          <span style="padding: 5px">Zum Angebot</span>
                          <div class="c-btn-img c-btn-img-right-white"/>
                        </div>
                      </router-link>
                    </div>
                  </div>            
                  <!-- coaching -->
                  <div class="col-12 order-9 col-md-4 order-md-6">
                    <img src="@/assets/academy_bubble_coaching-sm.png" alt="HappinessAndWork_Coaching" style="margin: 20px; width: 85%; max-width: 280px;">
                  </div>
                  <div class="col-12 order-10 col-md-4 order-md-3">
                    <h2 class="c-h2 c-txt-blue">Dein Coaching</h2>
                  </div>            
                  <div class="col-12 order-11 col-md-4 order-md-9 align-self-start py-3 text-left">
                    <p>
                      Coaching ist der Beschleuniger für persönliche Entwicklung überhaupt. 
                      Wir arbeiten mit einem systemischen Ansatz und kombinieren mit positiver Psychologie.  
                    </p>
                  </div>
                  <div class="col-12 order-12 col-md-4 order-md-12">
                    <div class="d-flex justify-content-center">  
                      <!-- button -->
                      <router-link to="/academy/coaching" style="text-decoration: none">
                        <div class="c-btn c-btn-orange">
                          <span style="padding: 5px">Zum Angebot</span>
                          <div class="c-btn-img c-btn-img-right-white"/>
                        </div>
                      </router-link>
                    </div>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Academy',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` },
        { vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Academy - Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind?",
      metaDescription: "Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind? Die Felicicon GmbH hat Weiterbildungsangebote für Mitarbeiter und Führungskräfte und führen in Mitarbeiter Events in das Thema ein.",
      metaUrl: "/academy",
      metaKeywords: "Seminar Beratung Coaching Teamentwicklung Keynotes 'Happinessandwork Academy'",
      metaImage: "https://www.happinessandwork.de/share/academy.png"
    }
  },
  // called when component is mounted to the dom
  mounted: function () {
    // Create render evnt puppeteer is waiting for when prerender pager (see param: renderAfterDocumentEvent)
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
  }
}

</script>

<style>
</style>
