<template>

  <div id="info_podcasts">
    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- info -->  
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">                    
                <div class="row">
                  <div class="col-12 d-flex align-items-center flex-column">
                    <h1 class="c-h1">Podcasts</h1>
                  </div>
                  <div class="col-12 py-3 col-md-8 d-flex align-items-center justify-content-center">             
                    <p class="text-center text-md-left">
                      Für alle die Wissen für unterwegs suchen - Hier kommt was auf die Ohren!
                    </p>
                  </div>
                  <div class="col-12 py-3 col-md-4 d-flex justify-content-end">             
                    <img id="bubble_academy_1" src="@/assets/bubble_red_1.png" alt="bubble">  
                  </div>
                </div>    
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>

    <!-- podcasts I -->
    <div class="container-xl">
      <div class="row justify-content-center">
        <div class="col-11 coll-md-10">
          <!--
          <div class="row align-items-start">
            <div class="col-12 py-3">             
              <h2 id="books" class="text-center text-md-center c-h2" >Podcasts</h2>
            </div>
          </div>
          -->
          <div class="row justify-content-center">
            <div class="col-12 col-md-10">
              <!-- pod 1 -->
              <div class="row justify-content-center align-items-center">  
                <div class="col-12 order-1 py-3 text-left text-md-left  ">
                  <h3 id="dgo" class="c-txt-orange"><b>Was braucht eine Organisation um glücklich zu sein?</b></h3>
                  <p>
                    Eva Resch & Jasmin Werne im Interview mit Dr. Ricarda Rehwaldt
                  </p>
                </div>
                <div class="col-12 order-2 col-md-5 py-3 text-left text-md-left">
                  <img id="pod_1" src="@/assets/podcasts/happy_works.png" alt="HappinessAndWork_Podcast_Happy_Works"> 
                </div>
                <div class="col-12 order-3 col-md-7 py-3 text-left ">            
                  <p>
                    Dr. Ricarda Rehwaldt ist Gründerin und Geschäftsführerin der FELICICON GmbH - einer Unternehmensberatung, die Unternehmen speziell zum Thema Glück und Arbeit berät. 
                    Sie hat viele Jahre dazu geforscht was Menschen bei der Arbeit glücklich macht. 
                    Jetzt macht sie andere glücklich, indem sie ihr Wissen in andere Unternehmen trägt. 
                    In ihrer täglichen Arbeit begleitet sie Vorstände, Führungskräfte und Teams auf dem Weg zu glücklicher Arbeit. 
                    Sie ist Autorin des Buches „die glückliche Organisation" und lehrt zudem im Bereich Wirtschaftspsychologie 
                    und Positive Psychologie an verschiedenen Hochschulen in Deutschland.
                  </p>
                  <ul>
                    <li><a href="https://www.happyworkspodcast.com/dr-ricarda-rehwaldt" target="_blank">HAPPY WORKS. - Was braucht eine Organisation um glücklich zu sein?</a></li>
                    <li><a href="https://soundcloud.com/happyworkspodcast/16-ricarda-rehwaldt-was-braucht-eine-organisation-um-glucklich-zu-sein" target="_blank">Hören auf SOUNDCLOUD</a></li>
                    
                  </ul>
                </div>
              </div>
              <!-- pod 2 -->
              <div class="row justify-content-center align-items-center">
                <div class="col-12 order-1 order-md-1 py-3 text-left text-md-right ">
                  <h3 class="c-txt-orange"><b>Dem Glück auf der Spur</b></h3>
                  <p>
                    ein Interview mit Prod. Dr. Ricarda Rehwaldt
                  </p>   
                </div>
                <div class="col-12 order-2 col-md-5 order-md-3 py-3 text-left text-md-right">
                  <img id="pod_2" src="@/assets/podcasts/einfach_weniger_stress.png" alt="HappinessAndWork_Podcast_Einfach_weniger_Stress"> 
                </div>
                <div class="col-12 order-3 col-md-7 order-md-2 py-3 text-left ">          
                  <p>
                    Um dem Glück mehr auf die Spur zu kommen, haben wir Glücksexpertin Prof. Dr. Ricarda Rehwaldt eingeladen, 
                    welche uns einen vertieften Einblick in den Themenbereich gewährt. 
                    Was ist Glück eigentlich und wie kann das eigene Glück beeinflusst werden? 
                    Wie wirkt sich das Glücksempfinden bei der Arbeit auf das eigene Leben und die eigene Leistung aus? 
                    Was kann getan werden, um das Glücksempfinden zu fördern? All diese Fragen finden in dieser Episode Eingang.
                  </p>
                  <ul>
                    <li><a href="https://open.spotify.com/episode/4bUAGT1GXLxIdFnZnE8IkS" target="_blank">Denkverstärker - Dem Glück auf der Spur</a></li>
                  </ul>
                </div>
              </div> 
              <!-- pod 3 -->
              <div class="row justify-content-center align-items-center">  
                <div class="col-12 order-1 py-3 text-left text-md-left  ">
                  <h3 id="dgo" class="c-txt-orange"><b>Happy at work</b></h3>
                  <p>
                    mit Prof. Dr. Ricarda Rehwaldt
                  </p>
                </div>
                <div class="col-12 order-2 col-md-5 py-3 text-left text-md-left">
                  <img id="pod_3" src="@/assets/podcasts/happy_at_work.png" alt="HappinessAndWork_Podcast_Happy_at_Work"> 
                </div>
                <div class="col-12 order-3 col-md-7 py-3 text-left ">            
                  <p>
                    Wir sprechen über organisationales Glück und dessen Bedingungen: 
                    Sinnempfinden, Selbstverwirklichung und Gemeinschaft und was Glück in Unternehmen bewirken kann: 
                    u.a. Identifikation, Motivation, Kreativität, Bindung, positive Stimmung und einen Leistungszuwachs der Mitarbeitenden.
                  </p>
                  <ul>
                    <li><a href="https://www.laurenzmenzinger.de/podcast/13-ricarda-rehwaldt" target="_blank">Laurenz Menzinger - Happy at work</a></li>
                    <li><a href="https://open.spotify.com/episode/0I9R71X047B5tHoc3XN5tk?si=6a0e6e0649dd4f28" target="_blank">Hören auf SPOTIFY</a></li>
                  </ul>
                </div>
              </div> 

            </div>
          </div>
        </div>
      </div>
    </div>

    
    <!-- podcasts II -->
    <div class="c-grey-bg">
      <!-- wave top -->
      <div class="c-wave c-wave-top"/>

      <!-- middle -->
      <div class="container-xl">
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">
                <div class="row justify-content-center">
                  <div class="col-12 col-md-10">
                    
                    <!-- pod 4 -->
                    <div class="row justify-content-center align-items-center">
                      <div class="col-12 order-1 order-md-1 py-3 text-left text-md-right ">
                        <h3 class="c-txt-orange"><b>Warum wir heute mehr glückliche Menschen brauchen.</b></h3>
                        <p>
                          ein Interview mit der Glücksforschering Dr. Ricarda Rehwaldt
                        </p>   
                      </div>
                      <div class="col-12 order-2 col-md-5 order-md-3 py-3 text-left text-md-right">
                        <img id="pod_4" src="@/assets/podcasts/warum_mehr_glueckliche_menschen.png" alt="HappinessAndWork_Podcast_Warum_wir_mehr_glückliche_Menschen_brauchen"> 
                      </div>
                      <div class="col-12 order-3 col-md-7 order-md-2 py-3 text-left ">          
                        <p>
                          Glück und Arbeit passen sicherlich nicht für jeden zusammen. 
                          Warum Glück jedoch in jedem Unternehmen gehört, habe ich mit der Glücksforscherin und Unternehmensberaterin Dr. Ricarda Rehwaldt diskutiert.
                          Glück hat eine gesellschaftliche Facette. Das Streben nach Glück findet sich in der Präambel der amerikanischen Unabhängigkeitserklärung. 
                          Das individuelle Streben nach Glück setzt jedoch Freiheitsrechte voraus und – man könnte als Europäer hinzufügen – einen starken Sozialstaat, 
                          der diese Freiheitsrechte jedem ermöglicht.
                        </p>
                        <ul>
                          <li><a href="https://www.lindner-consult.de/referenzen/podacst/" target="_blank">Oliver Lindner - Warum wir heute mehr glückliche Menschen brauchen.</a></li>
                          <li><a href="https://www.listennotes.com/podcasts/dem-wandel-auf-der/warum-wir-heute-mehr-SI2TmzFQ8pC/" target="_blank">Hören auf LISTEN NOTES</a></li>
                          <li><a href="https://podcasts.apple.com/us/podcast/warum-wir-heute-mehr-gl%C3%BCckliche-menschen-brauchen/id1455003368?i=1000433182868" target="_blank">Horen auf Apple Podcast</a></li>                
                        </ul>
                      </div>
                    </div> 
                    <!-- pod 5 -->
                    <div class="row justify-content-center align-items-center">  
                      <div class="col-12 order-1 py-3 text-left text-md-left  ">
                        <h3 id="dgo" class="c-txt-orange"><b>Leicht & Bewegt - Dr. Ricrada Rehwaldt - Expertin für Glück und Arbeit</b></h3>
                        <p>
                          Julia Buller im Interview mit Dr. Ricarda Rehwaldt
                        </p>
                      </div>
                      <div class="col-12 order-2 col-md-5 py-3 text-left text-md-left">
                        <img id="pod_5" src="@/assets/podcasts/leicht_und_bewegt.png" alt="HappinessAndWork_Podcast_Leicht_und_bewegt"> 
                      </div>
                      <div class="col-12 order-3 col-md-7 py-3 text-left ">            
                        <p>
                          Wie kann ich ein erfülltes, glückliches (Arbeits-)leben führen?
                          <br/>
                          Was Ricarda dazu sagt, welchen Einfluss Glück auf unsere Arbeit hat, wie wichtig es ist, 
                          glücklich zu sein und was unsere Gesundheit damit zu tun hat, erfahrt ihr in diesem Interview.
                          Außerdem stellen wir Dir ein spannendes Projekt zu diesem Thema vor, 
                          was vielleicht auch für Dich interessant sein könnte :-).
                        </p>
                        <ul>
                          <li><a href="https://bullablock.podigee.io/39-ricarda_rehwaldt" target="_blank">Hören auf POTIGEE</a></li>                   
                          <li><a href="https://open.spotify.com/episode/4svkHfmMIBM4YzMD4A0hsv" target="_blank">Hören auf SPOTIFY</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom"/>
    </div>


    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Info_Podcasts',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` }//,
        //{ vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Podcasts",
      metaDescription: "Hier findest Du Podcasts zum Thema Glück und Arbeit.",
      metaUrl: "/info/podcasts",
      metaKeywords: "Podcast",
      metaImage: undefined,
    }
  },
    // called when component is mounted to the dom
    mounted: function () {
/*       const anchor = this.$router.currentRoute.hash;
      setTimeout(() => { 
        if (anchor && document.querySelector(anchor)) {
        //var elem = document.querySelector(this.$route.hash);
        var elem = document.querySelector(anchor);
        console.log("scoll to: " + anchor); // eslint-disable-line no-console
        elem.scrollIntoView({ behavior: 'smooth', block: 'start'});
        }
      }, 1000) */
    },
    methods: {
    }
}

</script>

<style>

</style>
