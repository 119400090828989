<template>

  <div id="academy_co">

    <div class="c-img-bg c-academy-bg">

      <div class="container-xl">
        <div class="row">
          <div class="col-12">

            <!-- navigation-->
            <Navi dark-mode></Navi>

            <!-- coaching  -->  
            <div class="row justify-content-center">
              <div class="col-11 coll-md-10">
                <div class="row align-items-end">
                  <div class="col-12 order-1 col-md-7 order-md-1 py-3 align-self-start">             
                    <p class="c-txt-orange" style="font-weight: bold;">Persönliche Begleitung</p>  
                    <h2 class="text-center text-md-center c-h2">Dein Coaching</h2>
                  </div>
                  <div class="col-12 order-2 col-md-2 order-md-2 align-self-center">
                    <img id="kn_top_coaches" src="@/assets/top_coaches_badge.png" alt="HappinessAndWork_TopCoaches_Badge">
                    <!--
                    <a  href="https://derarthur.de/top-coaches-10-22-online-advertorial-webseite-manager-magazin/" target="_blank">
                      <img id="kn_top_coaches" src="@/assets/top_coaches_badge.png" alt="HappinessAndWork_TopCoaches_Badge">
                    </a>
                    -->
                  </div>
                  <div class="col-12 order-1 col-md-7 order-md-3">
                    <img id="bubble_co" src="@/assets/academy_bubble_coaching.png" alt="HappinessAndWork_Coaching">
                  </div>
                  <div class="col-12 order-2 col-md-5 order-md-2 py-3 align-self-start">  
                    <p class="text-center text-md-left">
                      Coaching ist der Beschleuniger für persönliche Entwicklung überhaupt.
                      Wir arbeiten mit einem systemischen Ansatz und kombinieren mit positiver Psychologie.  
                    </p>        
                    <p class="text-center text-md-left">
                      Unser Fokus bis ganz klar Du als Mensch. Wir unterstützen Dich dabei Deine Stärken einzusetzen, 
                      mehr positive Emotionen in Deinem Leben zu platzieren und Deine Ziel zu erreichen-  
                      beruflich und privat. Melde Dich gerne – Anna & Ricarda 
                    </p>
                    <!--
                    <p class="text-left">
                      <a href="/downloads/HappinessAndWork_Macht_Geld_eigentlich_glücklich.pdf" download="HappinessAndWork_Macht_Geld_eigentlich_glücklich.pdf">Infos zum Download</a>
                    </p>
                    -->
                    <ul class="text-left">
                      <li>
                        Kennenlerngespräch mit <a href="mailto:info@happinessandwork.de">Ricarda</a> vereinbaren
                      </li>
                      <li>
                        Kennenlerngespräch mit <a href="mailto:info@happinessandwork.de">Anna</a> vereinbaren
                      </li>
                    </ul>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- wave bottom -->
      <div class="c-wave c-wave-bottom" />
    </div>

    <!-- footer -->
    <div class="c-orange-bg">   
      <!-- wave top -->
      <div class="c-wave c-wave-top" style="position: relative; top:-2px;"/>
      <!-- footer content -->
      <div class="container-xl">
        <div class="row c-txt-white c-footer">
          <div class="col-12">
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Academy_CO',
  components: {
    Navi,
    Footer
  },
  metaInfo() {
    // patch meta data on current page
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: `${this.metaTitle}`,
      meta: [
        { vmid: "keywords",  name: 'keywords', content: `${this.metaKeywords}` },
        { vmid: "description", name: 'description', content: `${this.metaDescription}`},
        // social tagging
        { vmid: "og:title", property: "og:title", content: `${this.metaTitle}` },
        { vmid: "og:url", property: "og:url", content: `${this.metaUrl}` },
        { vmid: "og:description", property: "og:description", content: `${this.metaDescription}` },
        { vmid: "og:image", name: 'og:image', content: `${this.metaImage}` }
      ]
    }
  },
  data() {
    return {
      // metaData
      metaTitle: "- Academy - Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind?",
      metaDescription: "Wie schafft es eine Organisation, dass ihre Mitarbeiter glücklich sind? Die Felicicon GmbH hat Weiterbildungsangebote für Mitarbeiter und Führungskräfte und führen in Mitarbeiter Events in das Thema ein.",
      metaUrl: "/academy/coaching",
      metaKeywords: "Seminar Beratung Coaching Teamentwicklung Keynotes 'Happinessandwork Academy'",
      metaImage: "https://www.happinessandwork.de/share/academy.png",
      // eventbride
      eventbriteCourse1 : [
        //{date: '10. - 12. September 2020', eventId: '116082992355', visible: false},
        //{date: '12. - 14. November 2020', eventId: '116977666349', visible: false},
        //{date: '04. - 06. März 2021', eventId: '116981273137', visible: false},
        //{date: '10. - 12. Juni 2021', eventId: '129660007573', visible: false},
        //{date: '11. - 13. November 2021', eventId: '129660751799', visible: false},
        //{date: '10. - 12. März 2022', eventId: '149147647629', visible: false}
        //{date: '11. - 13. Juni 2021', eventId: '98193488367', visible: false}
      ],
      eventbriteCourse2 : [
        //{date: '10. - 12. Februar 2021', eventId: '116978400545', visible: false},
        //{date: '05. - 07. Mai 2021', eventId: '116980954183', visible: false},
        //{date: '28. - 30. Juli 2021', eventId: '116980954183', visible: false},
        //{date: '08. - 10. September 2021', eventId: '129661275365', visible: false},
        //{date: '26. - 28. Januar 2022', eventId: '149147382837', visible: false}
      ],
      eventbriteCourse3 : [
        //{date: '02. Oktober 2020', eventId: '116981874937', visible: false},
        //{date: '27. November 2020', eventId: '116982342335', visible: false}
      ],
      eventbriteLoaded: false   // status of eventbrite script loading
    }
  },
  // called when component is mounted to the dom
  mounted: function () {
    // load js code from eventbrite
    const eventbriteScript = 'https://www.eventbrite.de/static/widgets/eb_widgets.js'
    this.$loadScript(eventbriteScript)
      .then(() => {
        // Script is loaded
        let eb = {
          // Required
          widgetType: 'checkout',
          eventId: null,
          iframeContainerId: null,
          // Optional
          iframeContainerHeight: 450,  // Widget height in pixels. Defaults to a minimum of 425px if not provided
          onOrderComplete: this.orderCompletedCallback  // Method called when an order has successfully completed
        }
        // prepare iframes for course 1
        this.eventbriteCourse1.forEach((course) => {
          eb.eventId = course.eventId;
          eb.iframeContainerId = 'eventbrite-widget-container-' + course.eventId,
          // create Iframes
          window.EBWidgets.createWidget(eb)
        })
        // prepare iframes for course 2
        this.eventbriteCourse2.forEach((course) => {
          eb.eventId = course.eventId;
          eb.iframeContainerId = 'eventbrite-widget-container-' + course.eventId,
          // create Iframes
          window.EBWidgets.createWidget(eb)
        })
        // prepare iframes for course 3
        this.eventbriteCourse3.forEach((course) => {
          eb.eventId = course.eventId;
          eb.iframeContainerId = 'eventbrite-widget-container-' + course.eventId,
          // create Iframes
          window.EBWidgets.createWidget(eb)
        })
        // stop spinner
        this.eventbriteLoaded = true
      })
      .catch(() => {
        // Failed to fetch script
        console.log('Failed to load eventbrite script: ' + eventbriteScript); // eslint-disable-line no-console
      });
  },
  methods: {
    // called when order complete
    orderCompletedCallback() {
      console.log('Order complete!'); // eslint-disable-line no-console
      // send event to analytics
      this.$gtag.event('complete', {
        event_category: 'order',
        event_label: 'Academy',
        value: 123
      })
      /*
      this.$ga.event({
        eventCategory: 'order',
        eventAction: 'complete',
        eventLabel: 'Academy',
        eventValue: 123
      })
      */
    }
  }
}

</script>

<style>

</style>
